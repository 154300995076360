import React, { useState, useEffect } from 'react';
import {
	Box,
	IconButton,
	Grid,
	Button,
	Tooltip,
	useTheme,
	Card
} from '@material-ui/core';
import { fade, makeStyles } from '@material-ui/core/styles';
// import DropDownButton from './VariablesPanel/DropDownButton';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CancelIcon from '@material-ui/icons/Cancel';
import Xarrow, { Xwrapper } from 'react-xarrows';
import PanelInputField from '../SpaceView/ShowcaseOverlay/3DTools/PanelInputField';
import ShortUuid from 'short-uuid';
import { UserDataProperties } from 'mp/core/craEngine/SubSystems/ui-interop/PropertiesPanel';
// import {useDispatch} from 'react-redux';
import * as Icons from '@mui/icons-material';
import {
	Logic,
	ConditionTree,
	NestedCondition,
	Condition,
	OBJECT_ACTIONS,
	CONDITION_JOINER,
	ACTION_TYPE,
	CONDITION_TYPE,
	OBJECT_EVENT_TYPES,
	VarCondition,
	ObjectCondition,
	TreeCondition,
} from 'types/models/dataAccess/Logic';
import { fetchError } from 'redux/actions';
import { AppState } from 'redux/store';
import { useDispatch, useSelector } from 'react-redux';
import DropDownButton from './DropDownButton';
import { CremaTheme } from 'types/AppContextPropsType';
import { ISceneNode, SceneComponent } from 'mp/core/craEngine/SubSystems/sceneManagement/SceneComponent';
import { AddObjectClickSpy } from 'mp/core/craEngine/spies/AddObjectClickSpy';
import _ from 'lodash';
import { VariableTypeAllowedValues } from '../SpaceView/Sidebar/Variables/VariableTypes';
import { onUpdateTagGroup } from 'redux/actions/Step';
import { TagGroup } from 'types/models/home/HomeApp';
import IndicatorPulse from '@crema/core/IndicatorPulse';
import { store } from 'App';
import IconHover from '@crema/core/AppAnimation/ItemHover/IconHover';
import { getCustomProps } from 'mp/core/craEngine/Tools/Utils';
import { NodeCollider } from 'mp/core/craEngine/spies/DragSpies';
import { isUserAdmin } from '../utils';

const useStyles = makeStyles((theme: CremaTheme) => ({
	drawer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		transition: 'height 1s ease-out'
	},
	treeBox: {
		position: 'relative'
	},
	removeTreeBtn: {
		position: 'absolute',
		right: 0,
		top: 0
	},
	addLogicTreeButton: {
		height: 40,
		width: 150
	},
	paper: {
		minWidth: 400,
		// backgroundColor: '#eee !important',
		margin: 8,
		// position: 'relative',
		// width: "100%"
	},
	treeItem: {
		justifyContent: 'space-between',
		minWidth: 350,
		paddingTop: "8px",
		marginBottom: "8px",
		borderRadius: '16px',
	},
	triggersActionsContainer: {
		position: 'relative'
	},
	treeNode: {
		position: 'relative',
		marginBottom: 10,
		justifyContent: 'flex-start',
		borderRadius: '16px',
		'& .MuiButton-root': {
			padding: '1px'
		}
		// backgroundColor: '#fff'
	},
	actionRow: {
		position: 'relative',
		// marginBottom: 10,
		justifyContent: 'flex-start',
		borderRadius: '16px',
		'& .MuiButton-root': {
			padding: '1px'
		}
		// backgroundColor: '#fff'
	},
	decision: {
		marginBottom: 150,
	},
	addTriggerButton: {
		transition: 'all .2s',
		padding: 0,
		height: 36,
		'& .MuiSvgIcon-root': {
			fontSize: '2.5rem'
		},
		color: theme.palette.primary.main
	},
	addNestedTriggerButton: {
		color: fade(theme.palette.secondary.main, 0.5),
		transition: 'all .2s',
		padding: 0,
		height: 36,
		'& .MuiSvgIcon-root': {
			fontSize: '2.5rem'
		},
		'&:hover': {
			color: theme.palette.secondary.main
		}
	},
	addButton: {
		position: 'absolute',
		padding: 0,
		'& .MuiSvgIcon-root': {
			fontSize: '2.5rem'
		},
	},
	removeButton: {
		float: 'right'
	},
	actionTitle: {
		marginTop: '-22px',
		fontSize: '1em',
		fontFamily: 'fantasy',
		fontStyle: 'italic',
		color: 'purple',
	},

	selectBox: {
		// width: 'fit-content',
		// minWidth: '20%',
		width: '100px',

		display: 'flex',
		margin: theme.spacing(1),
		// fontWeight: Fonts.LIGHT,
		marginBottom: 20,
		marginTop: 16,
		// marginLeft: 5,
		// marginRight: 5
		'& .MuiOutlinedInput-input': {
			padding: '5px 10px',
		}
	},

	selectMenuItem: {
		display: 'flex',
		color: "black",
		// fontWeight: Fonts.LIGHT,
	},
}));

interface ILine {
	start: string;
	end: string;
	labels?: any;
	startAnchor?: any;
	endAnchor?: any;
}

export class CompInfo {
	constructor(public object1ComponentIndex: number = -1, public object2ComponentIndex: number = -1) {
	};
}

// export interface CompInfo {
// 	object1ComponentIndex: number,
// 	object2ComponentIndex: number
// }

export interface ICondition {
	id: string;
	type: CONDITION_TYPE;
	objectEventType?: OBJECT_EVENT_TYPES;
	objectId?: string;
	objectId2?: string;
	subObject1Name?: string;
	subObject2Name?: string;
	localCollidingPoint1?: { x: number, y: number, z: number };
	localCollidingPoint2?: { x: number, y: number, z: number };
	compInfo?: CompInfo;
	varName?: string;
	varValue?: string;
	valueOptions: Array<any>;
	nestedCondition?: NestedCondition;
	parameters?: string; // could be x,y,z or key,value string
}

export interface IAction {
	id: string;
	type: ACTION_TYPE;
	varName?: string;
	varValue?: string;
	actionType?: OBJECT_ACTIONS;
	objectId?: string;
	currentTagGroupId?: string;
	parameters?: string; // could be x,y,z or key,value string
	valueOptions: Array<any>;
	iotVariableName?: string;
	waitInSeconds?: number;
}

function LogicTreeNestedCondition(
	{ tIndex, conditionsData, updateConditions, depth }:
		{ tIndex: string, conditionsData: NestedCondition, updateConditions(data: NestedCondition): void, depth: number }) {

	const variableValues = useSelector<AppState, any[]>(({ home }) => home.spaceVariables || []);
	const spaceModels = useSelector<AppState, Map<string, any>>(({ home }) => home.spaceModels);
	const variableOptions = variableValues.length > 0 ? variableValues
		// .filter((e: any) => e.allowControl == true)
		.map((e: any) => ({ id: e.name, name: e.name })) : [];
	const [lastIndex, setLastIndex] = useState<number>(0);

	let selectableConditions: any[] = [];
	let v: any[] = [];
	variableOptions.forEach((vo: any) => v.push({ ...vo, category: 'Variables' }));

	let o: any[] = (Object.values(OBJECT_EVENT_TYPES).map(action => ({ id: action, name: action, category: 'Objects' })));
	selectableConditions.push(...v);
	selectableConditions.push(...o);
	let objectOptions: any[] = [];
	if (spaceModels.size > 0) {
		let i = 0;
		spaceModels.forEach((model, id) => {
			objectOptions.push({
				id: id,
				name: model.userData?.nameToShow || model.name,
			});
			if (model.nodeRef) {
				const componentIterator: IterableIterator<SceneComponent> = (model.nodeRef as ISceneNode).componentIterator();
				let componentIndex = 0;
				for (const component of componentIterator) {
					if (!!component.inputs?.showInConditions) {
						objectOptions.push({
							id: id + '---' + componentIndex,
							name: (model.userData?.nameToShow || model.name) + '---' + component.inputs?.name || '',
							type: 'component',
						});
					}
					componentIndex++;
				}
			}
		});

		_.sortBy(objectOptions, (a) => a.name?.toLowerCase());
	}
	else {
		objectOptions.push({
			id: '',
			name: ''
		});
	}

	const classes = useStyles();
	const dispatch = useDispatch();
	const [lines, setLines] = useState<ILine[]>([]);
	const [tConditions, setTConditions] = useState<ICondition[]>([]);
	const [tRootLogic, setRootLogic] = React.useState<CONDITION_JOINER>(CONDITION_JOINER.ALL);
	const emptyNestedCondition: NestedCondition = {
		conditionJoiner: CONDITION_JOINER.ALL,
		conditions: []
	};

	const [currentSpecificConditionState, setSpecificCondition] = useState<number>(-1);

	useEffect(() => {
		let conditionList: ICondition[] = [];
		if (conditionsData) {
			const conditionJoiner = conditionsData.conditionJoiner;
			setRootLogic(conditionJoiner ? conditionJoiner : CONDITION_JOINER.ALL);
			const conditions: Condition[] = conditionsData.conditions;
			conditions.forEach((condition: any, index) => {
				if (condition.type == CONDITION_TYPE.VARIABLE_CONDITION) {
					conditionList.push({
						id: 't' + tIndex + condition.varName + condition.varValue + index,
						varName: condition.varName,
						varValue: condition.varValue,
						type: CONDITION_TYPE.VARIABLE_CONDITION,
						valueOptions: getValueOptions(condition.varName),
					});
				} else if (condition.type == CONDITION_TYPE.OBJECT_CONDITION) {
					conditionList.push({
						id: 't' + tIndex + condition.objectEventType + index,
						objectEventType: condition.objectEventType,
						type: CONDITION_TYPE.OBJECT_CONDITION,
						objectId: condition.objectId,
						objectId2: condition.objectId2,
						subObject1Name: condition.subObject1Name,
						subObject2Name: condition.subObject2Name,
						localCollidingPoint1: condition.localCollidingPoint1,
						localCollidingPoint2: condition.localCollidingPoint2,
						parameters: condition.parameters,
						compInfo: condition.compInfo,
						valueOptions: [],
					});
				} else if (condition.type == CONDITION_TYPE.NESTED_TREE) {
					conditionList.push({
						id: 't' + tIndex + '-nested-tree' + index,
						nestedCondition: condition.nestedCondition,
						type: CONDITION_TYPE.NESTED_TREE,
						valueOptions: [],
					});
				}
			});
		}
		setTConditions(conditionList);
		return () => {
			setLines([]);
			setTConditions([]);
			selectableConditions = [];
			objectOptions = [];
		}
	}, []);

	useEffect(() => {

		updateConditions({ conditionJoiner: tRootLogic, conditions: tConditions });
		let linesTemp: ILine[] = [];
		linesTemp.push({
			start: `t${tIndex}-decision`,
			end: `t${tIndex}-add-button`,
			startAnchor: 'right',
			endAnchor: 'left',
			// labels: {
			// 	start: (
			// 		<div
			// 			style={{
			// 				fontSize: '.8em',
			// 				fontFamily: 'fantasy',
			// 				color: 'blue',
			// 				marginBottom: 5,
			// 			}}>
			// 			Conditions
			// 		</div>
			// 	)
			// }
		});
		tConditions.forEach((condition) => linesTemp.push({ start: `t${tIndex}-decision`, end: condition.id, startAnchor: 'right', endAnchor: 'left' }));
		setLines(linesTemp);
	}, [tConditions, tRootLogic]);

	// function serializeNestedCondition(condition: NestedCondition): string {

	// 	let conditionList: any[] = [];

	// 	condition.conditions.map((condition, index) => {

	// 		// conditions.forEach((condition: any, index) => {
	// 		if (condition.type == CONDITION_TYPE.VARIABLE_CONDITION) {

	// 			conditionList.push({
	// 				// 'variableName': condition.varName,
	// 				// 'variableValue': condition.varValue,
	// 				// 'conditionType': CONDITION_TYPE.VARIABLE_CONDITION
	// 			});
	// 		} else if (condition.type == CONDITION_TYPE.OBJECT_CONDITION) {
	// 			conditionList.push({
	// 				// id: 't' + tIndex + condition.objectEventType + index,
	// 				'objectEventType': condition.objectEventType,
	// 				type: CONDITION_TYPE.OBJECT_CONDITION,
	// 				objectId: condition.objectId,
	// 				objectId2: condition.objectId2,
	// 				subObject1Name: condition.subObject1Name,
	// 				subObject2Name: condition.subObject2Name,
	// localCollidingPoint1: condition.localCollidingPoint1,
	// localCollidingPoint2: condition.localCollidingPoint2,
	// 				parameters: condition.parameters,
	// 				compInfo: condition.compInfo,
	// 				valueOptions: [],
	// 			});
	// 		});

	// 	return JSON.stringify({ conditionJoiner: condition.conditionJoiner, condition: conditionList });
	// }

	// function serializeConditions(joiner: CONDITION_JOINER, conditions: ICondition[]): string {
	// 	let conditionList: any[] = [];
	// 	let root = {
	// 		'joiner': joiner, 'conditions': conditionList
	// 	}

	// 	conditions.map((condition, index) => {

	// 		// conditions.forEach((condition: any, index) => {
	// 		if (condition.type == CONDITION_TYPE.VARIABLE_CONDITION) {
	// 			conditionList.push({
	// 				'variableName': condition.varName,
	// 				'variableValue': condition.varValue,
	// 				'conditionType': CONDITION_TYPE.VARIABLE_CONDITION
	// 			});
	// 		} else if (condition.type == CONDITION_TYPE.OBJECT_CONDITION) {
	// 			conditionList.push({
	// 				// id: 't' + tIndex + condition.objectEventType + index,
	// 				'objectEventType': condition.objectEventType,
	// 				type: CONDITION_TYPE.OBJECT_CONDITION,
	// 				// objectId: condition.objectId,
	// 				// objectId2: condition.objectId2,
	// 				object1Name: store.getState().home.spaceModels.get(condition.objectId).userData.nameToShow,
	// 				object2Name: store.getState().home.spaceModels.get(condition.objectId2).userData.nameToShow,
	// 				subObject1Name: condition.subObject1Name,
	// 				subObject2Name: condition.subObject2Name,
	// localCollidingPoint1: condition.localCollidingPoint1,
	// localCollidingPoint2: condition.localCollidingPoint2,
	// 				parameters: condition.parameters,
	// 				compInfo: condition.compInfo,
	// 				valueOptions: [],
	// 			});
	// 		} else if (condition.type == CONDITION_TYPE.NESTED_TREE) {
	// 			if (condition.nestedCondition) {
	// 				conditionList.push({

	// 					// id: 't' + tIndex + '-nested-tree' + index,
	// 					nestedCondition: serializeConditions(condition.nestedCondition?.conditionJoiner, condition.nestedCondition.conditions as ICondition[]),
	// 					type: CONDITION_TYPE.NESTED_TREE,
	// 					valueOptions: [],
	// 				});
	// 			}
	// 		}
	// 	});

	// 	return JSON.stringify(root);

	// }

	function getValueOptions(variable: string): any[] {
		let varValuesFind = variableValues.length > 0 ? variableValues.find(v => v.name === variable) : undefined;

		if (varValuesFind) {
			return varValuesFind.values?.split(",").map((v: any) => ({ id: v.trim(), name: v.trim() }));
		} else {
			return [{ id: 0, name: 'No value' }];
		}
	}

	function addNestedTree() {
		if (depth === 5) {
			dispatch(fetchError("Cannot add a deeper tree"));
			return;
		}
		let last = lastIndex;

		let triggerArrayTemp: ICondition[] = tConditions.map(e => e);
		while (triggerArrayTemp.some(e => e.id == `t${tIndex}-trigger${last}`))
			last++;

		triggerArrayTemp.push({
			id: `t${tIndex}-trigger${last}`,
			type: CONDITION_TYPE.NESTED_TREE,
			nestedCondition: emptyNestedCondition,
			valueOptions: [],
		});
		setLastIndex(last);
		setTConditions(triggerArrayTemp);
	}

	function addNestedTreeFromData(nestedCondition: NestedCondition) {
		if (depth === 5) {
			dispatch(fetchError("Cannot add a deeper tree"));
			return;
		}
		let last = lastIndex;

		let triggerArrayTemp: ICondition[] = tConditions.map(e => e);
		while (triggerArrayTemp.some(e => e.id == `t${tIndex}-trigger${last}`))
			last++;

		triggerArrayTemp.push({
			id: `t${tIndex}-trigger${last}`,
			type: CONDITION_TYPE.NESTED_TREE,
			nestedCondition: nestedCondition,
			valueOptions: [],
		});

		setLastIndex(last);
		setTConditions(triggerArrayTemp);
	}

	function addCondtion() {
		let last = lastIndex;

		let triggerArrayTemp: ICondition[] = tConditions.map(e => e);
		while (triggerArrayTemp.some(e => e.id == `t${tIndex}-trigger${last}`))
			last++;

		if (variableValues.length > 0) {
			triggerArrayTemp.push({
				id: `t${tIndex}-trigger${last}`,
				varName: variableValues[0].name,
				varValue: getValueOptions(variableValues[0].name)[0].id,
				type: CONDITION_TYPE.VARIABLE_CONDITION,
				valueOptions: getValueOptions(variableValues[0].name),
			});
		}
		else {
			//TODO: Artur: I had commented out the if part to make CLICK as the default condition. Is it causing this bug: https://app.asana.com/0/1201627700010013/1201683644226126
			triggerArrayTemp.push({
				id: `t${tIndex}-trigger${last}`,
				objectEventType: OBJECT_EVENT_TYPES.CLICK,
				objectId: '',
				type: CONDITION_TYPE.OBJECT_CONDITION,
				valueOptions: objectOptions,
			});
		}
		setLastIndex(last);
		setTConditions(triggerArrayTemp);
	}

	function removeCondition(targetTrigger: ICondition) {
		let triggerArrayTemp: ICondition[] = tConditions.filter((e) => e.id != targetTrigger.id);
		setTConditions(triggerArrayTemp);
	}

	function changeConditionVariable(index: number, selected: string) {
		let triggerArrayTemp: ICondition[] = tConditions.map(e => e);

		if (Object.values(OBJECT_EVENT_TYPES).includes(selected as OBJECT_EVENT_TYPES)) {
			let selectedObjectEventType = selected as OBJECT_EVENT_TYPES;
			triggerArrayTemp[index].type = CONDITION_TYPE.OBJECT_CONDITION;
			triggerArrayTemp[index].objectEventType = selectedObjectEventType;
			triggerArrayTemp[index].objectId = objectOptions[0].id;

			// if (selectedObjectEventType == OBJECT_EVENT_TYPES.CLICK) {
			// 	console.log("click selected");
			// }
		}
		else {
			triggerArrayTemp[index].type = CONDITION_TYPE.VARIABLE_CONDITION;
			triggerArrayTemp[index].varName = selected;
			triggerArrayTemp[index].valueOptions = getValueOptions(selected);
			triggerArrayTemp[index].varValue = getValueOptions(selected)[0].id;
		}

		setTConditions(triggerArrayTemp);
	}

	function changeConditionValue(index: number, selected: string) {
		let triggerArrayTemp: ICondition[] = tConditions.map(e => e);
		triggerArrayTemp[index].varValue = selected;
		setTConditions(triggerArrayTemp);
	}

	function changeNestedCondition(index: number, data: NestedCondition) {
		let triggerArrayTemp: ICondition[] = tConditions.map(e => e);
		triggerArrayTemp[index].nestedCondition = data;
		setTConditions(triggerArrayTemp);
	}

	function changeOConditionObjectId(i: number, selected: string, isSecondObject = false) {

		let conditionsArrayTemp: ICondition[] = tConditions.map(e => e);

		// let nc = NodeCollider.makeNodeCollider({
		// 	nodeId: node.userData[UserDataProperties.id],
		// 	componentIndex: !optionalData?.component ? -1 : node.components.findIndex(component => component?.inputs?.name === optionalData?.component.inputs?.name),
		// 	subObjectName: optionalData?.subObjectName,
		// });
		// nc?.setConditionObject(isSecondObject, conditionsArrayTemp[i] as ObjectCondition);

		let objectId = selected;
		let nc = NodeCollider.makeNodeColliderFromObjectId(selected);
		let c = nc?.setConditionObject(isSecondObject, conditionsArrayTemp[i]);
		c && (conditionsArrayTemp[i] = c);


		// if (selected.includes('---')) {
		// 	let nodeComp = selected.split('---');
		// 	objectId = nodeComp[0];
		// 	let compIndex = nodeComp[1];

		// 	if (isSecondObject) {
		// 		conditionsArrayTemp[i].objectId2 = objectId;
		// 		if (!conditionsArrayTemp[i].compInfo) {
		// 			conditionsArrayTemp[i].compInfo = new CompInfo();
		// 		}
		// 		conditionsArrayTemp[i].compInfo!.object2ComponentIndex = Number.parseInt(compIndex);
		// 		conditionsArrayTemp[i].subObject2Name = '';
		// 		conditionsArrayTemp[i].localCollidingPoint2 = undefined;
		// 	} else {
		// 		conditionsArrayTemp[i].objectId = objectId;
		// 		if (!conditionsArrayTemp[i].compInfo) {
		// 			conditionsArrayTemp[i].compInfo = new CompInfo();
		// 		}
		// 		conditionsArrayTemp[i].compInfo!.object1ComponentIndex = Number.parseInt(compIndex);
		// 		conditionsArrayTemp[i].subObject1Name = '';
		// 		conditionsArrayTemp[i].localCollidingPoint1 = undefined;
		// 	}


		// } else {
		// 	objectId = selected;
		// 	if (isSecondObject) {
		// 		conditionsArrayTemp[i].objectId2 = objectId;
		// 		if (!conditionsArrayTemp[i].compInfo) {
		// 			conditionsArrayTemp[i].compInfo = new CompInfo();
		// 		}
		// 		conditionsArrayTemp[i].compInfo!.object2ComponentIndex = -1;
		// 	} else {
		// 		conditionsArrayTemp[i].objectId = objectId;
		// 		if (!conditionsArrayTemp[i].compInfo) {
		// 			conditionsArrayTemp[i].compInfo = new CompInfo();
		// 		}
		// 		conditionsArrayTemp[i].compInfo!.object1ComponentIndex = -1;
		// 	}

		// }
		conditionsArrayTemp[i].compInfo && (conditionsArrayTemp[i].compInfo = JSON.parse(JSON.stringify(conditionsArrayTemp[i].compInfo)));
		setTConditions(conditionsArrayTemp);
	}

	function changeObjectConditionPropName(i: number, propName: string) {
		let conditionsArrayTemp: ICondition[] = tConditions.map(e => e);
		conditionsArrayTemp[i].parameters = propName;
		setTConditions(conditionsArrayTemp);
	}

	function changeObjectConditionPropValue(i: number, propValue: string) {
		let conditionsArrayTemp: ICondition[] = tConditions.map(e => e);
		conditionsArrayTemp[i].parameters += "," + propValue;
		setTConditions(conditionsArrayTemp);
	}

	function setConditionToNode(node: ISceneNode, i: number, isSecondObject: boolean,
		optionalData?: {
			subObjectName?: string,
			component?: SceneComponent,
			localCollidingPoint?: { x: number, y: number, z: number };
		}): void {

		AddObjectClickSpy.ptrToSetConditionToNode = undefined;
		AddObjectClickSpy.actionOrConditionStatePointer = undefined;

		if (tConditions[i]) {
			if (node) {
				if (node.userData) {
					let conditionsArrayTemp: ICondition[] = tConditions.map(e => e);

					let nc = NodeCollider.makeNodeCollider({
						nodeId: node.userData[UserDataProperties.id],
						componentIndex: !optionalData?.component ? -1 : node.components.findIndex(component => component?.inputs?.name === optionalData?.component?.inputs?.name),
						subObjectName: optionalData?.subObjectName,
					});
					let c = nc?.setConditionObject(isSecondObject, conditionsArrayTemp[i]);
					c && (conditionsArrayTemp[i] = c);
					if (!isSecondObject) {

						// conditionsArrayTemp[i].objectId = nc?.nodeId || '';

						// conditionsArrayTemp[i].objectId = node.userData[UserDataProperties.id];
						if (optionalData?.subObjectName && optionalData.localCollidingPoint) {
							// conditionsArrayTemp[i].subObject1Name = optionalData.subObjectName;
							conditionsArrayTemp[i].localCollidingPoint1 = {
								x: optionalData.localCollidingPoint.x, y: optionalData.localCollidingPoint.y,
								z: optionalData.localCollidingPoint.z
							};

							// if (!!conditionsArrayTemp[i].compInfo) {
							// 	conditionsArrayTemp[i].compInfo!.object1ComponentIndex = -1
							// };
						}
					} else {
						// conditionsArrayTemp[i].objectId2 = node.userData[UserDataProperties.id];
						if (optionalData?.subObjectName && optionalData.localCollidingPoint) {
							// conditionsArrayTemp[i].subObject2Name = optionalData.subObjectName;
							conditionsArrayTemp[i].localCollidingPoint2 = {
								x: optionalData.localCollidingPoint.x, y: optionalData.localCollidingPoint.y,
								z: optionalData.localCollidingPoint.z
							};
							// if (conditionsArrayTemp[i].compInfo) {
							// 	conditionsArrayTemp[i].compInfo!.object2ComponentIndex = -1
							// };
						}
					}
					setTConditions(conditionsArrayTemp);
				}
			}
		}

		setSpecificCondition(-1);
		AddObjectClickSpy.objectSelectionState = -1;
		AddObjectClickSpy.objectSelectionStateIsObject2 = false;
		console.log(`set AddObjectClickSpy.objectSelectionState for PropPanelWrapper to -1`)
	}

	//AddObjectClickSpy.ptrToSetConditionToNode = ptrToSetConditionToNode;

	return (
		<Xwrapper>
			{lines.map((line, i) => (
				<Xarrow {...line}
					color="black" path="grid" showHead={false} strokeWidth={1} key={`t${tIndex}-line${i}`} />
			))}
			<Grid container alignItems="center" className={classes.treeItem}>
				<Grid item xs={3}>

					<DropDownButton
						id={`t${tIndex}-decision`}
						options={Object.values(CONDITION_JOINER).map(join => ({ id: join, name: join }))}
						defaultOption={tRootLogic}
						buttonClickAction={(logic: CONDITION_JOINER) => setRootLogic(logic)} size="small" />


					{/* <Select
						id={`t${tIndex}-decision`}
						variant='filled'
						label="Joiner"
						className={classes.selectBox}
						name='joiner'
						value={tRootLogic}
						onChange={(event: React.ChangeEvent<any>) => setRootLogic(event.target.value)}>
						{Object.values(CONDITION_JOINER).map((val: CONDITION_JOINER, index: number) => <MenuItem key={val} className={classes.selectMenuItem} value={val}>{val}</MenuItem>)}
					</Select> */}

				</Grid>
				<Grid item xs={9}>
					<Box>
						{tConditions.map((condition, i) => (
							<Grid container className={classes.treeNode} style={{
								// backgroundColor: `#000000${depth * 10}`
							}} id={condition.id} key={`t${tIndex}-c${i}`}>
								{condition.type == CONDITION_TYPE.NESTED_TREE && (
									<>
										<Tooltip title="Delete Nested Trigger">
											<IconButton color="inherit" size="small" key={`t${tIndex}-ic${i}`}
												className={classes.removeButton}
												// className={classes.removeTreeBtn}
												// style={{ position: 'absolute', left: '-13px', top: '-13px' }}
												onClick={() => removeCondition(condition)}
											>
												<CancelIcon key={`t${tIndex}-ci${i}`} />
											</IconButton></Tooltip>
										<LogicTreeNestedCondition
											depth={depth + 1}
											key={`tree-${tIndex}-${i}`}
											tIndex={tIndex + '-' + i}
											conditionsData={condition.nestedCondition ? condition.nestedCondition : emptyNestedCondition}
											updateConditions={(data: NestedCondition) => changeNestedCondition(i, data)}
										/>
									</>
								)}
								{condition.type == CONDITION_TYPE.VARIABLE_CONDITION && (
									<>
										<DropDownButton options={selectableConditions} defaultOption={condition.varName} size="small" style={{ marginRight: 10 }}
											buttonClickAction={(selected: string) => changeConditionVariable(i, selected)} key={`t${tIndex}-vc${i}`} />
										<DropDownButton options={condition.valueOptions} defaultOption={condition.varValue} size="small" style={{ marginRight: 10 }}
											buttonClickAction={(selected: string) => changeConditionValue(i, selected)} key={`t${tIndex}-v${i}`} />
										<IconButton color="inherit" size="small" key={`t${tIndex}-ic${i}`}
											className={classes.removeButton}
											onClick={() => removeCondition(condition)}
										>
											<CancelIcon key={`t${tIndex}-ci${i}`} />
										</IconButton>
									</>
								)}
								{condition.type == CONDITION_TYPE.OBJECT_CONDITION && (
									<>
										<div style={{
											display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'stretch'
										}
										}>
											<DropDownButton options={selectableConditions} defaultOption={condition.objectEventType} size="small" style={{
												// width: '20%',
												marginRight: 10
											}}
												buttonClickAction={(selected: string) => changeConditionVariable(i, selected)} key={`t${tIndex}-va${i}`} />

											{(condition.objectEventType == OBJECT_EVENT_TYPES.CLICK || condition.objectEventType == OBJECT_EVENT_TYPES.OBJECT_PROPERTY) && <div style={{
												display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'
											}}>
												{(currentSpecificConditionState == i) &&
													<Tooltip open={true} title="Click on an object in the space to select it. Click to Cancel">
														<div style={{ cursor: 'pointer' }}

														><IndicatorPulse onCancel={() => {

															AddObjectClickSpy.objectSelectionState = -1;
															AddObjectClickSpy.objectSelectionStateIsObject2 = false;
															AddObjectClickSpy.actionOrConditionStatePointer && AddObjectClickSpy.actionOrConditionStatePointer(-1);
															AddObjectClickSpy.actionOrConditionStatePointer = undefined;
														}} />
														</div>
													</Tooltip>}

												{(currentSpecificConditionState != i) && <Box
													onClick={(e: any) => {
														if (currentSpecificConditionState != i) {
															setSpecificCondition(i);
															AddObjectClickSpy.objectSelectionState = i;
															// console.log(`set AddObjectClickSpy.objectSelectionState for PropPanelWrapper set to ` + i)
															AddObjectClickSpy.ptrToSetConditionToNode = setConditionToNode;

															if (AddObjectClickSpy.actionOrConditionStatePointer) {
																AddObjectClickSpy.actionOrConditionStatePointer(-1);
																AddObjectClickSpy.actionOrConditionStatePointer = undefined;
															}
															AddObjectClickSpy.actionOrConditionStatePointer = setSpecificCondition;
															// store.dispatch(onShowPropertiesPanel(false));
														}
													}
													}
												>
													<IconHover dark tooltip="Click here, and then click on the object you want in the space">
														<Icons.HighlightAltRounded fontSize={'small'}
															color='secondary' />
													</IconHover>
												</Box>}
												<Tooltip title="Or select it from the dropdown">
													<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center' }}>
														{/* <Tooltip title={getConditionObjectId(condition, condition.objectId)}> */}
														<DropDownButton options={(() => {
															let def = NodeCollider.makeNodeCollider({
																nodeId: condition.objectId || '',
																componentIndex: condition.compInfo?.object1ComponentIndex || -1,
																subObjectName: condition.subObject1Name
															});
															def && !objectOptions.find(oo => oo.id === def?.getCompositeObjectId()) && objectOptions.push({ id: def.getCompositeObjectId(), name: def.getReadableObjectId() || '' })
															return objectOptions?.sort((a, b) => a.name < b.name ? -1 : 0)
														})()}
															defaultOption={NodeCollider.makeNodeCollider({
																nodeId: condition.objectId || '',
																componentIndex: condition.compInfo?.object1ComponentIndex || -1,
																subObjectName: condition.subObject1Name
															})?.getCompositeObjectId() || ''}
															size="small" style={{ marginRight: 10 }}
															buttonClickAction={(selected: string) => changeOConditionObjectId(i, selected)} key={`t${tIndex}-oco${i}`} />
														{/* <div>{NodeCollider.makeNodeCollider({
															nodeId: condition.objectId || '',
															componentIndex: condition.compInfo?.object1ComponentIndex || -1,
															subObjectName: condition.subObject1Name
														})?.getReadableObjectId() || ''
														}</div> */}
														{/* </Tooltip> */}
													</div>
												</Tooltip>
											</div>}
											{condition.objectEventType == OBJECT_EVENT_TYPES.OBJECT_PROPERTY && <>
												<Grid item xs={3}>
													<PanelInputField props={{
														type: "select",
														value: getPropKeyFromParams(condition.parameters || ''),
														values: getObjectPropKeys(condition.objectId || ''),
														onChange: (val: any) => { changeObjectConditionPropName(i, val) },
														key: `t${tIndex}-cpk${i}`,
													}} />
												</Grid>
												<Grid item xs={3}>
													<PanelInputField props={{
														type: "select",
														values: getObjectPropValues(condition.parameters || '', condition.objectId || ''),
														value: getPropValueFromParams(condition.parameters || ''),
														onChange: (val: any) => { changeObjectConditionPropValue(i, val) },
														key: `t${tIndex}-cpv${i}`,
														allowCustomInput: true
													}} />

												</Grid>


											</>}

											{condition.objectEventType == OBJECT_EVENT_TYPES.COLLIDES && <div style={{
												display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'
											}}>
												<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
													<Tooltip title="Select Object 1 from the dropdown">
														<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center' }}>
															{/* <Tooltip title={getConditionObjectId(condition, condition.objectId)}> */}
															<DropDownButton options={(() => {

																let def = NodeCollider.makeNodeCollider({
																	nodeId: condition.objectId || '',
																	componentIndex: condition.compInfo?.object1ComponentIndex || -1,
																	subObjectName: condition.subObject1Name
																});
																def && !objectOptions.find(oo => oo.id === def?.getCompositeObjectId()) && objectOptions.push({ id: def.getCompositeObjectId(), name: def.getReadableObjectId() || '' })
																return objectOptions?.sort((a, b) => a.name < b.name ? -1 : 0)
															})()}
																defaultOption={NodeCollider.makeNodeCollider({
																	nodeId: condition.objectId || '',
																	componentIndex: condition.compInfo?.object1ComponentIndex || -1,
																	subObjectName: condition.subObject1Name
																})?.getCompositeObjectId() || ''}
																size="small" style={{ marginRight: 10 }}
																buttonClickAction={(selected: string) => changeOConditionObjectId(i, selected)} key={`t${tIndex}-oco${i}`} />
														</div>
													</Tooltip>
													{(currentSpecificConditionState == i && !AddObjectClickSpy.objectSelectionStateIsObject2) &&
														<Tooltip open={true} title="Click on an object in the space to select it. Click to Cancel">
															<div style={{ cursor: 'pointer' }}

															><IndicatorPulse onCancel={() => {
																AddObjectClickSpy.actionOrConditionStatePointer && AddObjectClickSpy.actionOrConditionStatePointer(-1);
																AddObjectClickSpy.actionOrConditionStatePointer = undefined;
																AddObjectClickSpy.objectSelectionStateIsObject2 = false;
																AddObjectClickSpy.objectSelectionState = -1;
															}} />
															</div>
														</Tooltip>}

													{(currentSpecificConditionState != i) && <Box
														onClick={(e: any) => {
															if (currentSpecificConditionState != i) {
																setSpecificCondition(i);
																AddObjectClickSpy.objectSelectionState = i;
																AddObjectClickSpy.objectSelectionStateIsObject2 = false;
																// console.log(`set AddObjectClickSpy.objectSelectionState for PropPanelWrapper set to ` + i)
																AddObjectClickSpy.ptrToSetConditionToNode = setConditionToNode;

																if (AddObjectClickSpy.actionOrConditionStatePointer) {
																	AddObjectClickSpy.actionOrConditionStatePointer(-1);
																	AddObjectClickSpy.actionOrConditionStatePointer = undefined;
																}
																AddObjectClickSpy.actionOrConditionStatePointer = setSpecificCondition;
																// store.dispatch(onShowPropertiesPanel(false));
															}
														}
														}
													>
														<IconHover dark tooltip="Click here, and then click on the object you want in the space">
															<Icons.HighlightAltRounded fontSize={'small'}
																color='secondary' />
														</IconHover>
													</Box>}
												</div>

												<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
													<Tooltip title="Select Object 2 from the dropdown">
														<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center' }}>
															{/* <Tooltip title={getConditionObjectId(condition, condition.objectId2)}> */}
															<DropDownButton options={(() => {
																let def = NodeCollider.makeNodeCollider({
																	nodeId: condition.objectId2 || '',
																	componentIndex: condition.compInfo?.object2ComponentIndex || -1,
																	subObjectName: condition.subObject2Name
																});

																def && !objectOptions.find(oo => oo.id === def?.getCompositeObjectId()) && objectOptions.push({ id: def.getCompositeObjectId(), name: def.getReadableObjectId() || '' })
																return objectOptions?.sort((a, b) => a.name < b.name ? -1 : 0)
															})()}
																defaultOption={NodeCollider.makeNodeCollider({
																	nodeId: condition.objectId2 || '',
																	componentIndex: condition.compInfo?.object2ComponentIndex || -1,
																	subObjectName: condition.subObject2Name
																})?.getCompositeObjectId() || ''}

																size="small" style={{ marginRight: 10 }}
																buttonClickAction={(selected: string) => changeOConditionObjectId(i, selected, true)} key={`t${tIndex}-oco${i}`} />
															{/* </Tooltip> */}
															{/* <div>{NodeCollider.makeNodeCollider({
																nodeId: condition.objectId2 || '',
																componentIndex: condition.compInfo?.object2ComponentIndex || -1,
																subObjectName: condition.subObject2Name
															})?.getReadableObjectId() || ''}</div> */}
														</div>

													</Tooltip>
													{(currentSpecificConditionState == i && AddObjectClickSpy.objectSelectionStateIsObject2) &&
														<Tooltip open={true} title="Click on an object in the space to select it. Click to Cancel">
															<div style={{ cursor: 'pointer' }}

															><IndicatorPulse onCancel={() => {
																AddObjectClickSpy.actionOrConditionStatePointer && AddObjectClickSpy.actionOrConditionStatePointer(-1);
																AddObjectClickSpy.actionOrConditionStatePointer = undefined;
																AddObjectClickSpy.objectSelectionState = -1;
																AddObjectClickSpy.objectSelectionStateIsObject2 = false;

															}} />
															</div>
														</Tooltip>}

													{(currentSpecificConditionState != i) && <Box
														onClick={(e: any) => {
															if (currentSpecificConditionState != i) {
																setSpecificCondition(i);
																AddObjectClickSpy.objectSelectionState = i;
																AddObjectClickSpy.objectSelectionStateIsObject2 = true;
																console.log(`set AddObjectClickSpy.objectSelectionState for PropPanelWrapper set to ` + i)
																AddObjectClickSpy.ptrToSetConditionToNode = setConditionToNode;

																if (AddObjectClickSpy.actionOrConditionStatePointer) {
																	AddObjectClickSpy.actionOrConditionStatePointer(-1);
																	AddObjectClickSpy.actionOrConditionStatePointer = undefined;
																}
																AddObjectClickSpy.actionOrConditionStatePointer = setSpecificCondition;
																// store.dispatch(onShowPropertiesPanel(false));
															}
														}
														}
													>
														<IconHover dark tooltip="Click here, and then click on the object you want in the space">
															<Icons.HighlightAltRounded fontSize={'small'}
																color='secondary' />
														</IconHover>
													</Box>}
												</div>
											</div>}

											<IconButton color="inherit" size="small" key={`t${tIndex}-ic${i}`}
												className={classes.removeButton}
												onClick={() => removeCondition(condition)}
											>
												<CancelIcon key={`t${tIndex}-ci${i}`} />
											</IconButton>

										</div>
									</>
								)}

							</Grid>
						))}

						<Tooltip title="Add Trigger"><IconButton id={`t${tIndex}-add-button`}
							// variant="contained"
							color="primary"
							className={classes.addTriggerButton} size="medium"
							onClick={() => addCondtion()}
						>
							<AddCircleOutlineIcon />
							{/* Add Trigger */}
						</IconButton></Tooltip>
						{tConditions.length > 0 && <Tooltip title="Add a Nested Trigger"><IconButton id={`t${tIndex}-add-nested-button`}
							// color="secondary"
							className={classes.addNestedTriggerButton} size="medium"
							onClick={() => addNestedTree()}
						>
							<AddCircleOutlineIcon />
						</IconButton></Tooltip>}
					</Box>
				</Grid>
				{/* <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
					<Button onClick={() => {
						let c = Condition.serializeConditionTree({
							type: CONDITION_TYPE.NESTED_TREE,
							nestedCondition: {
								conditionJoiner: tRootLogic, conditions: tConditions
							}
						} as TreeCondition);

						navigator.clipboard.writeText(c);
					}}> Copy to clipboard </Button>
					<Button onClick={async () => {
						let c = (await navigator.clipboard.readText()).trim();
						addNestedTree();
						// c && Condition.deserialize(tIndex, c);
					}}> Insert from clipboard </Button>
				</Box> */}
			</Grid>
		</Xwrapper>
	);
}

export function LogicTree(

	{ tIndex, conditionData, updateTree, nodeUserData }:
		{ tIndex: string, conditionData: ConditionTree, updateTree: any, nodeUserData?: any }) {
	const spaceVariables = useSelector<AppState, any[]>(({ home }) => home.spaceVariables);
	const spaceModels = useSelector<AppState, Map<string, any>>(({ home }) => home.spaceModels);
	const currentTagGroupId = useSelector<AppState, string>(({ layer }) => layer.currentTagGroupId || '');

	const tagGroupsList = useSelector<AppState, TagGroup[]>(({ layer }) => layer.tagGroupsList);

	// >>>>>>> origin/s13/arp/v1.2changes:src/modules/home/SpaceDetail/SpaceView/MatterPortScreen/LogicTrees.tsx
	const variableOptions = spaceVariables.length > 0 ? spaceVariables
		// .filter((e: any) => e.allowControl == true)
		.map((e: any) => ({ id: e.name, name: e.name })) : [];

	const getObjectOptions = (): any[] => {
		let objectOptions: any[] = [];
		if (store.getState().home.spaceModels.size > 0) {
			spaceModels.forEach((model, key) => {
				objectOptions.push({
					id: key,
					name: model.nameToShow
				});
			});
			objectOptions.sort((a, b) => a.name - b.name);
			// console.log('[st] objectoptions: ', objectOptions.map(o => o.name));
		}
		else {
			objectOptions.push({
				id: '',
				name: ''
			});
		}
		return objectOptions;
	}
	let objectOptions: any[] = [];
	objectOptions = getObjectOptions();

	let selectableActions: any[] = Object.values(OBJECT_ACTIONS).map(action => ({ id: action, name: action, category: "Objects" }));
	if (spaceVariables.length > 0)
		selectableActions.push({ id: 'set-variable', name: 'Set variable', category: "Variables" });

	let tagGroup: TagGroup | undefined;
	let nextTagGroup: TagGroup | undefined;
	let tagGroupIndex = 0;

	if (currentTagGroupId) {
		tagGroup = tagGroupsList.find((tagGroup: TagGroup) => tagGroup.id === currentTagGroupId);
		tagGroupIndex = tagGroup ? tagGroup.sortIndex + 1 : 0
	}
	// if (tagGroup?.sortIndex !== tagGroupsList.length - 1) {
	nextTagGroup = tagGroupsList?.[tagGroupIndex] || {};
	selectableActions.push({ id: 'move-to-next-step', name: 'Move to next step', category: "Step" });
	// selectableActions.push({ id: 'wait-in-seconds', name: 'Pause (in seconds)' });
	// }
	let selectableConditions: any[] = variableOptions.concat(Object.values(OBJECT_EVENT_TYPES).map(action => ({ id: action, name: action })));

	const classes = useStyles();
	const [tConditions, setTConditions] = useState<ICondition[]>([]);
	const [tActions, setTActions] = useState<IAction[]>([]);
	const [lines, setLines] = useState<ILine[]>([]);
	const [lastIndex, setLastIndex] = useState<number>(0);
	const [tRootLogic, setRootLogic] = React.useState<CONDITION_JOINER>(CONDITION_JOINER.ALL);
	const [objectSelectionState, setObjectSelectionState] = useState<number>(-1);

	let objectSelectionLoopIntervalReference: number = 0;

	let allowedSystemVariablesAsInputSourceForNest = VariableTypeAllowedValues.getAllIOTVariables(spaceVariables);

	let variablesOptions: any[] = [];
	allowedSystemVariablesAsInputSourceForNest.forEach((name: string) => {
		variablesOptions.push({
			id: name,
			name: name
		});
	});

	function setActionToNode(node: ISceneNode, i: number): void {
		AddObjectClickSpy.ptrToSetActionToNode = undefined;
		AddObjectClickSpy.actionOrConditionStatePointer = undefined;

		if (tActions[i]) {
			if (node) {
				if (node.userData) {
					let actionArrayTemp: IAction[] = tActions.map(e => e);
					actionArrayTemp[i].objectId = node.userData[UserDataProperties.id];
					setTActions(actionArrayTemp);
					//changeAction(objectSelectionState, 'set-object');

				}
			}
		}

		setObjectSelectionState(-1);
		AddObjectClickSpy.objectSelectionState = -1;
		console.log(`set AddObjectClickSpy.objectSelectionState for PropPanelWrapper to -1`)
	}

	useEffect(() => {
		/*  Load actions from db data */
		let actionList: IAction[] = [];
		conditionData.actions.forEach((action: any, index) => {
			if (action.type == ACTION_TYPE.VARIABLE_ACTION) {
				actionList.push({
					id: 't' + tIndex + action.varName + action.varValue + index,
					varName: action.varName,
					varValue: action.varValue,
					type: ACTION_TYPE.VARIABLE_ACTION,
					valueOptions: getValueOptions(action.varName),
				});
			} else if (action.type == ACTION_TYPE.NEXTSTEP_ACTION) {
				actionList.push({
					id: 't' + tIndex + action.currentTagGroupId + index,
					type: ACTION_TYPE.NEXTSTEP_ACTION,
					currentTagGroupId: action.currentTagGroupId,
					waitInSeconds: action.waitInSeconds,
					valueOptions: [action.currentTagGroupId]
				});
			} else if (action.type == ACTION_TYPE.WAIT_ACTION) {
				actionList.push({
					id: 't' + tIndex + action.currentTagGroupId + index,
					type: ACTION_TYPE.WAIT_ACTION,
					currentTagGroupId: action.currentTagGroupId,
					valueOptions: [action.currentTagGroupId]
				});
			} else if (action.type == ACTION_TYPE.OBJECT_ACTION) {
				// if (action.actionType == OBJECT_ACTIONS.CALL_ACTIVATE) {
				// actionList.push({
				// 	id: 't' + tIndex + action.actionType + action.objectId + index,
				// 	actionType: action.actionType,
				// 	objectId: action.objectId,
				// 	iotVariableName: action.iotVariableName,
				// 	type: ACTION_TYPE.OBJECT_ACTION,
				// 	valueOptions: [],
				// });
				// } else {
				actionList.push({
					id: 't' + tIndex + action.actionType + action.objectId + index,
					actionType: action.actionType,
					objectId: action.objectId,
					parameters: action.parameters,
					type: ACTION_TYPE.OBJECT_ACTION,
					valueOptions: [],
				});
				// }
			}
		});
		setTActions(actionList);

		return () => {
			setLines([]);
			setTActions([]);
			setTConditions([]);
			selectableConditions = [];
			selectableActions = [];
			objectOptions = [];
		}
	}, []);

	useEffect(() => {
		updateTreeConditionData();
		let linesTemp: ILine[] = [];
		linesTemp.push({
			start: `t${tIndex}-decision`,
			end: `t${tIndex}-add-button`,
			startAnchor: 'right',
			endAnchor: 'left',
			labels: {
				start: (
					<div
						style={{
							fontSize: '.8em',
							fontFamily: 'fantasy',
							color: 'blue',
							marginBottom: 5,
						}}>
						Conditions
					</div>
				)
			}
		});
		tConditions.forEach((condition) => linesTemp.push({ start: `t${tIndex}-decision`, end: condition.id, startAnchor: 'right', endAnchor: 'left' }));
		setLines(linesTemp);


	}, [tConditions, tActions, tRootLogic]);

	function updateTreeConditionData() {
		let treeConditions: /* Condition[] */ any = [];
		let treeActions: /* Action[] */ any = [];

		tConditions.forEach((condition: ICondition) => {
			if (condition.type === CONDITION_TYPE.VARIABLE_CONDITION) {
				treeConditions.push({ type: CONDITION_TYPE.VARIABLE_CONDITION, varName: condition.varName, varValue: condition.varValue });
			}
			else if (condition.type === CONDITION_TYPE.NESTED_TREE) {
				treeConditions.push({ type: CONDITION_TYPE.NESTED_TREE, nestedCondition: condition.nestedCondition });
			}
			else {
				treeConditions.push({
					type: CONDITION_TYPE.OBJECT_CONDITION,
					objectEventType: condition.objectEventType,
					objectId: condition.objectId,
					objectId2: condition.objectId2,
					subObject1Name: condition.subObject1Name,
					subObject2Name: condition.subObject2Name,
					localCollidingPoint1: condition.localCollidingPoint1,
					localCollidingPoint2: condition.localCollidingPoint2,
					parameters: condition.parameters,
					compInfo: condition.compInfo,
				});
			}
		});

		tActions.forEach((action: IAction) => {

			if (action.type === ACTION_TYPE.VARIABLE_ACTION) {
				treeActions.push({ type: ACTION_TYPE.VARIABLE_ACTION, varName: action.varName, varValue: action.varValue });
			} else if (action.type === ACTION_TYPE.NEXTSTEP_ACTION) {
				treeActions.push({ type: ACTION_TYPE.NEXTSTEP_ACTION, currentTagGroupId: action.currentTagGroupId, waitInSeconds: action.waitInSeconds });
			} else if (action.type === ACTION_TYPE.WAIT_ACTION) {
				treeActions.push({ type: ACTION_TYPE.WAIT_ACTION, currentTagGroupId: action.currentTagGroupId });
			}
			else {
				// if (action.actionType == OBJECT_ACTIONS.CALL_ACTIVATE) {
				// treeActions.push({ type: ACTION_TYPE.OBJECT_ACTION, actionType: action.actionType, objectId: action.objectId, iotVariableName: action.iotVariableName });
				// } else {
				treeActions.push({ type: ACTION_TYPE.OBJECT_ACTION, actionType: action.actionType, objectId: action.objectId, parameters: action.parameters });
				// }
			}
		});

		let conditionRoot: NestedCondition = {
			conditionJoiner: tRootLogic,
			conditions: treeConditions
		};
		let conditionTree: ConditionTree = {
			id: tIndex,
			actions: treeActions,
			conditionRoot: conditionRoot
		};
		{ isUserAdmin() && navigator.clipboard.writeText(JSON.stringify(conditionTree)) }
		updateTree(conditionTree);
	}

	function getValueOptions(variable: string) {
		let varValuesFind = spaceVariables.length > 0 ? spaceVariables.find(v => v.name === variable) : undefined;

		if (varValuesFind) {
			return varValuesFind.values?.split(",").map((v: any) => ({ id: v.trim(), name: v.trim() }));
		} else {
			return [{ id: 0, name: 'No value' }];
		}
	}

	function getIConditionsFromNestedCondition(data: NestedCondition) {
		let conditionList: ICondition[] = [];
		const conditionJoiner = data.conditionJoiner;

		const conditions: Condition[] = data.conditions;
		conditions.forEach((condition: any, index) => {
			if (condition.type == CONDITION_TYPE.VARIABLE_CONDITION) {
				conditionList.push({
					id: 't' + tIndex + condition.varName + condition.varValue + index,
					varName: condition.varName,
					varValue: condition.varValue,
					type: CONDITION_TYPE.VARIABLE_CONDITION,
					valueOptions: getValueOptions(condition.varName),
				});
			} else if (condition.type == CONDITION_TYPE.OBJECT_CONDITION) {
				conditionList.push({
					id: 't' + tIndex + condition.objectEventType + index,
					objectEventType: condition.objectEventType,
					type: CONDITION_TYPE.OBJECT_CONDITION,
					objectId: condition.objectId,
					objectId2: condition.objectId2,
					subObject1Name: condition.subObject1Name,
					subObject2Name: condition.subObject2Name,
					localCollidingPoint1: condition.localCollidingPoint1,
					localCollidingPoint2: condition.localCollidingPoint2,
					parameters: condition.parameters,
					compInfo: condition.compInfo,
					valueOptions: [],
				});
			} else if (condition.type == CONDITION_TYPE.NESTED_TREE) {
				conditionList.push({
					id: 't' + tIndex + '-nested-tree' + index,
					nestedCondition: condition.nestedCondition,
					type: CONDITION_TYPE.NESTED_TREE,
					valueOptions: [],
				});
			}
		});
		return conditionList;
	}

	function updateRootConditions(data: NestedCondition) {

		let conditionList: ICondition[] = getIConditionsFromNestedCondition(data);

		setRootLogic(data.conditionJoiner ? data.conditionJoiner : CONDITION_JOINER.ALL);
		setTConditions(conditionList);
	}


	function addAction() {
		let last = lastIndex;
		let actionArrayTemp: IAction[] = tActions.map(e => e);
		while (actionArrayTemp.some(e => e.id == `t${tIndex}-action${last}`))
			last++;

		actionArrayTemp.push({
			id: `t${tIndex}-action${last}`,
			actionType: selectableActions[0].name as OBJECT_ACTIONS,
			objectId: objectOptions[0].id,
			type: ACTION_TYPE.OBJECT_ACTION,
			valueOptions: selectableActions, /** options for Object id */
		});

		// Simulation.instance.propertiesPanel.changePropertiesPanelMode(PropertiesPanelMode.Adding);
		// console.log(Simulation.instance.lastSelectedNode)
		setLastIndex(last);
		setTActions(actionArrayTemp);
	}

	function removeAction(targetAction: IAction) {
		let triggerArrayTemp: IAction[] = tActions.filter((e) => e.id != targetAction.id);
		setTActions(triggerArrayTemp);
	}

	function changeAction(index: number, selected: string) {
		let actionArrayTemp: IAction[] = tActions.map(e => e);

		if (selected == 'set-variable' && spaceVariables.length > 0) {
			actionArrayTemp[index].type = ACTION_TYPE.VARIABLE_ACTION;
			actionArrayTemp[index].varName = spaceVariables[0].name;
			actionArrayTemp[index].varValue = getValueOptions(spaceVariables[0].name)[0].id;
			actionArrayTemp[index].valueOptions = getValueOptions(spaceVariables[0].name);
		}
		else if (selected == 'move-to-next-step' && nextTagGroup) {
			actionArrayTemp[index].type = ACTION_TYPE.NEXTSTEP_ACTION;
			actionArrayTemp[index].currentTagGroupId = nextTagGroup?.id;
			actionArrayTemp[index].waitInSeconds = 0;
		}
		else if (selected == 'wait-in-seconds') {
			actionArrayTemp[index].type = ACTION_TYPE.WAIT_ACTION;
		}
		else {
			actionArrayTemp[index].type = ACTION_TYPE.OBJECT_ACTION;
			actionArrayTemp[index].actionType = selected as OBJECT_ACTIONS;
			actionArrayTemp[index].objectId = objectOptions[0].id;
			actionArrayTemp[index].valueOptions = selectableActions;
		}

		setTActions(actionArrayTemp);
	}

	function changeNextStepActionWaitSeconds(index: number, selected: string) {
		let actionArrayTemp: IAction[] = tActions.map(e => e);
		try {
			let n = Number.parseInt(selected);
			actionArrayTemp[index].waitInSeconds = n < 10 ? n : 0;
		} catch (e: any) {
			actionArrayTemp[index].waitInSeconds = 0;
		}
		setTActions(actionArrayTemp);
	}

	function changeVActionVariable(index: number, selected: string) {
		let actionArrayTemp: IAction[] = tActions.map(e => e);

		actionArrayTemp[index].varName = selected;
		actionArrayTemp[index].valueOptions = getValueOptions(selected);
		actionArrayTemp[index].varValue = getValueOptions(selected)[0].id;

		setTActions(actionArrayTemp);
	}

	function changeVActionValue(index: number, selected: string) {
		let actionArrayTemp: IAction[] = tActions.map(e => e);
		actionArrayTemp[index].varValue = selected;
		setTActions(actionArrayTemp);
	}

	function changeOActionObjectId(index: number, selected: string) {
		let actionArrayTemp: IAction[] = tActions.map(e => e);
		actionArrayTemp[index].objectId = selected;
		setTActions(actionArrayTemp);
	}

	function changeObjectActionParameters(index: number, parameters: string) {
		let actionArrayTemp: IAction[] = tActions.map(e => e);
		actionArrayTemp[index].parameters = parameters;
		setTActions(actionArrayTemp);
	}

	function changeObjectActionPropName(index: number, propName: string) {
		let actionArrayTemp: IAction[] = tActions.map(e => e);
		actionArrayTemp[index].parameters = propName;
		console.log('action is', actionArrayTemp)
		setTActions(actionArrayTemp);
	}

	function changeObjectActionPropValue(index: number, propValue: string) {
		let actionArrayTemp: IAction[] = tActions.map(e => e);
		actionArrayTemp[index].parameters += "," + propValue;
		console.log('action is', actionArrayTemp)
		setTActions(actionArrayTemp);
	}

	function changeOActionIOTVariableName(index: number, selected: string) {
		let actionArrayTemp: IAction[] = tActions.map(e => e);
		actionArrayTemp[index].iotVariableName = selected;
		setTActions(actionArrayTemp);
	}

	const theme = useTheme();

	const LogicActions = () => {
		return ((console.log(`%c[st] rendering LogicActions`)), <Grid container alignItems="center">
			<Grid item xs={12} style={{
				// backgroundColor: "#e8e6e6",
				borderRadius: '16px'
			}}>
				<Box id="actions-box"
					style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
				>
					{tActions.map((action, i) => (
						<Grid container className={classes.actionRow} id={action.id} key={`t${tIndex}-ac${i}`}>
							<div style={{
								display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center',
								// marginTop: "8px",
								borderRadius: '16px',
								padding: "8px 8px", width: "100%"
							}
							}>
								{action.type == ACTION_TYPE.VARIABLE_ACTION && (
									<>
										<DropDownButton options={selectableActions} defaultOption={'set-variable'} size="small" style={{ marginRight: 10 }}
											buttonClickAction={(selected: string) => changeAction(i, selected)} key={`t${tIndex}-al${i}`} />
										<DropDownButton options={variableOptions} defaultOption={action.varName} size="small" style={{ marginRight: 10 }}
											buttonClickAction={(selected: string) => changeVActionVariable(i, selected)} key={`t${tIndex}-ava${i}`} />
										<DropDownButton options={action.valueOptions} defaultOption={action.varValue} size="small" style={{ marginRight: 10 }}
											buttonClickAction={(selected: string) => changeVActionValue(i, selected)} key={`t${tIndex}-av${i}`} />
									</>
								)}
								{action.type == ACTION_TYPE.NEXTSTEP_ACTION && (
									<><DropDownButton options={selectableActions} defaultOption={'move-to-next-step'} size="small" style={{ marginRight: 10 }}
										buttonClickAction={(selected: string) => changeAction(i, selected)} key={`t${tIndex}-al${i}`} />
										<Grid item xs={3}>
											<PanelInputField props={{
												type: "textInput", label: 'Number of seconds to wait', text: action.waitInSeconds!, onChange: (event: any) => {
													changeNextStepActionWaitSeconds(i, event)
												}, key: `t${tIndex}-ap${i}`
											}} />
										</Grid></>
								)}
								{action.type == ACTION_TYPE.WAIT_ACTION && (
									<DropDownButton options={selectableActions} defaultOption={'wait-in-seconds'} size="small" style={{ marginRight: 10 }}
										buttonClickAction={(selected: string) => {
											changeAction(i, selected)
										}} key={`t${tIndex}-al${i}`} />
								)}
								{action.type == ACTION_TYPE.OBJECT_ACTION && (
									<>
										{/* <Grid item xs={7}> */}
										<DropDownButton options={selectableActions} defaultOption={action.actionType} size="small" style={{ marginRight: 10 }}
											buttonClickAction={(selected: string) => changeAction(i, selected)} key={`t${tIndex}-al${i}`} />

										<div style={{
											display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'
										}
										}>
											{(objectSelectionState == i) &&
												// <PanelInputField props={{
												// 	label: "Action", type: "readOnlyText", text: "Select an object in the scene", style: { width: '30%', marginRight: 10 },
												// 	onFocus: (e: any) => {
												// 	}
												// }} />
												<Tooltip open={true} title="Click on an object in the space to select it. Click to Cancel">
													<div style={{ cursor: 'pointer' }}

													><IndicatorPulse onCancel={() => {
														setObjectSelectionState(-1);
														AddObjectClickSpy.objectSelectionState = -1;
														AddObjectClickSpy.objectSelectionStateIsObject2 = false;
														AddObjectClickSpy.actionOrConditionStatePointer = undefined;
													}} />
													</div>
												</Tooltip>
											}
											{(objectSelectionState != i) &&
												<Box
													onClick={(e: any) => {
														if (objectSelectionState != i) {
															/*
															objectSelectionLoopIntervalReference = window.setInterval(listenFor3DSceneInteraction, 100);
															window.onkeydown = (e:KeyboardEvent) => {
																console.log(e)
																if(e.key === "Escape") {
																	clearListeningFor3DSceneInteraction();
																}
															}*/

															setObjectSelectionState(i);
															AddObjectClickSpy.objectSelectionState = i;
															AddObjectClickSpy.ptrToSetActionToNode = setActionToNode;

															if (AddObjectClickSpy.actionOrConditionStatePointer) {
																AddObjectClickSpy.actionOrConditionStatePointer(-1);
																AddObjectClickSpy.actionOrConditionStatePointer = undefined;
															}

															AddObjectClickSpy.actionOrConditionStatePointer = setObjectSelectionState;
														}
													}}
												>
													<IconHover dark tooltip="Click here, and then click on the object you want in the space">
														<Icons.HighlightAltRounded fontSize={'small'}
															color='secondary' />
													</IconHover>
												</Box>
											}

											<Tooltip title="Or select it from the dropdown">
												<DropDownButton options={getObjectOptions()?.sort((a, b) => a.name < b.name ? -1 : 0)}
													defaultOption={action.objectId}
													size="small"
													style={{ marginRight: 10 }}
													buttonClickAction={(selected: string) => changeOActionObjectId(i, selected)} key={`t${tIndex}-av${i}`}
												/>
											</Tooltip>
										</div>
										{/* </Grid> */}
										{
											(action.actionType === OBJECT_ACTIONS.MOVE ||
												action.actionType === OBJECT_ACTIONS.ROTATE ||
												action.actionType === OBJECT_ACTIONS.SCALE) &&
											<Grid item xs={5}>
												<PanelInputField props={{
													type: "XYZ",
													text: action.parameters!,
													onChange: (event: any) => { changeObjectActionParameters(i, event) },
													key: `t${tIndex}-ap${i}`,
													explicitSave: true,
													defaultValue: (action.actionType === OBJECT_ACTIONS.SCALE) ? '1,1,1' : '0,0,0'
												}} />
											</Grid>
										}
										{
											action.actionType === OBJECT_ACTIONS.SET_CUSTOM_PROP &&
											<><Grid item xs={3}>
												<PanelInputField props={{
													type: "select",
													value: getPropKeyFromParams(action.parameters || ''),
													values: getObjectPropKeys(action.objectId || ''),
													onChange: (val: any) => { changeObjectActionPropName(i, val) },
													key: `t${tIndex}-cpk${i}`,
												}} />
											</Grid>
												<Grid item xs={3}>
													<PanelInputField props={{
														type: "select",
														values: getObjectPropValues(action.parameters || '', action.objectId || ''),
														value: getPropValueFromParams(action.parameters || ''),
														onChange: (val: any) => { changeObjectActionPropValue(i, val) },
														key: `t${tIndex}-cpv${i}`,
														allowCustomInput: true
													}} />
												</Grid>
											</>
										}

										{/* {
											(action.actionType == OBJECT_ACTIONS.CALL_ACTIVATE) &&
											<DropDownButton options={variablesOptions} defaultOption={action.iotVariableName} size="small" style={{ marginRight: 10 }}
												buttonClickAction={(selected: string) => changeOActionIOTVariableName(i, selected)} key={`t${tIndex}-aiot${i}`} />
										} */}
										{/* </div> */}
									</>
								)}
								<Grid item xs={1}>
									<IconButton color="inherit" size="small" key={`aic${i}`}
										className={classes.removeButton}
										onClick={() => removeAction(action)}
									>
										<CancelIcon key={`t${tIndex}-aci${i}`} />
									</IconButton>
								</Grid>
							</div>
						</Grid>
					))}
					{/* <IconButton id={`t${tIndex}-add-button2`} color="secondary"
							className={classes.addTriggerButton} size="medium"
							onClick={() => addAction()}
						>
							<AddCircleOutlineIcon />
						</IconButton> */}
					<Button id={`t${tIndex}-add-button2`} color="secondary"
						style={{ textAlign: "center", display: "block" }}
						// className={classes.addTriggerButton}
						size="small"
						variant="outlined"
						onClick={() => addAction()}
					>Add Action
					</Button>
				</Box>
			</Grid>
		</Grid>)

	}
	return (
		<Card
			variant="outlined"
			raised
			className={classes.paper}
		// style={{backgroundColor: theme.palette.background.default}}
		>
			{/* <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }} >
				{[{ sub: "Something is", text: "Clicked" }, { sub: "Something is", text: "Hovered on" }, { sub: "", text: "Variable value is" }].map(x => {
					return (
						<Box style={{
							display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
							borderRadius: '16px', border: '0.5px solid grey',
							margin: '8px'
						}} >
							<div style={{
								fontSize: '10px',
								// display: "block",
								paddingTop: '8px',
								paddingLeft: '8px',
								paddingRight: '8px'
							}}>
								{x.sub}
							</div>
							<div style={{
								fontSize: '14px',
								// display: "block",
								padding: '8px',
								paddingTop: '0px'
							}}>
								{x.text}
							</div>
						</Box>


					)
				})}
			</Box> */}
			<div style={{
				// backgroundColor: "#3060722e",
				border: '0.5px solid primary',
				padding: "8px 8px", borderRadius: '16px', margin: '8px',
				display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center'
			}}>
				{/* <Typography className={'h7'}
				> If </Typography> */}
				<div style={{
					// position: 'absolute', left: '16px', top: 16,
					fontWeight: 500,
					color: theme.palette.success.dark
				}}>If</div>
				<LogicTreeNestedCondition
					depth={1}
					tIndex={tIndex.toString()}
					conditionsData={conditionData.conditionRoot as NestedCondition}
					updateConditions={(data: NestedCondition) => updateRootConditions(data)}
				/>
			</div>
			{/* <Divider style={{ margin: '10px 0 10px 50px' }} /> */}

			<div style={{
				// backgroundColor: "#3060722e",
				borderTop: '0.5px solid lightgrey',
				padding: "8px 8px",
				// borderRadius: '16px',
				margin: '8px',
				display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center'
			}}>
				{/* <Typography className={'h7'}
				> Then</Typography> */}
				<div style={{
					// position: 'relative', left: '16px', top: 16,
					color: theme.palette.success.dark,
					fontWeight: 500
				}}>Then</div>
				<LogicActions />
			</div>
		</Card>
	)
}

interface Props {
	tagGroup: any,
	// logic: Logic,
	// setLogic: (logic: Logic) => void,
	openLogicTreesDrawer?: boolean,
	onCloseDrawer(): void
	setHasPendingChanges(value: boolean): void;
	// hasChanges: any;
}

export default function LogicTrees({
	tagGroup,
	// logic,
	// setLogic,
	openLogicTreesDrawer,
	onCloseDrawer,
	setHasPendingChanges,
	// hasChanges,
}: Props) {
	const [logic, setLogic] = useState<Logic>({ conditions: [] });
	const [isChanged, setIsChanged] = useState<boolean>(false);
	const classes = useStyles();

	const dispatch = useDispatch();
	// const currentSpace = useSelector<AppState, AppState['home']>(({ home }) => home);
	// const { currentLesson, currentTagGroupId
	// } = useSelector<AppState, AppState['home']>(({ home }) => home);

	// const currentSpace = useSelector<AppState, SpaceData | undefined>(({ home }) => home.currentSpace);
	// const currentLesson = useSelector<AppState, Lesson | null>(({ home }) => home.currentLesson);
	// const currentTagGroupId = useSelector<AppState, string>(({ layer }) => layer.currentTagGroupId);

	useEffect(() => {

		// const getTagGroupData = async () => {
		// 	if (currentSpace?.id) {
		// 		if (currentLesson?.id) {
		// 			if (currentTagGroupId) {
		// 				//setTagGroupData(await (await firestore.doc(`Spaces/${currentSpace?.id}/lessons/${currentLesson}/tagGroups/${currentTagGroupId}`).get()).data);
		// 				let docRef = await firestore.doc(`Spaces/${currentSpace?.id}/lessons/${currentLesson?.id}/tagGroups/${currentTagGroupId}`);
		// 				let doc = await docRef.get();
		// 				let data = await doc.data();
		// 				if (data) {
		// 					//setTagGroupData(data);
		// 					if (data.logic) {
		// 						setLogic({
		// 							conditions: (data.logic as Logic).conditions.map(e => {
		// 								if (!e.id) {
		// 									return ({ id: ShortUuid.generate(), ...e });
		// 								}
		// 								return e;
		// 							})
		// 						});
		// 					} else {
		// 						setLogic({ conditions: [] });
		// 					}
		// 				}
		// 			}
		// 		}
		// 	}
		// }

		//getTagGroupData();
		// From from tagGroup
		// console.log(`%c[st] rendering useEffect LogicTrees - ${tagGroup.bannerText}`,'color: orange;')

		if (tagGroup[UserDataProperties.logic]) {

			setLogic({
				conditions: (tagGroup[UserDataProperties.logic] as Logic).conditions.map(e => {
					if (!e.id) {
						return ({ id: ShortUuid.generate(), ...e });
					}
					return e;
				})
			});

		}
		else {
			setLogic({ conditions: [] });
		}

		return () => {
			setLogic({ conditions: [] });
		}
	}, [
		tagGroup,
	]);

	useEffect(() => {
		let hasChanges = detectChanges();
		console.log(`detecting ${hasChanges}`)
		setIsChanged(hasChanges);
		setHasPendingChanges(hasChanges);
	}, [logic]);

	// React.useEffect(() => {
	// 	hasChanges.current = detectChanges
	// }, []);

	function addNewLogicTree() {
		let conditionTreesTemp: ConditionTree[] = logic.conditions.map(e => e);
		let newConditionTree: ConditionTree = {
			id: ShortUuid.generate(),
			actions: [],
			conditionRoot: {
				conditionJoiner: CONDITION_JOINER.ALL,
				conditions: []
			}
		};
		conditionTreesTemp.push(newConditionTree);
		setLogic({ conditions: conditionTreesTemp });
	}
	function saveLogicTree() {
		// Simulation.instance.propertiesPanel.updateLogic(logic);

		tagGroup.logic = logic;
		dispatch(onUpdateTagGroup(tagGroup));
		setIsChanged(false);
		setHasPendingChanges(false);
	}

	function isLogicEmpty(logic: Logic) {
		if (logic.conditions && logic.conditions.length == 0) {
			return true;
		}
		let actionsPresent = logic.conditions.find((condition: ConditionTree) => condition.actions.length > 0);
		let conditionsPresent = logic.conditions.find((condition: ConditionTree) => condition.conditionRoot.conditions.length > 0);

		return !actionsPresent && !conditionsPresent;

	}

	function detectChanges() {
		// console.log(`[st] detecting changes ${JSON.stringify(logic)} ----- ${JSON.stringify(tagGroup[UserDataProperties.logic])}`);
		if (!tagGroup[UserDataProperties.logic]) {
			return !isLogicEmpty(logic);
		}
		return _.isEqual(JSON.parse(JSON.stringify(tagGroup[UserDataProperties.logic])), JSON.parse(JSON.stringify(logic))) === false;
	}

	function updateTree(data: ConditionTree, index: number) {
		let conditionTreesTemp: ConditionTree[] = logic.conditions.map(e => e);
		conditionTreesTemp[index] = data;
		setLogic({ conditions: conditionTreesTemp });
	}

	function removeTree(index?: string) {
		if (index) {
			let conditionTreesTemp: ConditionTree[] = logic.conditions.filter(e => e.id != index);
			setLogic({ conditions: conditionTreesTemp });
		}
	}
	function dashedContainer(text: string, onClick: any) {
		return <div style={{ border: "1px dashed grey", width: "100%" }} onClick={onClick}>{text}</div>
	}

	return (<>

		<React.Fragment key={`Logic-Trees-for-${store.getState().home.currentSpace?.id}-${store.getState().layer.currentLesson?.id}-${store.getState().layer.currentTagGroupId}`}>
			{/* <Drawer
			anchor="right"
			open={openLogicTreesDrawer ? true : false}
			onClose={onCloseDrawer}
		> */}
			{/* <Prompt
				when={isChanged}
				message="You have unsaved changes. Are you sure to leave this page..?"
			/> */}

			<Box className={classes.drawer}>
				{logic.conditions.map((conditionTree: ConditionTree, index: number) => (
					<Box className={classes.treeBox} key={`tree-${conditionTree.id}-wrapper`}>
						<LogicTree
							// tagGroup={tagGroup}
							key={`tree-${conditionTree.id}`}
							tIndex={conditionTree.id ? conditionTree.id : 'x'}
							conditionData={conditionTree}
							updateTree={(data: ConditionTree) => updateTree(data, index)}
						/>
						<IconButton color="secondary" size="small" key={`remove-tree-${conditionTree.id}-button`}
							className={classes.removeTreeBtn}
							onClick={() => {
								console.log(`[st] deleting interaction`);

								let response = window.confirm("Are you sure you want to delete this interaction - including all its IFs and THENs?");
								if (response) {
									removeTree(conditionTree.id)
								}
							}}
						>
							<Tooltip title={'Delete Interaction?'} key={`tree-${conditionTree.id}-tooltip`}>
								<CancelIcon key={`remove-tree-${conditionTree.id}-button-icon`} />
							</Tooltip>
						</IconButton>
					</Box>
				))}
				{/* {dashedContainer("Add Interaction", () => addNewLogicTree())} */}


			</Box>
			<Box position="sticky" color='default'
			// style={{ top: 'auto', bottom: 0 }}
			>

				{/* <Toolbar variant="dense" style={{ border: '1px solid lightgrey' }}> */}
				<Box component="div" alignItems="center">
					<Button
						variant='contained'
						color='primary'
						size='small'
						disabled={isChanged == false}
						className={classes.addLogicTreeButton}
						type='button'
						onClick={() => saveLogicTree()}
					>
						Save
					</Button>
					<Button
						variant='contained'
						color='secondary'
						size='small'
						style={{ float: 'right', marginLeft: 20 }}
						className={classes.addLogicTreeButton}
						type='button'
						onClick={() => addNewLogicTree()}
					>
						Add New
					</Button>
				</Box>
				{/* </Toolbar> */}
			</Box>

			{/* </Drawer> */}
		</React.Fragment>
	</>
	)
}

export function getPropKeyFromParams(actionOrConditionParams: string) {
	let a = actionOrConditionParams?.split(',') || [];
	return a.length >= 1 ? a[0] : '';
}

export function getPropValueFromParams(actionOrConditionParams: string) {
	let a = actionOrConditionParams?.split(',') || [];
	console.log('value is', a.length, a[1], a.length >= 2 ? a[1] : '')
	return a.length >= 2 ? a[1] : '';
}

export function getObjectPropKeys(actionOrConditionObjectId: string) {
	return Object.keys(getCustomProps(store.getState().home.spaceModels.get(actionOrConditionObjectId || '')?.nodeRef.userData.customProps || ''));
}

export function getObjectPropValue(objectId: string, propName: string) {
	return getCustomProps(store.getState().home.spaceModels.get(objectId || '')?.nodeRef?.userData.customProps)[propName];
}

export function getObjectPropValues(actionOrConditionParams: string, actionOrConditionObjectId: string) {
	let values = [(getCustomProps(store.getState().home.spaceModels.get(actionOrConditionObjectId || '')?.nodeRef?.userData.customProps || ''))[getPropKeyFromParams(actionOrConditionParams) || ''] || ''];
	let cv = getPropValueFromParams(actionOrConditionParams);
	if (!values.includes(cv)) {
		values.push(cv);
	}
	return values;
}

// export function getConditionObjectId(condition: ICondition, objectId: string | undefined): string {

// 	let result = objectId || '';

// 	if (objectId && condition.compInfo) {
// 		result = objectId;
// 		if (condition.objectId === objectId) {

// 			if (condition.compInfo.object1ComponentIndex >= 0) {
// 				// let node: ISceneNode | undefined = store.getState().home.spaceModels.get(objectId);
// 				// node && node.components[condition.compInfo.object1ComponentIndex]
// 				result = objectId + '---' + condition.compInfo.object1ComponentIndex;
// 			}

// 		} else if (condition.objectId2 === objectId) {

// 			if (condition.compInfo.object2ComponentIndex >= 0) {
// 				result = objectId + '---' + condition.compInfo.object2ComponentIndex;
// 			}
// 		}
// 	} else if (objectId && condition.objectId === objectId && condition.subObject1Name) {
// 		result = objectId + '---' + condition.subObject1Name;

// 	} else if (objectId && condition.objectId2 === objectId && condition.subObject2Name) {
// 		result = objectId + '---' + condition.subObject2Name;
// 	}
// 	// console.log('objectId is', result, objectId, condition)
// 	return result;
// }


// function deserializeConditions(tIndex: number, conditionString: string): string {

// 	let conditionsTree = JSON.parse(conditionString);
// 	let tRootLogic = conditionsTree.joiner;
// 	// let root = {
// 	// 	'joiner': joiner, 'conditions': []
// 	// }

// 	function getObjectsIds(objectCondition: ObjectCondition): NodeCompCollider[] {
// 		let success = false;
// 		let result: NodeCompCollider[] = [];
// 		try {

// 			[objectCondition.objectId, objectCondition.objectId2].forEach(oc => {

// 				if (!!oc) {

// 					let objArray = (oc || '').split('---').map(i => i.trim()).filter(i => !!i);
// 					let objName = objArray[0];

// 					let ms = [...store.getState().home.spaceModels.values()].filter(m => m.userData.nameToShow === objName);

// 					if (ms.length > 1) {
// 						store.dispatch(fetchError(`You have more than one object named: ${oc}. Please rename the object so it can be uniquely identified.`))
// 					} else {
// 						let nc = (new NodeCompCollider()).setNodeId(ms[0].userData.id);
// 						result.push(nc);
// 						if (objArray.length >= 2) {
// 							let compName = objArray[1];
// 							let componentIndex = nc.node?.components.findIndex((c, i) => !!c.inputs?.showInConditions && c.inputs?.name === compName) || -1;
// 							nc.setComponentIndex(componentIndex);
// 						}
// 					}
// 				}
// 			})

// 			// if (!!objectCondition.objectId2) {
// 			// 	let obj2Array = (objectCondition.objectId2 || '').split('---').map(i => i.trim()).filter(i => !!i);
// 			// 	let objName2 = obj2Array[0];
// 			// 	let ms = [...store.getState().home.spaceModels.values()].filter(m => m.userData.nameToShow === objName2);
// 			// 	if (ms.length > 1) {
// 			// 		store.dispatch(fetchError(`You have more than one object named: ${objectCondition.objectId}. Please rename the object so it can be uniquely identified.`))
// 			// 	} else {
// 			// 		let nc2 = (new NodeCompCollider()).setNodeId(ms[0].userData.id);
// 			// 		result.push(nc2);
// 			// 		if (obj2Array.length >= 2) {
// 			// 			let compName2 = obj2Array[1];
// 			// 			let componentIndex = nc2.node?.components.findIndex((c, i) => !!c.inputs?.showInConditions && c.inputs?.name === compName2) || -1;
// 			// 			nc2.setComponentIndex(componentIndex);
// 			// 		}
// 			// 	}
// 			// }

// 		} catch (e: any) {
// 			console.error(e);
// 			store.dispatch(fetchError(`Bad Object name ${objectCondition.objectId} given with condition. Please check your input and try again`))
// 		}
// 		return result;
// 	}

// 	let conditionList: ICondition[] = [];
// 	conditionsTree.conditions.map((condition: any, index: number) => {

// 		// conditions.forEach((condition: any, index) => {
// 		let objects = getObjectsIds();

// 		if (condition.type == CONDITION_TYPE.VARIABLE_CONDITION) {
// 			conditionList.push({
// 				id: 't' + tIndex + condition.varName + condition.varValue + index,
// 				varName: condition.variableName, //should exist
// 				varValue: condition.varValue, //should exist
// 				type: condition.conditionType, //should exist, check
// 				valueOptions: getValueOptions(condition.varName),
// 			});
// 		} else if (condition.type == CONDITION_TYPE.OBJECT_CONDITION) {
// 			let o1 = getObjectsIds(condition)
// 			conditionList.push({
// 				id: 't' + tIndex + condition.objectEventType + index,
// 				objectEventType: condition.objectEventType,
// 				type: CONDITION_TYPE.OBJECT_CONDITION,
// 				objectId: objects[0].nodeId,
// 				objectId2: objects[1]?.nodeId,
// 				parameters: condition.parameters,
// 				compInfo: new CompInfo(objects[0].componentIndex, objects[1].componentIndex),
// 				valueOptions: [],
// 			});
// 		} else if (condition.type == CONDITION_TYPE.NESTED_TREE) {
// 			conditionList.push({
// 				id: 't' + tIndex + '-nested-tree' + index,
// 				nestedCondition: condition.nestedCondition,
// 				type: CONDITION_TYPE.NESTED_TREE,
// 				valueOptions: [],
// 			});
// 		}
// 	});

// 	return JSON.stringify(root);

// }


// function getObjectsIds(objectCondition: ObjectCondition): NodeCollider[] {
// 	let success = false;
// 	let result: NodeCollider[] = [];
// 	try {

// 		[objectCondition.objectId, objectCondition.objectId2].forEach(oc => {

// 			if (!!oc) {

// 				let objArray = (oc || '').split('---').map(i => i.trim()).filter(i => !!i);
// 				let objName = objArray[0];

// 				let ms = [...store.getState().home.spaceModels.values()].filter(m => m.userData.nameToShow === objName);

// 				if (ms.length > 1) {
// 					store.dispatch(fetchError(`You have more than one object named: ${oc}. Please rename the object so it can be uniquely identified.`))
// 				} else {
// 					let nc = (new NodeCompCollider()).setNodeId(ms[0].userData.id);
// 					result.push(nc);
// 					if (objArray.length >= 2) {
// 						let compName = objArray[1];
// 						let componentIndex = nc.node?.components.findIndex((c, i) => !!c.inputs?.showInConditions && c.inputs?.name === compName) || -1;
// 						nc.setComponentIndex(componentIndex);
// 					}
// 				}
// 			}
// 		})

// 		// if (!!objectCondition.objectId2) {
// 		// 	let obj2Array = (objectCondition.objectId2 || '').split('---').map(i => i.trim()).filter(i => !!i);
// 		// 	let objName2 = obj2Array[0];
// 		// 	let ms = [...store.getState().home.spaceModels.values()].filter(m => m.userData.nameToShow === objName2);
// 		// 	if (ms.length > 1) {
// 		// 		store.dispatch(fetchError(`You have more than one object named: ${objectCondition.objectId}. Please rename the object so it can be uniquely identified.`))
// 		// 	} else {
// 		// 		let nc2 = (new NodeCompCollider()).setNodeId(ms[0].userData.id);
// 		// 		result.push(nc2);
// 		// 		if (obj2Array.length >= 2) {
// 		// 			let compName2 = obj2Array[1];
// 		// 			let componentIndex = nc2.node?.components.findIndex((c, i) => !!c.inputs?.showInConditions && c.inputs?.name === compName2) || -1;
// 		// 			nc2.setComponentIndex(componentIndex);
// 		// 		}
// 		// 	}
// 		// }

// 	} catch (e: any) {
// 		console.error(e);
// 		store.dispatch(fetchError(`Bad Object name ${objectCondition.objectId} given with condition. Please check your input and try again`))
// 	}
// 	return result;
// }

// function deserializeConditionsFromUser(tIndex: number, conditionString: string, getValueOptions: (variable: string) => any[]): NestedCondition {

// 	let conditionsTree = JSON.parse(conditionString);
// 	// let tRootLogic = conditionsTree.joiner;
// 	// let root = {
// 	// 	'joiner': joiner, 'conditions': []
// 	// }


// 	let conditionList: Condition[] = [];
// 	let result: NestedCondition = { conditionJoiner: conditionsTree.joiner, conditions: conditionList }
// 	conditionsTree.conditions.map((condition: any, index: number) => {

// 		// conditions.forEach((condition: any, index) => {

// 		if (condition.type == CONDITION_TYPE.VARIABLE_CONDITION) {
// 			conditionList.push({
// 				// id: 't' + tIndex + condition.varName + condition.varValue + index,
// 				varName: condition.variableName, //should exist
// 				varValue: condition.varValue, //should exist
// 				type: condition.conditionType, //should exist, check
// 				valueOptions: getValueOptions(condition.varName),
// 			} as VarCondition);
// 		} else if (condition.type == CONDITION_TYPE.OBJECT_CONDITION) {
// 			let ncs = getObjectsIds(condition);
// 			conditionList.push({
// 				// id: 't' + tIndex + condition.objectEventType + index,
// 				objectEventType: condition.objectEventType,
// 				type: CONDITION_TYPE.OBJECT_CONDITION,
// 				objectId: ncs[0].nodeId,
// 				objectId2: ncs[1]?.nodeId,
// 				parameters: condition.parameters,
// 				compInfo: new CompInfo(ncs[0].componentIndex, ncs[1].componentIndex),
// 				valueOptions: [],
// 			} as ObjectCondition);
// 		} else if (condition.type == CONDITION_TYPE.NESTED_TREE) {
// 			conditionList.push({
// 				// id: 't' + tIndex + '-nested-tree' + index,
// 				nestedCondition: condition.nestedCondition,
// 				type: CONDITION_TYPE.NESTED_TREE,
// 				valueOptions: [],
// 			} as unknown as NestedCondition);
// 		}
// 	});

// 	return JSON.stringify(result);

// }