import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { makeStyles, Theme, createStyles, fade } from '@material-ui/core/styles';
import {
  Lesson,
  ISceneData,
  SpaceData,
  TagGroup,
} from 'types/models/home/HomeApp';
import {
  Box,
  Button,
  List,
  ListItem,
  Tooltip,
  TextField,
  Typography,
  Card,
  CardActions,
  CardContent,
  CardActionArea,
  IconButton,
  Fab,
  Popover,
  FormControlLabel
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { firestore } from '@crema/services/auth/firebase/firebase';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'redux/store';
import { canEditSpace } from '@crema/utility/Utils';
import IntlMessages from '@crema/utility/IntlMessages';
import { Fonts } from 'shared/constants/AppEnums';
import { grey, red } from '@material-ui/core/colors';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import { onGetLayer, onGetLessonsList } from 'redux/actions/Layer';
import { makeid } from 'shared/helpers/helper';
import StepsBar from './StepsBar';
import { SET_PRESENTATION_MODE } from 'types/actions/Home.action';

import Layout from '../layout';
import { defaultLayerNameSingular, UseCase } from 'shared/constants/AppConst';
import KeyboardArrowLeftRoundedIcon from '@mui/icons-material/KeyboardArrowLeftRounded';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import ItemHover from '@crema/core/AppAnimation/ItemHover';
import Spacer from '@crema/core/Spacer';
import ShareRoundedIcon from '@mui/icons-material/ShareRounded';
import { showMessage } from 'redux/actions';
import { goToLesson } from 'redux/actions/Layer';
import { AuthUser } from 'types/models/AuthUser';
import { store } from 'App';
import { Checkbox } from '@mui/material';
import TagGroups from './TagGroups';
import { onAddTagGroup } from 'redux/actions/Step';

//tags list overflow bug
// tag menu should be hidden pres mode
// popover hide in pres mode
// 3d prop panel not visible anymore

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    heading: {
      fontWeight: 500,
      color: 'black',
      lineHeight: '48px',
    },
    root: {
      width: '100%',
      // maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
    nested: {
      paddingLeft: theme.spacing(2),
    },
    createTagsLink: {
      paddingLeft: theme.spacing(4),
      alignItems: 'center',
      justifyContent: 'center',
    },
    buttonGroup: {
      alignContent: 'center',
      justifyContent: 'center',
      width: '80%',
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    avatar: {
      cursor: 'pointer',
      backgroundColor: grey[100],
      border: `1px dashed ${grey[400]}`,
    },
    pointer: {
      cursor: 'pointer',
    },
    addIconRoot: {
      color: theme.palette.primary.light,
      fontWeight: Fonts.LIGHT,

    },
    btnRoot: {
      backgroundColor: red[500],
      color: theme.palette.primary.contrastText,
      paddingLeft: 24,
      paddingRight: 24,
      marginTop: 12,
      '&:hover, &:focus': {
        backgroundColor: red[800],
        color: theme.palette.primary.contrastText,
      },
    },
    editIcon: {
      marginRight: 5,
      cursor: 'pointer',
      // height: '20px'
    },
    deleteIcon: {
      marginRight: 5,
      // color: 'red',
      cursor: 'pointer',
      // height: '20px'
    },
    lessonBox: {
      // minWidth: '320px',
      // maxWidth: '320px',
      height: '100%',
      // boxShadow: '0px 0px 2px 2px rgba(0,0,0,0.12)',
      // transition: 'all 0.5s ease',
      // paddingTop: '0px',
      [theme.breakpoints.down('xs')]: {
        minWidth: '100%',
        maxWidth: '100%',
        width: '100%',
      },
    },
    lessonContainer: {
      // backgroundColor: "lightgrey",
      borderRadius: "15px",
      display: 'flex', flexDirection: 'column', justifyContent: 'flex-start',
      margin: '5px 5px 5px 5px',
      backgroundColor: "aliceblue",
      padding: '5px 2px'
      //alignItems: 'flex-start'
    },
    lessonMetadata: {
      textAlign: 'right',
      alignSelf: 'flex-end'
    },
    lessonScrollbar: {
      height: "calc(100% - 49px)",
    }
  }),
);

interface Props {
  setMinimized: (isMinimized: boolean) => void;
  isMinimized: boolean;
  presentationMode: boolean;
}

export default function LessonSidebar({ setMinimized, isMinimized, presentationMode }: Props) {

  const currentSpaceId = useSelector<AppState, string>(({ home }) => home.currentSpace?.id || '');
  const currentSpaceDid = useSelector<AppState, string>(({ home }) => home.currentSpace?.did || '');
  const currentSpaceUseCase = useSelector<AppState, UseCase | null>(({ home }) => home.currentSpace?.primaryUseCase || null);
  // const currentSpaceProjectDid = useSelector<AppState, string>(({ home }) => home.currentSpace?.currentSpaceProject?.did || '');
  const currentSpaceProjectDid = useSelector<AppState, string>(
    ({ projects }) => projects?.projectDetail?.did || ''
  );
  const currentLesson = useSelector<AppState, Lesson | null>(({ layer }) => layer.currentLesson);
  const lessonsList = useSelector<AppState, Lesson[]>(({ home }) => home.lessonsList);

  const { authUser } = useSelector<AppState, AppState['auth']>(({ auth }) => auth);
  const classes = useStyles();
  const [isAddLayer, setAddLayer] = useState(false);
  const [editLayerId, setEditLayerId] = useState<null | string>(null);
  const [lessonName, setLessonName] = useState('');
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname } = useLocation();
  const [shareAnchorEl, setShareAnchorEl] = React.useState<null | HTMLElement>(null);
  const openSharePopover = Boolean(shareAnchorEl);

  useEffect(() => {
    currentSpaceId &&
      !currentLesson &&
      dispatch(onGetLessonsList(currentSpaceId));

  }, [history]);

  useEffect(() => {
    const listener = (event: any) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        if (editLayerId) handleEditLessonName(editLayerId);
        else onClickAddButton();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  });

  const onClickAddButton = async () => {
    if (lessonName !== '' && currentSpaceId) {
      const newLayerRef = firestore
        .collection(`Spaces/${currentSpaceId}/lessons`)
        .doc();

      let dvs: any[] = [];
      store.getState().home.spaceVariables.map(sv => {
        dvs.push({ ...sv, value: '', allowControl: false })
      });

      newLayerRef.set({
        id: newLayerRef.id,
        did: makeid(8),
        name: lessonName,
        createdOn: new Date(),
        createdBy: authUser?.uid,
        defaultVars: dvs
      });
      dispatch(onGetLessonsList(currentSpaceId));
      setAddLayer(false);
      setLessonName('');
    }
  };

  const handleDeleteLesson = async (lesson: ISceneData) => {
    if (!currentSpaceId) return;
    let res = window.confirm(`Are you sure you want to delete this ${currentSpaceUseCase?.layerTermSingular}: ${lesson?.name}?`);
    if (res) {

      const newLayerRef: any = await firestore.doc(
        `Spaces/${currentSpaceId}/lessons/${lesson.id}`,
      );

      newLayerRef
        .delete()
        .then(() => {
          dispatch(onGetLessonsList(currentSpaceId));
        })
        .catch(console.error);
    }
  };

  const handleEditLessonName = async (lessonId: string | null) => {
    if (!currentSpaceId) return;
    const newLayerRef = await firestore.doc(
      `Spaces/${currentSpaceId}/lessons/${lessonId}`,
    );

    newLayerRef
      .update({ name: lessonName })
      .then(() => {
        dispatch(onGetLessonsList(currentSpaceId));
        setAddLayer(false);
        setEditLayerId(null);
        setLessonName('');
      })
      .catch(console.error);
  };

  const handleEditLessonPublicFlag = async (lessonId: string | null, makePublic: boolean, callback: () => void) => {
    if (!currentSpaceId || !lessonId) return;

    console.log(`[st] [lessons] setting lesson isPublic to ${makePublic}`);

    firestore.doc(
      `Spaces/${currentSpaceId}/lessons/${lessonId}`,
    ).update({ isPublic: makePublic })
      .then(() => {
        dispatch(onGetLessonsList(currentSpaceId));
        setAddLayer(false);
        setEditLayerId(null);
        setLessonName('');
      })
      .catch(console.error)
      .then(() => callback())
  };

  function SharePopover() {
    return (console.log(`[st] [lessons] editlayerid is ${editLayerId}- public is: ${!!lessonsList.find(l => l.id == editLayerId)?.isPublic}`), <Popover
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      open={openSharePopover}
      anchorEl={shareAnchorEl}
      onClose={() => setShareAnchorEl(null)}
      style={{ padding: '8px' }}
    >

      <FormControlLabel control={<Checkbox checked={!!lessonsList.find(l => l.id == editLayerId)?.isPublic}
        onChange={() => {
          handleEditLessonPublicFlag(editLayerId, !lessonsList.find(l => l.id == editLayerId)?.isPublic, () => {
            setTimeout(() => setShareAnchorEl(null), 500)
          });

        }
        }
      />}
        label="Share publicly?" />

    </Popover>)
  }
  return (console.log(`%c[st] rendering lessonsidebar`, 'color: orange;'),

    currentLesson ?
      <Layout
        width={presentationMode ? '0' : ''}
      >
        <>
          {<StepsBar setMinimized={setMinimized} isMinimized={isMinimized} />}
          <TagGroups onDuplicateTagGroups={(tg: TagGroup) => {
            onAddTagGroup(tg);
          }} />
        </>
      </Layout>
      :

      <>
        <Layout scrollBarClass={classes.lessonScrollbar}>
          <SharePopover />
          {/* <Popover
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={openSharePopover}
          >

            <FormControlLabel control={<Checkbox onChange={() => handleEditLessonPublicFlag(currentLesson?.id, !!currentLesson?.isPublic)} />}
              label="Share publicly?" />

          </Popover> */}
          {isMinimized ? <ItemHover> <Button onClick={() => setMinimized(false)}><KeyboardArrowLeftRoundedIcon /></Button></ItemHover> : null}
          <div className={'sidebar ' + classes.lessonBox}>

            <Box
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
              }}>
              {canEditSpace() && (
                <Box>
                  <Box
                    display='flex'
                    alignItems='center'
                    justifyContent='center'
                    style={{ marginBottom: 15 }}>
                    <Tooltip title={`Create new ${currentSpaceUseCase?.layerTermSingular}`} >
                      <Fab size="small" color="secondary" onClick={() => {
                        setAddLayer(true);
                        setEditLayerId(null);
                        setLessonName('');
                      }}>
                        <AddIcon />

                      </Fab>
                    </Tooltip>
                    <Box display='flex'>
                      {isAddLayer ? (
                        <Box px={3} textAlign='right'>
                          <CloseIcon
                            className={classes.pointer}
                            onClick={async () => {
                              setAddLayer(false);
                            }}
                          />
                        </Box>
                      ) : null}
                    </Box>
                  </Box>
                  {isAddLayer ? (
                    <Box
                      display='flex'
                      flexDirection='column'
                      alignItems='center'
                      justifyContent='center'>
                      <TextField
                        fullWidth
                        style={{ width: "100%", fontSize: "12px" }}
                        margin='normal'
                        label={`Name`}
                        value={lessonName}
                        onChange={(event) => setLessonName(event.target.value)}
                      />
                      <Button
                        variant='contained'
                        color='secondary'
                        size='small'
                        type='submit'
                        style={{
                          marginTop: 10,
                          marginBottom: 20,
                          alignSelf: 'center',
                        }}
                        onClick={() => {
                          if (editLayerId) handleEditLessonName(editLayerId);
                          else onClickAddButton();
                        }}>
                        <IntlMessages
                          id={editLayerId ? 'common.save' : 'common.add'}
                        />
                      </Button>
                    </Box>
                  ) : null}
                </Box>
              )}
            </Box>

            <List style={{ padding: 3 }}>

              {lessonsList
                .filter((lesson) => lesson.name)
                .map((lesson: Lesson, index: number) => {

                  // console.log(`[vars] lesson: ${lesson?.createdOn}`);
                  return (<ListItem key={lesson.id + index}>

                    <Card
                      // raised={true}
                      style={{ minWidth: "100%", borderRadius: '16px' }}
                    >
                      <CardContent>
                        <CardActionArea onClick={() =>
                          history.push({ pathname: `/home/space/${currentSpaceDid}/project/${currentSpaceProjectDid}/layers/${lesson.did}`, search: '?mode=studio' })}
                        // goToLesson(currentSpaceDid || '', currentSpace?.currentSpaceProject.did || '', lesson.did, false, (path: string) => history.push(path))}
                        >

                          <Typography gutterBottom variant='h6' className={'h7Left'}>
                            {lesson.name}
                          </Typography>
                          {/* <Typography variant='body2' color='text.secondary'>
                                stepsInfo
                              </Typography> */}
                          <Typography variant='caption' style={{ overflow: 'wrap', color: fade('rgb(0,0,0)', 0.7) }}
                          // color='secondary'
                          >
                            Created on {((lesson as any).createdOn || (lesson as any).createdAt)?.toLocaleDateString('en-us', { year: "numeric", month: "short", day: "numeric" })}
                            {/* {//FIXME}} */}
                          </Typography>
                        </CardActionArea>
                      </CardContent>

                      <CardActions>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: "100%" }}
                        >
                          {/* <Fab size="small"> */}
                          <Tooltip title="Start Presentation">
                            <IconButton size="small" color="secondary" onClick={() => {
                              history.push({ pathname: `/home/space/${currentSpaceDid}/project/${currentSpaceProjectDid}/layers/${lesson.did}`, search: "?mode=present" });
                              // dispatch({ type: SET_PRESENTATION_MODE, payload: true });
                            }}>
                              <PlayArrowRoundedIcon fontSize='large' />
                            </IconButton>
                          </Tooltip>

                          {canEditSpace() && <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center' }}
                          >
                            <Tooltip title="Rename Lesson">
                              <IconButton
                                size='medium'
                                // className={classes.editIcon}
                                onClick={() => {
                                  setAddLayer(true);
                                  setEditLayerId(lesson.id);
                                  setLessonName(lesson.name);
                                }}>
                                <EditIcon fontSize='small' />
                              </IconButton>
                            </Tooltip>

                            <Tooltip title="Delete Lesson">
                              <IconButton size='medium'
                                // className={classes.deleteIcon}
                                onClick={() => {
                                  handleDeleteLesson(lesson);
                                }}>
                                <DeleteIcon fontSize='small' />
                              </IconButton>
                            </Tooltip>

                            <Tooltip title={"Share " + currentSpaceUseCase?.layerTermSingular}>
                              <IconButton size='medium'
                                // className={classes.deleteIcon}
                                onClick={(event: any) => {
                                  let path = `https://app.spatialthink.com/${pathname}/layers/${lesson.did}`;
                                  path = path.replace('layers/layers/', 'layers/');
                                  navigator.clipboard.writeText(path);
                                  setEditLayerId(lesson.id);
                                  setShareAnchorEl(event.target);
                                  dispatch(showMessage(`${currentSpaceUseCase?.layerTermSingular} share link copied to your clipboard!`));

                                }}>
                                <ShareRoundedIcon fontSize='small' />
                              </IconButton>
                            </Tooltip>

                            <Spacer x={4} />
                          </div>}
                        </div>
                      </CardActions>
                    </Card>

                  </ListItem>

                  );
                })}
            </List>
          </div>
        </Layout>
      </>
  );
}
