import { BasicProfiler, tryCatch } from "@crema/utility/Utils";
import { store } from "App";
import _ from "lodash";
import { getCurrentSweep } from "modules/home/SpaceDetail/SpaceView/Sidebar/TagSidebar/Tags/ShowcaseUtils";
import { setSpaceModelsList } from "redux/actions/Home";
import { showGizmo } from "redux/actions/ThreeD";
import * as THREE from "three";
import { SELECT_NODE } from "types/actions/ThreeD.actions";
import { Logic } from "types/models/dataAccess/Logic";
import { getNodePropsFromNode, ShowcaseTag, SpaceNode } from "types/models/home/HomeApp";
import { firestore } from "../../../../../@crema/services/auth/firebase/firebase";
import { GizmoTools } from "../../../../../modules/home/SpaceDetail/SpaceView/ShowcaseOverlay/3DTools/GizmoTools";
import Utils from "../../Tools/Utils";
import { SimulationMode } from "../core/RenderingAndPlaceObjectStateSystem";
import Simulation from "../core/Simulation";
import { ISceneNode } from "../sceneManagement/SceneComponent";
import { UserDataProperties, UserDataTypes } from "../ui-interop/PropertiesPanel";
import Serialization from "./Serialization";
import { WireTerminal } from "../../components/Wire/WireTerminal";
import { WireComponent } from "../../components/Wire/WireComponentOld";
import { GenericEventCallback } from '../../../../../CustomSdk/Systems/InputSystem';
import { ISceneNodeExtensions } from '../../extensions/ISceneNodeExtensions';
import { FrameUpdateCallback } from '../../../../../CustomSdk/Systems/WindowingSystem';

export default class NodeStorage {

    // public static async storeLogicForStep(logic:Logic):Promise<boolean> {
    //     let currentLessonId = store.getState().layer.currentLesson?.id;
    //     let currentTagGroupId = store.getState().layer.currentTagGroupId;
    //     //console.log(currentLessonId);
    //     let docRef = await firestore.doc(`Spaces/${Simulation.instance.spaceID}/lessons/${currentLessonId}/tagGroups/${currentTagGroupId}`);

    //     if(!docRef) {
    //         return false;
    //     }
    //     //console.log(await (await docRef.get()).data())
    //     //console.log(docRef)
    //     await docRef.update( { "logic" : JSON.parse(JSON.stringify(logic)}));
    //     return true;
    // }

    public static async storeNode(node: ISceneNode, wasJustPlaced?: boolean, NoPreSaveOps?: boolean): Promise<boolean> {
        try {
            if (node == null || !!store.getState().layer.presentationMode) {
                return false;
            }

            store.dispatch({ type: SELECT_NODE, payload: getNodePropsFromNode(node) });

            let docRef: any;
            let model;

            //TODO if this slows things down, we can only get floor at create time, not update
            let floorData = await Simulation.instance.renderingSubSystem.sdk().Floor.getData().catch(console.error);
            let currentFloor = floorData ? floorData.currentFloor : '';
            if (!node.userData["id"]) { // First Save

                docRef = firestore.collection(`Spaces/${Simulation.instance.spaceID}/nodes`).doc();
                let id = docRef.id;
                node.userData["id"] = id;
                node.userData.floorIndex = currentFloor || node.userData?.floorIndex;

                node.userData.pose = await getCurrentSweep();

                model = Serialization.SerializeNode(node, docRef.id);
                model.createdOn = new Date();
                model.createdBy = Simulation.instance.currentUser.uid;
                model.lastUpdatedOn = model.createdOn;
                model.lastUpadatedBy = model.createdBy;

            } else { // Update Node
                let spaceModel = store.getState().home.spaceModels.get(node.userData.id) ||{};

                docRef = firestore.doc(`Spaces/${Simulation.instance.spaceID}/nodes/${node.userData["id"]}`);
                node.userData.floorIndex = currentFloor || node.userData?.floorIndex;

                if ([undefined, null, false].includes(NoPreSaveOps))
                    NodeStorage.preSaveOps(node, wasJustPlaced);

                // if(!node.userData.pose){
                node.userData.pose = await getCurrentSweep();
                // }

                model = Serialization.SerializeNode(node, docRef.id);
                model.createdOn = spaceModel.createdOn;
                model.createdBy = spaceModel.createdBy;
                model.lastUpdatedOn = new Date();
                model.lastUpadatedBy = store.getState().auth.authUser?.uid;

            }

            // console.log(model);
            await docRef.set(model, { merge: true });

            (model as any)[UserDataProperties.nameToShow] = node.userData[UserDataProperties.nameToShow];
            (model as any).nodeRef = node;
            Simulation.instance.spaceModels().set(model.id, model); //will be used to update redux store
            // GizmoTools?.instance?.props.updateNodes();
            store.dispatch(setSpaceModelsList(Simulation.instance.spaceModels()));

            return true;
        } catch (e: any) {
            console.error(`[st] storeNode failed with: ${e}`)
        }

        return false;
    }

    public static async deleteNode() {

        let nodeToDelete = Simulation.instance.lastSelectedNode;
        let nodeId = nodeToDelete?.userData["id"];
        let nodeUserData = Object.assign({}, nodeToDelete?.userData);
        this.deleteNodeById(nodeId);
        tryCatch(() => {
            if (nodeUserData[UserDataProperties.markers]) {
                (nodeUserData[UserDataProperties.markers] as any[]).forEach((marker: any) => {
                    this.deleteNodeById(marker.id);
                });
            }
        }, 'deleting markers')
    }

    public static async deleteNodeById(nodeId: string) {
        try {
            let dr = firestore.doc(`Spaces/${Simulation.instance.spaceID}/nodes/${nodeId}`);
            let o = await dr.get();

            await o.ref.delete();

            if (Simulation.instance.getSimulationMode() == SimulationMode.ADD_OBJECT || Simulation.instance.getSimulationMode() == SimulationMode.ADD_INTERNAL_OBJECT) {
                setTimeout(Simulation.instance.resumeMatterPortCameraMovement.bind(this), 1000);
            }

            if (!Simulation.instance.spaceModels() || Simulation.instance.spaceModels().size == 0) {
                return;

            }
            if (Simulation.instance.spaceModels().get(nodeId)) {
                if (Simulation.instance.lastSelectedNode === Simulation.instance.spaceModels().get(nodeId).nodeRef) {
                    Simulation.instance.sceneLoader.hideTransformGizmo();
                    store.getState().threeD.showGizmo && store.dispatch(showGizmo(false));
                    // Simulation.instance.propertiesPanel.hidePropertiesPanel();
                    Simulation.instance.lastSelectedNode = null;
                }
            }

            if (Simulation.instance.spaceModels().get(nodeId).nodeRef) {
                Simulation.instance.spaceModels().get(nodeId).nodeRef.stop();
                Simulation.instance.spaceModels().get(nodeId).nodeRef = null;
            }
            Simulation.instance.spaceModels().delete(nodeId);
            Simulation.instance.sceneLoader.removeNodeByID(nodeId);

            // let sm = _.cloneDeep(Simulation.instance.spaceModels());
            // sm.delete(nodeId);
            // Simulation.instance.spaceModels() = sm;

            store.dispatch(setSpaceModelsList(Simulation.instance.spaceModels()));
            //TODO this is buggy. spaceModels object has already been changed, dispatch will have no effect.
            //Deleting from checkboxes in InsertedObjects with the above commented code was causing threejs to hang.
            //DEBUG to see why that is. see what the impact is, and figure out a low cost way to update
        } catch (e: any) {
            console.error(`[st] deleteNode failed with: ${e}`)
        }
    }

    public static async loadNodesFromDB(callback: (node: ISceneNode, dbJSON: any) => void): Promise<Map<string, any>> {
        try {

            // this.d.log("LOADING nodes... " + `Spaces/${this.spaceID}/nodes`)
            firestore.collection(`Spaces/${Simulation.instance.spaceID}/nodes`)
                .withConverter(SpaceNode.nodeConverter)
            // .onSnapshot(async (qs) => {
            // let nodesFromDBDocs = qs


            let nodesFromDBDocs = (await firestore.collection(`Spaces/${Simulation.instance.spaceID}/nodes`)
                .withConverter(SpaceNode.nodeConverter)
                .get())
                .docs.map((doc: any) => Serialization.DeserializeNode(doc)).sort((a, b) => b.lastUpdatedOn - a.lastUpdatedOn);

            nodesFromDBDocs = nodesFromDBDocs.filter(n => n.userData.name !== 'Quiz')
            // let tagsFromDBDocs = (await firestore.collection(`Spaces/${Simulation.instance.spaceID}/tags`)
            //     // .withConverter(SpaceNode.nodeConverter)
            //     .get()).docs.map(doc => doc.data() as ShowcaseTag);x

            // tagsFromDBDocs.forEach(tag => {
            //     if (tag.quizDetails) {
            //         let quizNode = {
            //             annotationType: '3dObject',
            //             name: 'Quiz',
            //             position: tag.data.anchorPosition,
            //             scale: '{ "x": 1, "y": 1, "z": 1 }',
            //             quaternion: '{ "_x": 0, "_y": 0, "_z": 0, "_w": 1 }',
            //             userData: { 'id': tag.id },
            //         } as SpaceNode;
            //         console.log('quiz node added', quizNode);
            //         // nodesFromDBDocs.push(quizNode);
            //     }
            // })

            // nodesFromDBDocs.map(node => {
            //     if (!node.createdOn) {
            //         console.error(`[error] [3D] createdOn not found for node
            //             ${store.getState().home.currentSpace?.name} - ${store.getState().home.currentSpace?.id} - ${node.userData.nameToShow} - ${node.id} `)
            //     }
            //     if (!node.lastUpdatedOn) {
            //         console.error(`[error] [3D] lastUpdatedOn not found for node
            //             ${store.getState().home.currentSpace?.name} - ${store.getState().home.currentSpace?.id} - ${node.userData.nameToShow} - ${node.id} `)
            //     }
            // })

            //Added to quickly load a lesson when opened directly from space thumbnail
            // if(!!store.getState().layer.currentLesson){
            //     let lesson = store.getState().layer.currentLesson;
            //     let lessonQs = await firestore.collection(`Spaces/${Simulation.instance.spaceID}/lessons`)
            //     .where('did', '==', lesson?.did)
            //     .get();

            //     if (!lessonQs.empty){
            //         let lessonDoc = lessonQs.docs[0];
            //         let firstStepQs = await firestore.collection(`Spaces/${Simulation.instance.spaceID}/lessons/${lessonDoc?.id}/tagGroups`)
            //         .where('sortIndex', '==', 0).limit(1)
            //         .get();

            //         // if (!firstStepQs.empty) {
            //             let firstStepNodesAndTagsIds = firstStepQs?.docs[0].data().tagIds || [];
            //             let alwaysShowNodeIds = nodesFromDBDocs.filter(n => n.userData?.alwaysShow).map(n => n.id);
            //             nodesFromDBDocs = nodesFromDBDocs.filter((n: any) => firstStepNodesAndTagsIds.includes(n.id) || alwaysShowNodeIds.includes(n.id));
            //         // }

            //     }

            // }

            let dbNodesArray = nodesFromDBDocs;
            let spaceModelsMap = new Map<string, any>();
            for (let i = 0; i < dbNodesArray.length; i++) {

                let model = dbNodesArray[i];
                spaceModelsMap.set(dbNodesArray[i].id, model);

                // DebugLogger.BigLine();
                // if (!excludeIds.includes(dbNodesArray[i].userData.id)) {
                await Simulation.instance.sceneLoader.loadClassical(model.name, callback, model);

                model.nodeRef = Simulation.instance.sceneLoader.getLastNodeAdded();
                // }
            }
            return spaceModelsMap;
            // });
        } catch (e: any) {
            console.error(`[st] failed to load nodes in Simulation : ${e}`)
        }
        //TODO: Show an error message
        return new Map<string, any>();
    }

    public static async duplicateNode() {
        try {
            let node = Simulation.instance.lastSelectedNode;
            if (node == null) {
                return;
            }


            let docRef = firestore.collection(`Spaces/${Simulation.instance.spaceID}/nodes`).doc();
            //node.userData["id"] = docRef.id;
            let model = Serialization.SerializeNode(node, docRef.id);
            model.userData['id'] = docRef.id;
            model.userData[UserDataProperties.nameToShow] = Simulation.instance.sceneLoader.generateNameFromCount(node);//model.userData[UserDataProperties.nameToShow] + " copy";
            await docRef.set(model);


            //let newNode = Object.assign({}, node);
            //newNode.userData["id"] = docRef.id;

            let nodeStarted = await NodeStorage.loadNode(model.name, Simulation.instance.scenePreProcess.bind(Simulation.instance), model);
            node = Simulation.instance.sceneLoader.getLastNodeAdded();
            let tempNodePosition = node.position;
            // tempNodePosition.x += 0.01;
            // tempNodePosition.y += 0.05;
            // tempNodePosition.z += 0.01;
            node.position.x = tempNodePosition.x;
            node.position.y = tempNodePosition.y;
            node.position.z = tempNodePosition.z;
            (model as any)[UserDataProperties.nameToShow] = node.userData[UserDataProperties.nameToShow];
            (model as any).nodeRef = node;
            Simulation.instance.spaceModels().set(model.id, model);
            store.dispatch(setSpaceModelsList(Simulation.instance.spaceModels()));

            //Simulation.instance.selectNode(node);
            await Simulation.instance.selectNodeWithPropertiesUpdate(node);

            console.log("Node duplicated!");
        } catch (e: any) {
            console.error(`[st] duplicateNode failed with: ${e}`)
        }
    }

    public static async loadNode(objectOrObjectName: string,
        callback: (node: ISceneNode, dbJSON: any) => void = Simulation.instance.scenePreProcess.bind(Simulation.instance),
        dbJSON?: any): Promise<boolean> {
        try {
            return await Simulation.instance.sceneLoader.loadClassical(objectOrObjectName, callback, dbJSON);
        } catch (e: any) {
            console.log(`[st] scene wasn't yet loaded: ${e}`)
        }

        return false;
    }

    // public static async addDefaultInternalObjects(parentNode: ISceneNode) {

    //     if (parentNode.name === 'Wire' && !parentNode.userData.markers) {
    //         Simulation.instance.srcObjectIdForInternalObjects = parentNode.userData.id;
    //         //startTerminal
    //         // await NodeStorage.loadNode('FlowMarker', undefined, null);
    //         await NodeStorage.loadNode('WireTerminal', undefined, null);
    //     }
    // }

    public static async preSaveOps(node: ISceneNode, wasJustPlaced?: boolean): Promise<void> {
        //     wasJustPlaced && NodeStorage.addDefaultInternalObjects(node);
        //     let bp = new BasicProfiler();
        //     if(node.name == 'Wire') {
        //         if (node.userData.markers){
        //             let terminalPositions = (node.components[0] as any).getStartAndEndPoints();

        //             bp.lap();
        //             if (node.userData.markers.length < 1)
        //                 return;
        //             for (let index=0; index < node.userData.markers.length; index++){
        //                 let markerNode = Simulation.instance.sceneLoader.findNodeByID(node.userData.markers[index]);
        //                 bp.lap("FindNodeByID");
        //                 let iter = index === 0 ? index: index + 1;
        //                 if (markerNode){
        //                     markerNode.position.x = node.position.x + terminalPositions[iter].x;
        //                     markerNode.position.y = node.position.y + terminalPositions[iter].y;
        //                     markerNode.position.z = node.position.z + terminalPositions[iter].z;
        //                     bp.lap("Component Updated");
        //                     await NodeStorage.storeNode(markerNode, undefined, true);
        //                     bp.lap("Component marker saved");
        //                 }
        //             }
        //         }

        //     } else if (node.name == 'WireTerminal') {
        //         let parentNode = store.getState().home.spaceModels.get(node.userData.srcObjectId)?.nodeRef;
        //         // let srcNode = Simulation.instance.sceneLoader.findNodeByID(Simulation.instance.srcObjectIdForInternalObjects);
        //         if (parentNode && parentNode.userData.type === "wire"){
        //             const positionUpdate = new THREE.Vector3();
        //             positionUpdate.subVectors(node.position, parentNode.position);
        //             parentNode.components[0].updateCurveOutline(positionUpdate, node.userData.nameToShow.split(" ")[0], parseInt(node.userData.wireWidth || 0));
        //             let terminalPositions = (parentNode.components[0] as WireComponent).getStartAndEndPoints();
        //             (node.components[0] as WireTerminal).alignTerminalWithWire(node.position, parentNode.position);
        //             console.log("Here is the terminal position: ", node.position, "Here is the wire position", parentNode.position);
        //             await NodeStorage.storeNode(parentNode as ISceneNode, undefined, true);
        //         }
        //     }
    }
}

