import TextFormatRoundedIcon from '@mui/icons-material/TextFormatRounded';

import VideoCameraFrontRoundedIcon from '@mui/icons-material/VideoCameraFrontRounded';
import {
    CustomLeverIcon,
    CustomPowerOnIcon,
    CustomGaugeIcon,
    BoxHighlightIcon,
    CustomArrowIcon,
    BorderHighlightIcon,
    CustomSwitchIcon,
    CustomDialIcon,
    WireIcon,
    MultimeterIcon,
} from '@crema/core/Icons/CustomIcons';
import LocalFireDepartmentRoundedIcon from '@mui/icons-material/LocalFireDepartmentRounded';
import WaterfallChartRoundedIcon from '@mui/icons-material/WaterfallChartRounded';
import ImageRoundedIcon from '@mui/icons-material/ImageRounded';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
import FastForwardIcon from '@mui/icons-material/FastForward';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import * as Icons from '@mui/icons-material/';
import { AnalogGaugeComponent } from 'mp/core/craEngine/components/AnalogGaugeComponent';

export const modelMap = new Map();

/**
 * If adding components that should respond to drag and can be added to Conditions:
 * component must have a name
 *
 *
 */

// export const OffButtonModel = {
//     "version": "1.0",
//     "icon": "https://storage.googleapis.com/virtual-tc.appspot.com/3d/gltf/Off Black/icon_off_Black.jpg",
//     "iconMui": CustomPowerOffIcon,
//     "payload": {
//         "objects": [{
//             "name": "Power Off",
//             "position": { "x": 0, "y": 0, "z": 0 },
//             "rotation": { "x": 0, "y": 0, "z": 0 },
//             "scale": { "x": 1, "y": 1, "z": 1 },
//             "userData": {
//                 "hasPropertiesPanel": true,
//                 "alwaysShow": false,
//                 "initialPlacementStyle": "thermostat",
//                 "noClickVarChange": true
//             },
//             "components": [
//                 {
//                     "type": "mp.gltfLoader",
//                     "inputs": {
//                         "url": "https://storage.googleapis.com/virtual-tc.appspot.com/3d/gltf/Off Black/Off_Black.gltf",
//                         "localScale": { "x": 1, "y": 1, "z": 1 },
//                         "localRotation": { "x": -90, "y": 0, "z": 180 },
//                         "localPosition": { "x": 0, "y": 0, "z": 0 }
//                     }
//                 },
//             ],

//         }]
//     },

// }

export const OnButtonModel = {
    'version': '1.0',
    'icon': 'https://storage.googleapis.com/virtual-tc.appspot.com/3d/gltf/On Black/icon_on_Black.jpg',
    iconMui: CustomPowerOnIcon,
    'payload': {
        'objects': [{
            'name': 'Power On',
            'position': { 'x': 0, 'y': 0, 'z': 0 },
            'rotation': { 'x': 0, 'y': 0, 'z': 0 },
            'scale': { 'x': 1, 'y': 1, 'z': 1 },
            'userData': {
                'hasPropertiesPanel': true,
                'alwaysShow': false,
                'initialPlacementStyle': 'thermostat',
            },
            'components': [
                {
                    'type': 'mp.gltfLoader',
                    'inputs': {
                        'url': 'https://storage.googleapis.com/virtual-tc.appspot.com/3d/gltf/On Black/On_Black.gltf',
                        'localScale': { 'x': 1, 'y': 1, 'z': 1 },
                        'localRotation': { 'x': -90, 'y': 0, 'z': 180 },
                        'localPosition': { 'x': 0, 'y': 0, 'z': 0 },
                    },
                },
            ],

        }],
    },
};

export const OnOffButtonModel = {
    'version': '1.0',
    'icon': 'https://image.flaticon.com/icons/png/512/2885/2885976.png',
    'iconMui': CustomSwitchIcon,
    'payload': {
        'objects': [{
            'name': 'On Off Button',
            'position': {
                'x': 0,
                'y': 0,
                'z': 0,
            },
            'rotation': {
                'x': 0,
                'y': 0,
                'z': 0,
            },
            'scale': {
                'x': 1,
                'y': 1,
                'z': 1,
            },
            'userData': {
                'type': 'onOffButton',
                'hasPropertiesPanel': true,
                'initialPlacementStyle': 'offsetFromNormal',
                'distanceForNormalOffset': 0.5,
                'alwaysShow': false,
                'buttons': { 'scalePatch': 'Patch Size' },
                'disableScale': false,
                'overrideUserData': {
                    'disableScale': false,
                },
                'events': ['CLICK', 'HOVER'],
            },
            'components': [
                {
                    'type': 'mp.onOffButton',
                    'inputs': {
                        'rotationAxis': {
                            'x': 0,
                            'y': 0,
                            'z': 1,
                        },
                    },
                    'events': {
                        'INTERACTION.CLICK': true,
                        'INTERACTION.HOVER': true,
                        'INTERACTION.DRAG': true,
                        'INTERACTION.DRAG_END': true,
                        'INTERACTION.DRAG_BEGIN': true,
                    },
                },
            ],

        }],
    },
};

export const ArrowModel = {
    'version': '1.0',
    'icon': 'https://img.flaticon.com/icons/png/512/52/52692.png?size=1200x630f&pad=10,10,10,10&ext=png&bg=FFFFFFFF',
    iconMui: CustomArrowIcon,
    'payload': {
        'objects': [{
            'name': 'Arrow',
            'position': { 'x': 0, 'y': 0, 'z': 0 },
            'rotation': { 'x': 0, 'y': 0, 'z': 0 },
            'scale': { 'x': 0.25, 'y': 0.5, 'z': 0.25 },
            'userData': {
                'type': 'arrow',
                'hasPropertiesPanel': true,
                'hasColorProperty': true,
                'initialPlacementStyle': 'arrow',
                'alwaysShow': false,
                'events': ['CLICK', 'DRAG', 'HOVER'],
            },
            'components': [
                {
                    'type': 'mp.gltfLoader',
                    'inputs': {
                        'url': 'https://storage.googleapis.com/virtual-tc.appspot.com/3d/gltf/Arrow%20Large/Arrow_New.gltf',
                        'localScale': { 'x': 2.5, 'y': 2.5, 'z': 3.75 },
                        'localRotation': { 'x': 0, 'y': 0, 'z': 0 },
                        'localPosition': { 'x': 0, 'y': 0, 'z': 0 },
                    },
                    'events': {
                        'INTERACTION.CLICK': true,
                        'INTERACTION.HOVER': true,
                        'INTERACTION.DRAG': true,
                        'INTERACTION.DRAG_END': true,
                        'INTERACTION.DRAG_BEGIN': true,
                    },
                },
                {
                    'type': 'mp.objLoader',
                    'inputs': {
                        'url': 'https://storage.googleapis.com/virtual-tc.appspot.com/3d/obj/Loading Icon/icon.obj',
                        'localScale': { 'x': 0.01, 'y': 0.01, 'z': 0.01 },
                        'localRotation': { 'x': 0, 'y': 0, 'z': 0 },
                        'localPosition': { 'x': 0, 'y': 0, 'z': 0 },
                    },
                    'events': {
                        'INTERACTION.CLICK': false,
                        'INTERACTION.HOVER': false,
                        'INTERACTION.DRAG': false,
                    },
                },
                {
                    'type': 'mp.loadingIndicator',
                    'inputs': {
                        'size': { 'x': 1, 'y': 1, 'z': 1 },
                    },
                },
            ],
            'bindings': [
                [2, 'logo', 1, 'objectRoot'],
            ],
        }],
    },
};

export const BoxHighlightModel = {
    'version': '1.0',
    'icon': 'https://static.thenounproject.com/png/438176-200.png',
    iconMui: BoxHighlightIcon,
    'payload': {
        'objects': [{
            'name': 'Box Highlight',
            'position': { 'x': 0, 'y': 0, 'z': 0 },
            'rotation': { 'x': 0, 'y': 0, 'z': 0 },
            'scale': { 'x': 1, 'y': 1, 'z': 1 },
            'userData': {
                'type': 'boundexBox',
                'hasPropertiesPanel': true,
                'hasColorProperty': true,
                'hasBorderProperty': true,
                'initialPlacementStyle': 'offsetFromNormal',
                'distanceForNormalOffset': 0.251,
                'alwaysShow': false,
                'events': ['CLICK', 'DRAG', 'HOVER'],
            },
            'components': [
                {
                    'type': 'mp.highlightBox',
                    'events': {
                        'INTERACTION.CLICK': true,
                        'INTERACTION.HOVER': true,
                        'INTERACTION.DRAG': false,
                        'INTERACTION.DRAG_BEGIN': false,
                        'INTERACTION.DRAG_END': false,
                    },
                },
            ],
        }],
    },
};

export const BorderHighlightModel = {
    'version': '1.0',
    'icon': 'https://w7.pngwing.com/pngs/929/75/png-transparent-squared-green-frame-square-text-area-frame-pattern-simple-square-frame-border-frame-blue-thumbnail.png',
    iconMui: BorderHighlightIcon,
    'payload': {
        'objects': [{
            'name': 'Border Highlight',
            'position': { 'x': 0, 'y': 0, 'z': 0 },
            'rotation': { 'x': 0, 'y': 0, 'z': 0 },
            'scale': { 'x': 1, 'y': 1, 'z': 1 },
            'userData': {
                'type': 'highlightBorder',
                'hasPropertiesPanel': true,
                'hasColorProperty': true,
                'initialPlacementStyle': 'plane',
                'alwaysShow': false,
                'events': ['CLICK', 'DRAG', 'HOVER'],
            },
            'components': [
                {
                    'id': 0,
                    'type': 'mp.planeRenderer',
                    'inputs': {
                        'transparent': true,
                        'opacity': 0.5,
                    },
                    'events': {
                        'INTERACTION.CLICK': true,
                        'INTERACTION.HOVER': true,
                        'INTERACTION.DRAG': true,
                        'INTERACTION.DRAG_BEGIN': true,
                        'INTERACTION.DRAG_END': true,
                    },
                },
                {
                    'id': 1,
                    'type': 'mp.canvasRenderer',
                    'inputs': {
                        'textureRes': {
                            'w': 1024,
                            'h': 1024,
                        },
                    },
                    'events': {
                        'INTERACTION.CLICK': true,
                        'INTERACTION.HOVER': true,
                        'INTERACTION.DRAG': true,
                        'INTERACTION.DRAG_BEGIN': true,
                        'INTERACTION.DRAG_END': true,
                    },
                },
                {
                    'id': 2,
                    'type': 'mp.canvasBorder',
                    'inputs': {
                        'position': {
                            'x': 0,
                            'y': 0,
                        },
                        'size': {
                            'h': 1024,
                            'w': 1024,
                        },
                        'lineWidth': 100,
                        'radius': 50,
                        'color': 'white',
                    },
                    'events': {
                        'INTERACTION.CLICK': true,
                        'INTERACTION.HOVER': true,
                        'INTERACTION.DRAG': true,
                        'INTERACTION.DRAG_BEGIN': true,
                        'INTERACTION.DRAG_END': true,
                    },
                },
            ], 'bindings': [
                [1, 'painter', 2, 'painter'],
                [0, 'texture', 1, 'texture'],


            ],
            'events': [
                [1, 'repaint', 2, 'repaint'],
            ],
        }],
    },
};

export const IoTModel = {
    'version': '1.0',
    'icon': 'https://www.pinclipart.com/picdir/middle/85-854633_iot-icon-clipart.png',
    iconMui: CustomGaugeIcon,
    'payload': {
        'objects': [{
            'name': 'IoT',
            'position': { 'x': 0, 'y': 0, 'z': 0 },
            'rotation': { 'x': 0, 'y': 0, 'z': 0 },
            'scale': { 'x': 1, 'y': 1, 'z': 1 },
            'userData': {
                'type': 'thermostatNest',
                'hasPropertiesPanel': true,
                'initialPlacementStyle': 'thermostat',
                'hasColorProperty': true,
                'hasBorderProperty': true,
                'inputSource1': 'None',
                'alwaysShow': false,
                'diff1': '',
                'diff2': '',
                'noClickVarChange': true,
                'events': ['CLICK', 'HOVER'],

            },
            'components': [
                {
                    'id': 0,
                    'type': 'mp.daeLoader',
                    'inputs': {
                        'url': 'https://storage.googleapis.com/st-models/3d/nest.dae',
                        'localScale': { 'x': 0.04, 'y': 0.04, 'z': 0.04 },
                        'localRotation': { 'x': -90, 'y': 0, 'z': 180 },
                        'localPosition': { 'x': 0, 'y': 0, 'z': 0 },
                    },
                    'events': {
                        'INTERACTION.CLICK': true,
                        'INTERACTION.HOVER': true,
                        'INTERACTION.DRAG': true,
                        'INTERACTION.DRAG_BEGIN': true,
                        'INTERACTION.DRAG_END': true,
                    },
                },
                {
                    'id': 1,
                    'type': 'mp.planeRenderer',
                    'inputs': {
                        'localPosition': { 'x': 0, 'y': 0.15, 'z': 0.75 },
                        'localRotation': { 'x': -90, 'y': 0, 'z': 180 },
                    },
                    'events': {
                        'INTERACTION.CLICK': true,
                        'INTERACTION.HOVER': true,
                        'INTERACTION.DRAG': true,
                        'INTERACTION.DRAG_BEGIN': true,
                        'INTERACTION.DRAG_END': true,
                    },
                },
                {
                    'id': 2,
                    'type': 'mp.canvasRenderer',
                    'inputs': {
                        'textureRes': {
                            'w': 1024,
                            'h': 1024,
                        },
                    },
                    'events': {
                        'INTERACTION.CLICK': true,
                        'INTERACTION.HOVER': true,
                        'INTERACTION.DRAG': true,
                        'INTERACTION.DRAG_BEGIN': true,
                        'INTERACTION.DRAG_END': true,
                    },
                },
                {
                    'id': 3,
                    'type': 'mp.nestThermostat',
                    'events': {
                        'INTERACTION.CLICK': true,
                        'INTERACTION.HOVER': true,
                        'INTERACTION.DRAG': true,
                        'INTERACTION.DRAG_BEGIN': true,
                        'INTERACTION.DRAG_END': true,
                    },
                },
                {
                    'type': 'mp.objLoader',
                    'inputs': {
                        'url': 'https://storage.googleapis.com/virtual-tc.appspot.com/3d/obj/Loading Icon/icon.obj',
                        'localScale': { 'x': 0.005, 'y': 0.005, 'z': 0.005 },
                        'localRotation': { 'x': 0, 'y': 0, 'z': 0 },
                        'localPosition': { 'x': 0, 'y': 0, 'z': 0 },
                    },
                    'events': {
                        'INTERACTION.CLICK': true,
                        'INTERACTION.HOVER': true,
                        'INTERACTION.DRAG': true,
                        'INTERACTION.DRAG_BEGIN': true,
                        'INTERACTION.DRAG_END': true,
                    },
                },
                {
                    'type': 'mp.loadingIndicator',
                    'inputs': {
                        'size': { 'x': 1, 'y': 1, 'z': 1 },
                    },
                    'events': {
                        'INTERACTION.CLICK': true,
                        'INTERACTION.HOVER': true,
                        'INTERACTION.DRAG': true,
                        'INTERACTION.DRAG_BEGIN': true,
                        'INTERACTION.DRAG_END': true,
                    },
                },
            ],
            'bindings': [
                [3, 'loadingState', 0, 'loadingState'],
                [1, 'texture', 2, 'texture'],
                [2, 'painter', 3, 'painter'],
                [3, 'texture', 2, 'texture'],
                [1, 'visible', 3, 'visible'],
                [5, 'logo', 4, 'objectRoot'],
            ],
            'events': [
                [3, 'hover', 0, 'INTERACTION.HOVER'],
                [3, 'hover', 1, 'INTERACTION.HOVER'],
                [2, 'repaint', 3, 'repaint'],
            ],
        }],
    },
};

export const TextBoxModel = {
    'version': '1.0',
    'icon': 'https://cdn0.iconfinder.com/data/icons/thin-text-editing/24/thin-0256_input_box_text_edit-512.png',
    iconMui: TextFormatRoundedIcon,
    'payload': {
        'objects': [{
            'name': 'Text Box',
            'position': { 'x': 0, 'y': 0, 'z': 0 },
            'rotation': { 'x': 0, 'y': 0, 'z': 0 },
            'scale': { 'x': 1, 'y': 0.45, 'z': 1 },
            'userData': {
                'type': 'textPanel',
                'hasPropertiesPanel': true,
                'textProperty': '',
                'hasColorProperty': true,
                'hasBorderProperty': true,
                'initialPlacementStyle': 'plane',
                'fontSize': 50,
                'alwaysShow': false,
                'width': 1,
                'height': 1,
                'inputSource1': 'None',
                'events': ['CLICK', 'DRAG', 'HOVER'],
            },
            'components': [
                {
                    'id': 0,
                    'type': 'mp.planeRenderer',
                    'inputs': {
                        'localScale': { 'x': 1, 'y': 1, 'z': 1 },
                        'transparent': false,
                        'opacity': 1,
                    },
                    'events': {
                        'INTERACTION.CLICK': true,
                        'INTERACTION.HOVER': true,
                        'INTERACTION.DRAG': true,
                    },
                },
                {
                    'id': 1,
                    'type': 'mp.planeRenderer',
                    'inputs': {
                        'localScale': { 'x': 1, 'y': 1, 'z': 1 },
                        'transparent': true,
                        'opacity': 1,
                    },
                    'events': {
                        'INTERACTION.CLICK': true,
                        'INTERACTION.HOVER': true,
                        'INTERACTION.DRAG': true,
                    },
                },
                {
                    'id': 2,
                    'type': 'mp.canvasRenderer',
                    'inputs': {
                        'textureRes': {
                            'w': 1024,
                            'h': 1024,
                        },
                    },
                },
                {
                    'id': 3,
                    'type': 'mp.canvasBorder',
                    'inputs': {
                        'position': {
                            'x': 0,
                            'y': 0,
                        },
                        'size': {
                            'w': 1024,
                            'h': 1024,
                        },
                        'radius': 5,
                        'lineWidth': 20,
                    },
                },
                {
                    'id': 4,
                    'type': 'mp.planeRenderer',
                    'inputs': {
                        'invertScale': false,
                        'transparent': true,
                        'opacity': 1,
                        'polygonOffset': true,
                        'polygonOffsetFactor': -100,
                        'color': 'white',
                    },
                },
                {
                    'id': 5,
                    'type': 'mp.canvasRenderer',
                    'inputs': {
                        'textureRes': {
                            'w': 512,
                            'h': 512,
                        },
                    },
                },
                {
                    'id': 6,
                    'type': 'mp.canvasText',
                    'inputs': {
                        'size': {
                            'w': 1024,
                            'h': 1024,
                        },
                        'text': 'Sample text',
                        'fontStyle': 'normal bold',
                        'fontSize': 80,
                        'fontName': 'sans-serif',
                        'position': { 'x': 30, 'y': 0, 'z': 0 },
                        'textWidth': 840,
                    },
                },
                {
                    'id': 7,
                    'type': 'mp.toggleState',
                    'inputs': {
                        'initialState': false,
                    },
                },
            ], 'bindings': [
                [1, 'texture', 2, 'texture'],
                [2, 'painter', 3, 'painter'],
                [5, 'painter', 6, 'painter'],
                [4, 'texture', 5, 'texture'],
                [4, 'visible', 7, 'negated'],

            ],
            'events': [
                [7, 'toggle', 1, 'INTERACTION.CLICK'],
                [5, 'repaint', 6, 'textUpdate'],
            ],
        }],
    },
};

export const WebcamModel = {
    'version': '1.0',
    'icon': 'https://png.pngtree.com/png-vector/20191011/ourmid/pngtree-webcam-icon-png-image_1817559.jpg',
    'iconMui': VideoCameraFrontRoundedIcon,
    'payload': {
        'objects': [{
            'name': 'Webcam',
            'position': { 'x': -4.65, 'y': 1.5, 'z': -0.1 },
            'rotation': { 'x': 0, 'y': 0, 'z': 0 },
            'scale': { 'x': 1.2, 'y': 1.2, 'z': 1.2 },
            'userData': {
                'type': 'webcam',
                'hasPropertiesPanel': true,
                'hasColorProperty': false,
                'initialPlacementStyle': 'plane',
                'alwaysShow': false,
                'events': ['CLICK', 'DRAG', 'HOVER'],
            },
            'components': [

                {
                    'id': 0,
                    'type': 'mp.planeRenderer',
                    'inputs': {
                        'transparent': false,
                        'borderRadius': 50,
                    },
                },
                {
                    'id': 1,
                    'type': 'mp.videoRenderer',
                },
                {
                    'id': 2,
                    'type': 'mp.videoStreamCapture',
                    'inputs': {
                        'deviceId': 0,
                    },
                },
                {
                    'id': 3,
                    'type': 'mp.toggleState',
                    'inputs': {
                        'initialState': false,
                    },
                },

                {
                    'id': 4,
                    'type': 'mp.planeRenderer',
                    'inputs': {
                        'transparent': true,
                        'opacity': 0.8,
                        'borderRadius': 50,
                    },
                },
                {
                    'id': 5,
                    'type': 'mp.canvasRenderer',
                    'inputs': {
                        'textureRes': {
                            'w': 1024,
                            'h': 1024,
                        },
                    },
                },
                {
                    'id': 6,
                    'type': 'mp.canvasBorder',
                    'inputs': {
                        'position': {
                            'x': 0,
                            'y': 0,
                        },
                        'size': {
                            'h': 1024,
                            'w': 1024,
                        },
                        'radius': 50,
                    },
                },
                {
                    'id': 7,
                    'type': 'mp.planeRenderer',
                    'inputs': {
                        'aspect': 1.77777777778,
                        'transparent': true,
                        'opacity': 0.8,
                        'polygonOffset': true,
                        'polygonOffsetFactor': -2,
                    },
                },
                {
                    'id': 8,
                    'type': 'mp.canvasRenderer',
                    'inputs': {
                        'textureRes': {
                            'w': 256,
                            'h': 256,
                        },
                    },
                },
                {
                    'id': 9,
                    'type': 'mp.canvasText',
                    'inputs': {
                        'size': {
                            'h': 256,
                            'w': 256,
                        },
                        'text': 'Click to enable webcam.',
                        'fontStyle': 'normal bold',
                        'fontSize': 30,
                        'fontName': 'sans-serif',
                        'position': { 'x': 70, 'y': 70, 'z': 0 },
                        'textWidth': 200,
                        'fontColor': '#000000',
                    },
                },
            ],
            'bindings': [
                [1, 'src', 2, 'stream'],
                [0, 'texture', 1, 'texture'],
                [2, 'enabled', 3, 'state'],
                [0, 'aspect', 2, 'aspect'],
                [5, 'painter', 6, 'painter'],
                [4, 'texture', 5, 'texture'],
                [4, 'aspect', 2, 'aspect'],
                [8, 'painter', 9, 'painter'],
                [7, 'texture', 8, 'texture'],
                [7, 'visible', 3, 'negated'],
                [7, 'aspect', 2, 'aspect'],
            ],
            'events': [
                [3, 'toggle', 0, 'INTERACTION.CLICK'],
            ],
        }],
    },
};


export const LeverToggleModel = {
    'version': '1.0',
    'icon': 'https://static.thenounproject.com/png/1037776-200.png',
    'iconMui': CustomLeverIcon,
    'payload': {
        'objects': [{
            'name': 'Lever Toggle',
            'position': { 'x': 0, 'y': 0, 'z': 0 },
            'rotation': { 'x': 0, 'y': 0, 'z': 0 },
            'scale': { 'x': 3, 'y': 3, 'z': 3 },
            'userData': {
                'type': 'leverToggle',
                'nameToShow': 'Lever',
                'hasPropertiesPanel': true,
                'initialPlacementStyle': 'default',
                'distanceForNormalOffset': 0.01,
                'disableScale': true,
                'alwaysShow': false,
                'overrideUserData': {
                    'disableScale': true,
                },
                'localPosition': { 'x': 0, 'y': 0, 'z': 0 },
                'rotationAxis': { 'x': 0, 'y': 1, 'z': 0 },
                'rotationRange': '0,-90',
                'events': ['CLICK', 'DRAG', 'HOVER'],
            },
            'components': [
                {
                    'type': 'mp.daeLoader',
                    'inputs': {
                        'url': '/assets/3d/Lever/Lever.dae',
                        'localScale': { 'x': 0.01, 'y': 0.01, 'z': 0.01 },
                        'localRotation': { 'x': 0, 'y': 0, 'z': 0 },
                        'localPosition': { 'x': 0, 'y': 0, 'z': 0 },
                    },
                    'events': {
                        'INTERACTION.CLICK': true,
                        'INTERACTION.HOVER': true,
                        // 'INTERACTION.DRAG': true,
                        // 'INTERACTION.DRAG_END': true,
                        // 'INTERACTION.DRAG_BEGIN': true,
                    },
                },
                {
                    'type': 'mp.rotateToggle',
                    'inputs': {
                        'rotationAxis': {
                            'x': 0,
                            'y': 1,
                            'z': 0,
                        },
                        'rotationRange': [0, -90],
                        'transitionTime': 0.5,
                    },
                    'events': {
                        'INTERACTION.CLICK': true,
                        'INTERACTION.HOVER': true,
                        // 'INTERACTION.DRAG': true,
                        // 'INTERACTION.DRAG_END': true,
                        // 'INTERACTION.DRAG_BEGIN': true,
                    },
                },
                {
                    'type': 'mp.objLoader',
                    'inputs': {
                        'url': 'https://storage.googleapis.com/virtual-tc.appspot.com/3d/obj/Loading Icon/icon.obj',
                        'localScale': { 'x': 0.01, 'y': 0.01, 'z': 0.01 },
                        'localRotation': { 'x': 0, 'y': 0, 'z': 0 },
                        'localPosition': { 'x': 0, 'y': 0, 'z': 0 },
                    },
                    'events': {
                        'INTERACTION.CLICK': false,
                        'INTERACTION.HOVER': false,
                        // 'INTERACTION.DRAG': true,
                        // 'INTERACTION.DRAG_END': true,
                        // 'INTERACTION.DRAG_BEGIN': true,
                    },

                },
                {
                    'type': 'mp.loadingIndicator',
                    'inputs': {
                        'size': { 'x': 1, 'y': 1, 'z': 1 },
                    },
                },
            ],
            'bindings': [
                [3, 'logo', 2, 'objectRoot'],
                [1, 'targetMesh', 0, 'objectRoot'],
            ],
        }],
    },
};

export const ToggleMultiModelModel = {
    'version': '1.0',
    'icon': 'https://storage.googleapis.com/virtual-tc.appspot.com/3d/gltf/On Black/icon_on_Black.jpg',
    iconMui: CustomPowerOnIcon,
    'payload': {
        'objects': [{
            'name': 'On Off Switch',
            'position': { 'x': 0, 'y': 0, 'z': 0 },
            'rotation': { 'x': 0, 'y': 0, 'z': 0 },
            'scale': { 'x': 1, 'y': 1, 'z': 1 },
            'userData': {
                'hasPropertiesPanel': true,
                'alwaysShow': false,
                'initialPlacementStyle': 'thermostat',
                'nameToShow': 'On Off Button',
                'events': ['CLICK', 'HOVER'],
            },
            'components': [
                {
                    'type': 'mp.gltfLoader',
                    'inputs': {
                        'url': 'https://storage.googleapis.com/virtual-tc.appspot.com/3d/gltf/On Black/On_Black.gltf',
                        'localScale': { 'x': 1, 'y': 1, 'z': 1 },
                        'localRotation': { 'x': -90, 'y': 0, 'z': 180 },
                        'localPosition': { 'x': 0, 'y': 0, 'z': 0 },
                    },
                    'events': {
                        'INTERACTION.CLICK': true,
                        'INTERACTION.HOVER': true,
                        'INTERACTION.DRAG': false,
                        'INTERACTION.DRAG_END': false,
                        'INTERACTION.DRAG_BEGIN': false,
                    },
                },
                {
                    'type': 'mp.gltfLoader',
                    'inputs': {
                        'url': 'https://storage.googleapis.com/virtual-tc.appspot.com/3d/gltf/Off Black/Off_Black.gltf',
                        'localScale': { 'x': 1, 'y': 1, 'z': 1 },
                        'localRotation': { 'x': -90, 'y': 0, 'z': 180 },
                        'localPosition': { 'x': 0, 'y': 0, 'z': 0 },
                    },
                    'events': {
                        'INTERACTION.CLICK': true,
                        'INTERACTION.HOVER': true,
                        'INTERACTION.DRAG': false,
                        'INTERACTION.DRAG_END': false,
                        'INTERACTION.DRAG_BEGIN': false,
                    },
                },
                {
                    'type': 'mp.gltfLoader',
                    'inputs': {
                        'url': 'https://storage.googleapis.com/virtual-tc.appspot.com/3d/gltf/Off Black/Off_Black.gltf',
                        'localScale': { 'x': 1.1, 'y': 1.1, 'z': 1.1 },
                        'localRotation': { 'x': -90, 'y': 0, 'z': 180 },
                        'localPosition': { 'x': 0, 'y': 0, 'z': 0 },
                    },
                    'events': {
                        'INTERACTION.CLICK': true,
                        'INTERACTION.HOVER': true,
                        'INTERACTION.DRAG': false,
                        'INTERACTION.DRAG_END': false,
                        'INTERACTION.DRAG_BEGIN': false,
                    },
                },
                {
                    'type': 'mp.ToggleComponent',
                    'inputs': {},
                    'events': {
                        'INTERACTION.CLICK': true,
                        'INTERACTION.HOVER': true,
                        'INTERACTION.DRAG': false,
                        'INTERACTION.DRAG_END': false,
                        'INTERACTION.DRAG_BEGIN': false,
                    },
                },
            ],

        }],
    },
};

export const ThreeDMenuModel = {
    'version': '1.0',
    'icon': 'https://static.thenounproject.com/png/1037776-200.png',
    iconMui: MenuRoundedIcon,
    'payload': {
        'objects': [{
            'name': 'Menu',
            'position': { 'x': 0, 'y': 0, 'z': 0 },
            'rotation': { 'x': 0, 'y': 0, 'z': 0 },
            'scale': { 'x': 1, 'y': 1, 'z': 1 },
            'userData': {
                'hasPropertiesPanel': true,
                'alwaysShow': false,
                'initialPlacementStyle': 'plane',
                'type': 'textPanel',
                'events': ['CLICK', 'DRAG', 'HOVER'],
            },
            'components': [
                {
                    'id': 0,
                    'type': 'st.plateRenderer',
                    'inputs': {
                        'aspect': 1.77777777778,
                        'transparent': true,
                        'opacity': 0.8,
                        'polygonOffset': true,
                        'polygonOffsetFactor': 0,
                        'color': 0x5f69f6,
                        'borderRadius': 100,
                    },
                    'events': {
                        'INTERACTION.CLICK': true,
                        'INTERACTION.HOVER': false,
                        'INTERACTION.DRAG': false,
                        'INTERACTION.DRAG_END': false,
                        'INTERACTION.DRAG_BEGIN': false,
                    },
                },
                {
                    'id': 1,
                    'type': 'st.plateRenderer',
                    'inputs': {
                        'aspect': 1.77777777778,
                        'transparent': true,
                        'opacity': 1,
                        'polygonOffset': true,
                        'polygonOffsetFactor': -2,
                        'borderRadius': 100,
                        'invertScale': true,
                    },
                    'events': {
                        'INTERACTION.CLICK': true,
                        'INTERACTION.HOVER': false,
                        'INTERACTION.DRAG': false,
                        'INTERACTION.DRAG_END': false,
                        'INTERACTION.DRAG_BEGIN': false,
                    },
                },
                {
                    'id': 2,
                    'type': 'mp.canvasRenderer',
                    'inputs': {
                        'textureRes': {
                            'w': 1024,
                            'h': 1024,
                        },
                    },
                },
                {
                    'id': 3,
                    'type': 'mp.canvasBorder',
                    'inputs': {
                        'position': {
                            'x': 0,
                            'y': 0,
                        },
                        'size': {
                            'w': 1024,
                            'h': 1024,
                        },
                        'radius': 100,
                        'lineWidth': 20,
                        'color': 'white',
                    },
                },
                {
                    'id': 4,
                    'type': 'st.buttonTextRenderer',
                    'inputs': {
                        'transparent': true,
                        'opacity': 1,
                        'polygonOffset': true,
                        'polygonOffsetFactor': -4,
                        'borderRadius': 50,
                        'color': '#f5efef',
                        'hoverColor': '#ff0d60',
                        'clickColor': '#3b74ff',
                        'borderShadowColor': '#4bea27',
                        'borderStyle': '#000000',
                        'borderLineWidth': 10,
                        'bgColor': 'rgba(111,200,239,0.93)',
                        'bgHoverColor': 'rgba(140,0,255,0.93)',
                        'bgClickColor': 'rgba(46,109,134,0.93)',
                        'fontSize': 50,
                        'textLabel': 'Rattus Rattus',
                        'size': { 'w': 0.7, 'h': 0.15 },
                        'localPosition': { 'x': 0.15, 'y': 0.75, 'z': 0.01 },
                    },
                },
                {
                    'id': 5,
                    'type': 'st.buttonTextRenderer',
                    'inputs': {
                        'transparent': true,
                        'opacity': 1,
                        'polygonOffset': true,
                        'polygonOffsetFactor': -4,
                        'borderRadius': 50,
                        'color': '#f5efef',
                        'hoverColor': '#ff0d60',
                        'clickColor': '#3b74ff',
                        'borderShadowColor': '#4bea27',
                        'borderStyle': '#000000',
                        'borderLineWidth': 10,
                        'bgColor': 'rgba(111,200,239,0.93)',
                        'bgHoverColor': 'rgba(140,0,255,0.93)',
                        'bgClickColor': 'rgba(46,109,134,0.93)',
                        'fontSize': 50,
                        'textLabel': 'Pochahontos',
                        'size': { 'w': 0.7, 'h': 0.15 },
                        'localPosition': { 'x': 0.15, 'y': 0.55, 'z': 0.01 },
                    },
                },
                {
                    'id': 6,
                    'type': 'st.buttonTextRenderer',
                    'inputs': {
                        'transparent': true,
                        'opacity': 1,
                        'polygonOffset': true,
                        'polygonOffsetFactor': -4,
                        'borderRadius': 50,
                        'color': '#f5efef',
                        'hoverColor': '#ff0d60',
                        'clickColor': '#3b74ff',
                        'borderShadowColor': '#4bea27',
                        'borderStyle': '#000000',
                        'borderLineWidth': 10,
                        'bgColor': 'rgba(111,200,239,0.93)',
                        'bgHoverColor': 'rgba(140,0,255,0.93)',
                        'bgClickColor': 'rgba(46,109,134,0.93)',
                        'fontSize': 50,
                        'textLabel': 'Comanche',
                        'size': { 'w': 0.7, 'h': 0.15 },
                        'localPosition': { 'x': 0.15, 'y': 0.35, 'z': 0.01 },
                    },
                },
                {
                    'id': 7,
                    'type': 'st.buttonTextRenderer',
                    'inputs': {
                        'transparent': true,
                        'opacity': 0.9,
                        'polygonOffset': true,
                        'polygonOffsetFactor': -4,
                        'borderRadius': 50,
                        'color': '#f5efef',
                        'hoverColor': '#ff0d60',
                        'clickColor': '#3b74ff',
                        'borderShadowColor': '#4bea27',
                        'borderStyle': '#000000',
                        'borderLineWidth': 10,
                        'bgColor': 'rgba(13,255,0,0.6)',
                        'bgHoverColor': 'rgba(140,0,255,0.93)',
                        'bgClickColor': 'rgba(46,109,134,0.93)',
                        'fontSize': 50,
                        'textLabel': 'Kaesar',
                        'size': { 'w': 0.7, 'h': 0.15 },
                        'localPosition': { 'x': 0.15, 'y': 0.15, 'z': 0.01 },
                    },
                },
            ],
            'bindings': [
                [1, 'texture', 2, 'texture'],
                [2, 'painter', 3, 'painter'],
            ],

        }],
    },
};

export const ImageRendererModel = {
    'version': '1.0',
    'icon': 'https://cdn0.iconfinder.com/data/icons/thin-text-editing/24/thin-0256_input_box_text_edit-512.png',
    iconMui: ImageRoundedIcon,
    'payload': {
        'objects': [{
            'name': 'Image',
            'position': { 'x': 0, 'y': 0, 'z': 0 },
            'rotation': { 'x': 0, 'y': 0, 'z': 0 },
            'scale': { 'x': 1, 'y': 1, 'z': 1 },
            'userData': {
                'type': 'imageRenderer',
                'hasPropertiesPanel': true,
                'hasColorProperty': true,
                'hasBorderProperty': false,
                'initialPlacementStyle': 'plane',
                'alwaysShow': false,
                'width': 1,
                'height': 1,
                'borderRadius': 0,
                'borderSize': 0,
                'textureSource': '/assets/images/texture.jpg',
                'events': ['CLICK', 'DRAG', 'HOVER'],
            },
            'components': [
                {
                    'id': 0,
                    'type': 'st.imageRenderer',
                    'inputs': {
                        'localScale': { 'x': 1, 'y': 1, 'z': 1 },
                        'opacity': 1,
                        'borderRadius': 100,
                        'borderSize': 10,
                        'textureSource': '/assets/images/texture.jpg',
                    },
                    'events': {
                        'INTERACTION.CLICK': true,
                        'INTERACTION.HOVER': true,
                        'INTERACTION.DRAG': true,
                        'INTERACTION.DRAG_END': true,
                        'INTERACTION.DRAG_BEGIN': true,
                    },
                },
            ],
        }],
    },
};

export const FireParticleSystem = {
    'version': '1.0',
    'icon': 'https://cdn0.iconfinder.com/data/icons/thin-text-editing/24/thin-0256_input_box_text_edit-512.png',
    iconMui: LocalFireDepartmentRoundedIcon,
    'payload': {
        'objects': [{
            'name': 'Fire',
            'position': { 'x': 0, 'y': 0, 'z': 0 },
            'rotation': { 'x': 0, 'y': 0, 'z': 0 },
            'scale': { 'x': 1, 'y': 1, 'z': 1 },
            'userData': {
                'hasPropertiesPanel': true,
                'alwaysShow': false,
                'initialPlacementStyle': 'plane',
                'particleCount': 1000,
                'velocityMin': { x: 0, y: 0, z: 0 },
                'velocityMax': { x: 0, y: 0, z: 0 },
                'gravity': { x: 0, y: 0, z: 0 },
                'chaosMax': { x: 10, y: 10, z: 10 },
                'chaos': false,
                'particleSystemLife': 1.25,
                'boxVisible': true,
                'events': ['CLICK', 'HOVER'],
            },
            'components': [
                {
                    'id': 0,
                    'type': 'st.fireParticleSystem',
                    'inputs': {
                        'particleCount': 1000,
                        'velocityMin': { x: 0, y: 0, z: 0 },
                        'velocityMax': { x: 0, y: 0, z: 0 },
                        'gravity': { x: 0, y: 0, z: 0 },
                        'chaosMax': { x: 10, y: 10, z: 10 },
                        'chaos': false,
                        'particleSystemLife': 1.25,
                        'boxVisible': true,
                    },
                    'events': {
                        'INTERACTION.CLICK': true,
                        'INTERACTION.HOVER': false,
                        'INTERACTION.DRAG': true,
                        'INTERACTION.DRAG_END': true,
                        'INTERACTION.DRAG_BEGIN': true,
                    },
                },
            ],
        }],
    },
};

export const WaterParticleSystem = {
    'version': '1.0',
    'icon': 'https://cdn0.iconfinder.com/data/icons/thin-text-editing/24/thin-0256_input_box_text_edit-512.png',
    iconMui: WaterfallChartRoundedIcon,
    'payload': {
        'objects': [{
            'name': 'Water Spray',
            'position': { 'x': 0, 'y': 0, 'z': 0 },
            'rotation': { 'x': 0, 'y': 0, 'z': 0 },
            'scale': { 'x': 1, 'y': 1, 'z': 1 },
            'userData': {
                'hasPropertiesPanel': true,
                'alwaysShow': false,
                'initialPlacementStyle': 'plane',
                'particleCount': 1000,
                'velocityMin': { x: 0, y: 0, z: 0 },
                'velocityMax': { x: 0, y: 0, z: 0 },
                'gravity': { x: 0, y: 0, z: 0 },
                'chaosMax': { x: 10, y: 10, z: 10 },
                'chaos': false,
                'particleSystemLife': 1.25,
                'events': ['CLICK', 'HOVER'],
            },
            'components': [
                {
                    'id': 0,
                    'type': 'st.waterParticleSystem',
                    'inputs': {
                        'particleCount': 1000,
                        'velocityMin': { x: 0, y: 0, z: 0 },
                        'velocityMax': { x: 0, y: 0, z: 0 },
                        'gravity': { x: 0, y: 0, z: 0 },
                        'chaosMax': { x: 10, y: 10, z: 10 },
                        'chaos': false,
                        'particleSystemLife': 1.25,
                    },
                    'events': {
                        'INTERACTION.CLICK': true,
                        'INTERACTION.HOVER': false,
                        'INTERACTION.DRAG': true,
                        'INTERACTION.DRAG_END': true,
                        'INTERACTION.DRAG_BEGIN': true,
                    },
                },
            ],
        }],
    },
};

export const DialModel = {
    'version': '1.0',
    'icon': 'https://static.thenounproject.com/png/1037776-200.png',
    'iconMui': CustomDialIcon,
    'payload': {
        'objects': [{
            'name': 'Dial',
            'position': { 'x': 0, 'y': 0, 'z': 0 },
            'rotation': { 'x': 0, 'y': 0, 'z': 0 },
            'scale': { 'x': 1, 'y': 1, 'z': 1 },
            'userData': {
                'type': 'leverToggle',
                'nameToShow': 'Dial',
                'hasPropertiesPanel': true,
                'initialPlacementStyle': 'dial',
                'distanceForNormalOffset': 0.01,
                'disableScale': true,
                'alwaysShow': false,
                'overrideUserData': {
                    'disableScale': true,
                },
                'localPosition': {
                    'z': 0,
                    'y': 0.21,
                    'x': 0.1,
                },
                'rotationAxis': {
                    'x': 0,
                    'y': 0,
                    'z': 1,
                },
                'rotationRange': '0,45',
                'quaternionStart': { 'x': 0, 'y': 0, 'z': 0, 'w': 1 },
                'events': ['CLICK', 'DRAG', 'HOVER'],
            },
            'components': [
                {
                    'type': 'mp.gltfLoader',
                    'inputs': {
                        'url': 'https://storage.googleapis.com/virtual-tc.appspot.com/3d/gltf/Yellow%20Control/YellowControl.gltf',
                        'localScale': { 'x': 0.01, 'y': 0.01, 'z': 0.01 },
                        'localRotation': { 'x': 0, 'y': 0, 'z': 0 },
                        'localPosition': { 'x': 0, 'y': 0, 'z': 0 },
                    },
                    'events': {
                        'INTERACTION.CLICK': true,
                        'INTERACTION.HOVER': true,
                        'INTERACTION.DRAG': true,
                        'INTERACTION.DRAG_END': true,
                        'INTERACTION.DRAG_BEGIN': true,
                    },
                },
                {
                    'type': 'mp.rotateToggle',
                    'inputs': {
                        'rotationAxis': {
                            'x': 0,
                            'y': 1,
                            'z': 0,
                        },
                        'rotationRange': [0, -90],
                        'transitionTime': 0.5,
                    },
                    'events': {
                        'INTERACTION.CLICK': true,
                        'INTERACTION.HOVER': true,
                        'INTERACTION.DRAG': true,
                        'INTERACTION.DRAG_END': true,
                        'INTERACTION.DRAG_BEGIN': true,
                    },
                },
                {
                    'type': 'mp.objLoader',
                    'inputs': {
                        'url': 'https://storage.googleapis.com/virtual-tc.appspot.com/3d/obj/Loading Icon/icon.obj',
                        'localScale': { 'x': 0.01, 'y': 0.01, 'z': 0.01 },
                        'localRotation': { 'x': 0, 'y': 0, 'z': 0 },
                        'localPosition': { 'x': 0, 'y': 0, 'z': 0 },
                    },
                    'events': {
                        'INTERACTION.CLICK': true,
                        'INTERACTION.HOVER': true,
                        'INTERACTION.DRAG': true,
                        'INTERACTION.DRAG_END': true,
                        'INTERACTION.DRAG_BEGIN': true,
                    },

                },
                {
                    'type': 'mp.loadingIndicator',
                    'inputs': {
                        'size': { 'x': 1, 'y': 1, 'z': 1 },
                    },
                },
            ],
            'bindings': [
                [3, 'logo', 2, 'objectRoot'],
                [1, 'targetMesh', 0, 'objectRoot'],
            ],
        }],
    },
};

export const LightModel = {
    'version': '1.0',
    'access': 'ADMIN',
    'icon': 'https://static.thenounproject.com/png/1037776-200.png',
    'iconMui': LightModeOutlinedIcon,
    'payload': {
        'objects': [
            {
                'name': 'Light',
                'position': { 'x': -3, 'y': 1, 'z': 0 },
                'rotation': { 'x': 0.5, 'y': 0.5, 'z': 0.5 },
                'scale': { 'x': 1, 'y': 1, 'z': 1 },
                'userData': {
                    'type': 'arrow',
                    'nameToShow': 'Light',
                    'disableScale': false,
                    'hasPropertiesPanel': true,
                },
                'components': [
                    {
                        'type': 'mp.spotlight',
                        'events': {
                            'INTERACTION.CLICK': false,
                            'INTERACTION.HOVER': false,
                            'INTERACTION.DRAG': false,
                        },
                    },
                ],
            },
        ],
    },
};


export const SpotLightModel = {
    'version': '1.0',
    'icon': 'https://cdn0.iconfinder.com/data/icons/thin-text-editing/24/thin-0256_input_box_text_edit-512.png',
    iconMui: LightbulbIcon,
    access: 'EXPERIMENT-SPOTLIGHT',
    'payload': {
        'objects': [{
            'name': 'Spot Light',
            'position': { 'x': 0, 'y': 0, 'z': 0 },
            'rotation': { 'x': 0, 'y': 0, 'z': 0 },
            'scale': { 'x': 1, 'y': 1, 'z': 1 },
            'userData': {
                'hasPropertiesPanel': true,
                'alwaysShow': true,
                'initialPlacementStyle': 'plane',
            },
            'components': [
                {
                    'id': 0,
                    'type': 'mp.spotlight',
                    'inputs': {
                        'intensity': 100,
                    },
                    // "inputs": {
                    //     "lightColor": "#ffffff",
                    //     "intensity": 1.5,
                    //     "distance": 150,
                    //     "angle": 0.7,
                    //     "pernumbra": 0.2,
                    //     "dacay": 2,
                    //     "focus": 0.5,
                    //     "boxVisible": true
                    // },
                    'events': {
                        'INTERACTION.CLICK': true,
                        'INTERACTION.HOVER': false,
                        'INTERACTION.DRAG': false,
                        'INTERACTION.DRAG_END': false,
                        'INTERACTION.DRAG_BEGIN': false,
                    },
                },
            ],
        }],
    },
};

export const AnalogGaugeModel = {
    'version': '1.0',
    'icon': 'https://www.pinclipart.com/picdir/middle/85-854633_iot-icon-clipart.png',
    iconMui: Icons.Speed,
    'payload': {
        'objects': [{
            'name': 'Analog Gauge',
            'position': { 'x': 0, 'y': 0, 'z': 0 },
            'rotation': { 'x': 0, 'y': 0, 'z': 0 },
            'scale': { 'x': 1, 'y': 1, 'z': 1 },
            'userData': {
                'type': 'analogGauge',
                'hasPropertiesPanel': true,
                'initialPlacementStyle': 'thermostat',
                'hasColorProperty': false,
                'hasBorderProperty': false,
                'inputSource1': 'None',
                'alwaysShow': false,
                'noClickVarChange': true,
                'events': ['CLICK', 'HOVER', 'DRAG'],
                'textureSource': '/assets/3d/VikingAnalogGauge.png',
                'minReading': AnalogGaugeComponent.defaultMinReading,
                'maxReading': AnalogGaugeComponent.defaultMaxReading,
                'initialReadingOffset': AnalogGaugeComponent.defaultIntitalReadingOffset,
                'rotationDelta': AnalogGaugeComponent.defaultRotationDelta,
                'hideDigitalReading': false,
            },
            'components': [
                {
                    'id': 0,
                    'type': 'mp.gltfLoader',
                    'inputs': {
                        'url': '/assets/3d/Gauge.gltf',
                        'localScale': { 'x': 1, 'y': 1, 'z': 1 },
                        'localRotation': { 'x': 0, 'y': 180, 'z': 0 },
                        'localPosition': { 'x': 0, 'y': 0, 'z': 0 },
                    },
                    'events': {
                        'INTERACTION.CLICK': true,
                        'INTERACTION.HOVER': true,
                        'INTERACTION.DRAG': true,
                        'INTERACTION.DRAG_END': true,
                        'INTERACTION.DRAG_BEGIN': true,
                    },
                },
                {
                    'id': 1,
                    'type': 'mp.planeRenderer',
                    'inputs': {
                        'localPosition': { 'x': 0, 'y': 0.15, 'z': 0.75 },
                        'localRotation': { 'x': -90, 'y': 0, 'z': 180 },
                    },
                    'events': {
                        'INTERACTION.CLICK': true,
                        'INTERACTION.HOVER': true,
                        'INTERACTION.DRAG': true,
                        'INTERACTION.DRAG_END': true,
                        'INTERACTION.DRAG_BEGIN': true,
                    },
                },
                {
                    'id': 2,
                    'type': 'mp.canvasRenderer',
                    'inputs': {
                        'textureRes': {
                            'w': 1024,
                            'h': 1024,
                        },
                    },
                    'events': {
                        'INTERACTION.CLICK': true,
                        'INTERACTION.HOVER': true,
                        'INTERACTION.DRAG': true,
                        'INTERACTION.DRAG_END': true,
                        'INTERACTION.DRAG_BEGIN': true,
                    },
                },
                {
                    'id': 3,
                    'type': 'mp.AnalogGauge',
                    'events': {
                        'INTERACTION.CLICK': true,
                        'INTERACTION.HOVER': true,
                        'INTERACTION.DRAG': true,
                        'INTERACTION.DRAG_END': true,
                        'INTERACTION.DRAG_BEGIN': true,
                    },
                },
                {
                    'id': 4,
                    'type': 'mp.objLoader',
                    'inputs': {
                        'url': 'https://storage.googleapis.com/virtual-tc.appspot.com/3d/obj/Loading Icon/icon.obj',
                        'localScale': { 'x': 0.005, 'y': 0.005, 'z': 0.005 },
                        'localRotation': { 'x': 0, 'y': 0, 'z': 0 },
                        'localPosition': { 'x': 0, 'y': 0, 'z': 0 },
                    },
                    'events': {
                        'INTERACTION.CLICK': true,
                        'INTERACTION.HOVER': true,
                        'INTERACTION.DRAG': true,
                        'INTERACTION.DRAG_END': true,
                        'INTERACTION.DRAG_BEGIN': true,
                    },
                },
                {
                    'id': 5,
                    'type': 'mp.loadingIndicator',
                    'inputs': {
                        'size': { 'x': 1, 'y': 1, 'z': 1 },
                    },
                    'events': {
                        'INTERACTION.CLICK': true,
                        'INTERACTION.HOVER': true,
                        'INTERACTION.DRAG': true,
                        'INTERACTION.DRAG_END': true,
                        'INTERACTION.DRAG_BEGIN': true,
                    },
                },
                {
                    'type': 'mp.planeRenderer',
                    'inputs': {
                        // <<<<<<< HEAD
                        'transparent': true,
                        //   "aspect": 1.77777777778,
                        'opacity': 1,
                        'polygonOffset': true,
                        //   "polygonOffsetFactor": -2,
                        'localScale': { 'x': 0.1, 'y': 0.1, 'z': 0.1 },
                        'localRotation': { 'x': -90, 'y': 0, 'z': 180 },
                        'localPosition': { 'x': 0, 'y': 0, 'z': 0.09 }, //+y is forward, +x is left
                        //   "invertScale": false,
                        //   "transparent": true,
                        //   "opacity": 1,
                        //   "polygonOffset": true,
                        'polygonOffsetFactor': -100,
                        'color': 'white',
                    },
                    'events': {
                        'INTERACTION.CLICK': true,
                        'INTERACTION.HOVER': true,
                        'INTERACTION.DRAG': true,
                        'INTERACTION.DRAG_END': true,
                        'INTERACTION.DRAG_BEGIN': true,
                    },
                },
                {
                    'type': 'mp.canvasRenderer',
                    'inputs': {
                        'textureRes': {
                            'w': 1000,
                            'h': 1000,
                        },
                    },
                    'events': {
                        'INTERACTION.CLICK': true,
                        'INTERACTION.HOVER': true,
                        'INTERACTION.DRAG': true,
                        'INTERACTION.DRAG_END': true,
                        'INTERACTION.DRAG_BEGIN': true,
                    },
                },
                {
                    'type': 'mp.canvasText',
                    'inputs': {
                        'text': '',
                        'position': { 'x': 70, 'y': 70, 'z': 0 },
                        'fontStyle': 'normal bold',
                        'fontSize': 400,
                        'fontName': 'sans-serif',
                        //   "textWidth": 200,
                        'fontColor': '#FFFFFF',

                        'size': {
                            'w': 256,
                            'h': 256,
                        },
                        'textWidth': 840,
                    },
                    'events': {
                        'INTERACTION.CLICK': true,
                        'INTERACTION.HOVER': true,
                        'INTERACTION.DRAG': true,
                        'INTERACTION.DRAG_END': true,
                        'INTERACTION.DRAG_BEGIN': true,
                    },
                },
                // =======
                //                       "transparent": true,
                //                     //   "aspect": 1.77777777778,
                //                       "opacity": 1,
                //                       "polygonOffset": true,
                //                     //   "polygonOffsetFactor": -2,
                //                       "localRotation": {"x": -90, "y": 0, "z": 180},
                //                       "localPosition": { "x": 0, "y": 0, "z": 0.09}, //+y is forward, +x is left

                //                     //   "invertScale": false,
                //                     //   "transparent": true,
                //                     //   "opacity": 1,
                //                     //   "polygonOffset": true,
                //                       "polygonOffsetFactor": -100,
                //                     //   "color": "white"
                //                     }
                //                   },
                //                   {
                //                     "type": "mp.canvasRenderer",
                //                     "inputs": {
                //                       "textureRes": {
                //                         "w": 512,
                //                         "h": 512
                //                       }
                //                     }
                //                   },
                //                   {
                //                     "type": "mp.canvasText",
                //                     "inputs": {
                //                     //   "size": {
                //                     //     "h": 256,
                //                     //     "w": 256
                //                     //   },
                //                       "text": "70",
                //                       "position": { "x":70, "y": 70, "z": 0 },
                //                       "fontStyle": "normal bold",
                //                       "fontSize": 40,
                //                       "fontName": "sans-serif",
                //                     //   "textWidth": 200,
                //                       "fontColor": "#FFFFFF",

                //                       "size": {
                //                         "w": 256,
                //                         "h": 256
                //                     },
                //                     // "fontName": "sans-serif",
                //                     // "position": { "x": 30, "y": 0, "z": 0 },
                //                     "textWidth": 40
                //                     }
                //                   }
                // >>>>>>> s24/arp/AnalogGauge
            ],
            'bindings': [
                [3, 'loadingState', 0, 'loadingState'],
                [1, 'texture', 2, 'texture'],
                [2, 'painter', 3, 'painter'],
                [3, 'texture', 2, 'texture'],
                [1, 'visible', 3, 'visible'],
                [5, 'logo', 4, 'objectRoot'],

                [7, 'painter', 8, 'painter'],
                [6, 'texture', 7, 'texture'],
            ],
            'events': [
                [3, 'hover', 0, 'INTERACTION.HOVER'],
                [3, 'hover', 1, 'INTERACTION.HOVER'],
                [2, 'repaint', 3, 'repaint'],
                [7, 'repaint', 8, 'textUpdate'],
            ],
        }],
    },
};

export const ArrowFlow = {
    'version': '1.0',
    'icon': 'https://cdn0.iconfinder.com/data/icons/thin-text-editing/24/thin-0256_input_box_text_edit-512.png',
    'iconMui': FastForwardIcon,
    'payload': {
        'objects': [{
            'name': 'Arrow Flow',
            'position': { 'x': 0, 'y': 0, 'z': 0 },
            'rotation': { 'x': 0, 'y': 0, 'z': 0 },
            'scale': { 'x': 1, 'y': 1, 'z': 1 },
            'userData': {
                'type': 'arrowFlow',
                'hasPropertiesPanel': true,
                'hasColorProperty': true,
                'alwaysShow': false,
                'inputSource1': 'None',
                'flowRadius': 1,
                'noClickVarChange': false,
                'events': ['CLICK', 'HOVER', 'DRAG'],

            },
            'components': [
                {
                    'id': 0,
                    'type': 'mp.objLoader',
                    'inputs': {
                        'url': 'https://storage.googleapis.com/virtual-tc.appspot.com/3d/obj/FS/FS.obj',
                        'materialUrl': 'https://storage.googleapis.com/virtual-tc.appspot.com/3d/obj/FS/FS.mtl',
                        'localScale': { 'x': 0.0025, 'y': 0.0025, 'z': 0.0025 },
                        'localRotation': { 'x': 0, 'y': 0, 'z': 0 },
                        'localPosition': { 'x': 0, 'y': 0, 'z': 0 },
                    },
                    'events': {
                        'INTERACTION.CLICK': true,
                        'INTERACTION.HOVER': true,
                        'INTERACTION.DRAG': true,
                        'INTERACTION.DRAG_END': true,
                        'INTERACTION.DRAG_BEGIN': true,
                    },
                },

                {
                    'id': 1,
                    'type': 'mp.objLoader',
                    'inputs': {
                        'url': 'https://storage.googleapis.com/virtual-tc.appspot.com/3d/obj/Loading Icon/icon.obj',
                        'localScale': { 'x': 0.01, 'y': 0.01, 'z': 0.01 },
                        'localRotation': { 'x': 0, 'y': 0, 'z': 0 },
                        'localPosition': { 'x': 0, 'y': 0, 'z': 0 },
                    },
                    'events': {
                        'INTERACTION.CLICK': true,
                        'INTERACTION.HOVER': true,
                        'INTERACTION.DRAG': true,
                        'INTERACTION.DRAG_END': true,
                        'INTERACTION.DRAG_BEGIN': true,
                    },
                },
                {
                    'id': 2,
                    'type': 'mp.loadingIndicator',
                    'inputs': {
                        'size': { 'x': 1, 'y': 1, 'z': 1 },
                    },
                },
                {
                    'id': 3,
                    'type': 'mp.flowmodel',
                    'inputs': {
                        'isActivate': false,
                    },
                    'events': {
                        'INTERACTION.CLICK': true,
                        'INTERACTION.HOVER': true,
                        'INTERACTION.DRAG': true,
                        'INTERACTION.DRAG_END': true,
                        'INTERACTION.DRAG_BEGIN': true,
                    },
                },
            ],
            'bindings': [
                [2, 'logo', 1, 'objectRoot'],
            ],
        }],
    },
};


export const Dropzone = {
    'version': '1.0',
    'icon': 'https://static.thenounproject.com/png/438176-200.png',
    iconMui: BoxHighlightIcon,
    'access': 'ADMIN',
    'payload': {
        'objects': [{
            'name': 'Dropzone',
            'position': { 'x': 0, 'y': 0, 'z': 0 },
            'rotation': { 'x': 0, 'y': 0, 'z': 0 },
            'scale': { 'x': 1, 'y': 1, 'z': 1 },
            'userData': {
                'type': 'boundexBox',
                'hasPropertiesPanel': true,
                'hasColorProperty': true,
                'hasBorderProperty': true,
                'alwaysShow': false,
            },
            'components': [
                {
                    'type': 'mp.dropzone',
                    'events': {
                        'INTERACTION.CLICK': true,
                        'INTERACTION.HOVER': true,
                    },
                },
            ],
        }],
    },
};

export const videoView = {
    'version': '1.0',
    'icon': 'https://cdn0.iconfinder.com/data/icons/thin-text-editing/24/thin-0256_input_box_text_edit-512.png',
    'iconMui': OndemandVideoIcon,
    'access': 'ADMIN',
    'payload': {
        'objects': [
            {
                'name': 'video',
                'position': { 'x': -3.0, 'y': 2.3, 'z': 6.7 },
                'rotation': { 'x': 0, 'y': 90, 'z': 0 },
                'scale': { 'x': 1.75, 'y': 1.75, 'z': 1.75 },
                'userData': {
                    'type': 'webcam',
                    'hasPropertiesPanel': true,
                    'alwaysShow': false,
                    'initialPlacementStyle': 'plane',
                    'inputSource1': 'None',
                },
                'components': [
                    {
                        'type': 'mp.tuner',
                        'inputs': {
                            'urls': [
                                'https://video2archives.earthcam.com/archives/_definst_/MP4:permanent/14162/2019/07/22/1300.mp4/chunklist_w1401431259.m3u8',
                                'https://nmxlive.akamaized.net/hls/live/529965/Live_1/index.m3u8',
                                'https://br_hdslive-f.akamaihd.net/i/bfssued_germany@119890/index_3776_av-p.m3u8',
                            ],
                        },
                        'events': {
                            'next': true,
                        },
                    },
                    {
                        'type': 'mp.hlsLoader',
                    },
                    {
                        'type': 'mp.videoRenderer',
                    },
                    {
                        'type': 'mp.planeRenderer',
                        'inputs': {
                            'transparent': false,
                            'localScale': { 'x': 0.6, 'y': 0.6, 'z': 0.6 },
                            'localPosition': { 'x': 0, 'y': 0, 'z': 0 },
                        },
                    },
                    {
                        'type': 'mp.toggleState',
                        'inputs': {
                            'initialState': false,
                        },
                    },

                    {
                        'type': 'mp.planeRenderer',
                        'inputs': {
                            'transparent': true,
                            'opacity': 0.8,
                            'localScale': { 'x': 0.6, 'y': 0.6, 'z': 0.6 },
                        },
                    },
                    {
                        'type': 'mp.canvasRenderer',
                        'inputs': {
                            'textureRes': {
                                'w': 1024,
                                'h': 1024,
                            },
                        },
                    },
                    {
                        'type': 'mp.canvasBorder',
                        'inputs': {
                            'position': { 'x': 0, 'y': 0 },
                            'size': { 'h': 1024, 'w': 1024 },
                            'radius': 50,
                        },
                    },

                    {
                        'type': 'mp.planeRenderer',
                        'inputs': {
                            'localScale': { 'x': 0.6, 'y': 0.6, 'z': 0.6 },
                            'transparent': true,
                            'opacity': 0.8,
                            'polygonOffset': true,
                            'polygonOffsetFactor': -2,
                        },
                    },
                    {
                        'type': 'mp.canvasRenderer',
                        'inputs': {
                            'textureRes': {
                                'w': 512,
                                'h': 512,
                            },
                        },
                    },
                    {
                        'type': 'mp.canvasText',
                        'inputs': {
                            'size': {
                                'h': 512,
                                'w': 512,
                            },
                            'text': 'Click me.',
                            'font': 'normal bold 40px sans-serif',
                            'position': { 'x': 160, 'y': 210, 'z': 0 },
                            'textWidth': 250,
                        },
                    },

                    {
                        'type': 'mp.planeRenderer',
                        'inputs': {
                            'localScale': { 'x': 0.08, 'y': 0.08, 'z': 1.0 },
                            'localPosition': { 'x': 0.22, 'y': -0.13, 'z': 0.01 },
                        },
                        'events': {
                            'INTERACTION.CLICK': true,
                        },
                    },
                    {
                        'type': 'mp.canvasRenderer',
                        'inputs': {
                            'textureRes': {
                                'w': 128,
                                'h': 128,
                            },
                        },
                    },
                    {
                        'type': 'mp.canvasImage',
                        'inputs': {
                            'src': 'https://static.matterport.com/showcase-sdk/examples/assets-1.0-2-g6b74572/assets/textures/ff.png',
                            'srcPosition': { 'x': 0, 'y': 0, 'z': 0 },
                            'srcSize': { 'w': 128, 'h': 128 },
                            'destPosition': { 'x': 0, 'y': 0, 'z': 0 },
                            'destSize': { 'w': 128, 'h': 128 },
                        },
                    },
                ],
                'bindings': [
                    [1, 'src', 0, 'src'],
                    [2, 'src', 1, 'video'],
                    [3, 'texture', 2, 'texture'],
                    [1, 'enabled', 4, 'state'],
                    [3, 'aspect', 1, 'aspect'],

                    [6, 'painter', 7, 'painter'],
                    [5, 'texture', 6, 'texture'],
                    [5, 'aspect', 1, 'aspect'],

                    [9, 'painter', 10, 'painter'],
                    [8, 'texture', 9, 'texture'],
                    [8, 'visible', 4, 'negated'],

                    [12, 'painter', 13, 'painter'],
                    [11, 'texture', 12, 'texture'],
                    [11, 'visible', 4, 'state'],
                ],
                'events': [
                    [4, 'toggle', 3, 'INTERACTION.CLICK'],
                    [0, 'next', 11, 'INTERACTION.CLICK'],
                    [12, 'repaint', 13, 'paint.ready'],
                ],
            },
        ],
    },
};

export const internalModelMap = new Map<any, any>();
export const ArrowFlowMarker = {
    'version': '1.0',
    'icon': 'https://cdn0.iconfinder.com/data/icons/thin-text-editing/24/thin-0256_input_box_text_edit-512.png',
    iconMui: TextFormatRoundedIcon,
    'payload': {
        'objects': [{
            'name': 'FlowMarker',
            'position': { 'x': 0, 'y': 0, 'z': 0 },
            'rotation': { 'x': 0, 'y': 0, 'z': 0 },
            'scale': { 'x': 1, 'y': 1, 'z': 1 },
            'userData': {
                'type': 'arrowFlowMarker',
                'hasPropertiesPanel': true,
                'initialPlacementStyle': 'plane',
            },
            'components': [
                {
                    'id': 0,
                    'type': 'mp.objLoader',
                    'inputs': {
                        'url': 'https://storage.googleapis.com/virtual-tc.appspot.com/3d/obj/FS/FS.obj',
                        'materialUrl': 'https://storage.googleapis.com/virtual-tc.appspot.com/3d/obj/FS/FS.mtl',
                        'localScale': { 'x': 0.0025, 'y': 0.0025, 'z': 0.0025 },
                        'localRotation': { 'x': 0, 'y': 0, 'z': 0 },
                        'localPosition': { 'x': 0, 'y': 0, 'z': 0 },
                    },
                    'events': {
                        'INTERACTION.CLICK': true,
                        'INTERACTION.HOVER': true,
                        'INTERACTION.DRAG': true,
                        'INTERACTION.DRAG_END': true,
                        'INTERACTION.DRAG_BEGIN': true,
                    },
                },
            ],
        }],
    },
};

export const WireTerminal = {
    'version': '1.0',
    'icon': 'https://cdn0.iconfinder.com/data/icons/thin-text-editing/24/thin-0256_input_box_text_edit-512.png',
    iconMui: TextFormatRoundedIcon,
    'payload': {
        'objects': [{
            'name': 'WireTerminal',
            'position': { 'x': 0, 'y': 0, 'z': 0 },
            'rotation': { 'x': 0, 'y': 0, 'z': 0 },
            'scale': { 'x': 1, 'y': 1, 'z': 1 },
            'userData': {
                'type': 'wireTerminal',
                'hasPropertiesPanel': true,
                'initialPlacementStyle': 'plane',
            },
            'components': [
                {
                    'id': 0,
                    'type': 'mp.wireTerminal',
                    'inputs': {
                        'localScale': { 'x': 0.0025, 'y': 0.0025, 'z': 0.0025 },
                        'localRotation': { 'x': 0, 'y': 0, 'z': 0 },
                        'localPosition': { 'x': -0.2, 'y': 0, 'z': 0.1 },
                        'color': 'blue',
                        'radius': 0.01,
                        'shape': "sphere"
                    },
                    'events': {
                        'INTERACTION.CLICK': true,
                        'INTERACTION.HOVER': true,
                        'INTERACTION.DRAG': true,
                        'INTERACTION.DRAG_END': true,
                        'INTERACTION.DRAG_BEGIN': true,
                    },
                },
            ],
        }],
    },
};

export const QuizModel = {
    'version': '1.0',
    'icon': 'https://static.thenounproject.com/png/1037776-200.png',
    iconMui: CustomPowerOnIcon,
    "access": "ADMIN",
    'payload': {
        'objects': [{
            'name': 'Quiz',
            'position': { 'x': 0, 'y': 0, 'z': 0 },
            'rotation': { 'x': 0, 'y': 0, 'z': 0 },
            'scale': { 'x': 1, 'y': 1, 'z': 1 },
            'userData': {
                'hasPropertiesPanel': true,
                'alwaysShow': false,
                'initialPlacementStyle': 'plane',
                'promptText': 'Add your own text',
                'choices': [
                    {
                        text: 'Red',
                        isCorrect: true,
                    },

                    {
                        text: 'Blue',
                        isCorrect: false,
                    },
                    {
                        text: 'Green',
                        isCorrect: false,
                    }
                ],
                'events': ['CLICK', 'HOVER'],
            },
            'components': [
                {
                    'id': 0,
                    'type': 'st.plateRenderer',
                    'inputs': {
                        'aspect': 1.77777777778,
                        'transparent': true,
                        'opacity': 0.8,
                        'polygonOffset': true,
                        'polygonOffsetFactor': 0,
                        'color': 0x5f69f6,
                        'borderRadius': 100,
                    },
                    'events': {
                        'INTERACTION.CLICK': true,
                        'INTERACTION.HOVER': false,
                        'INTERACTION.DRAG': false,
                        'INTERACTION.DRAG_END': false,
                        'INTERACTION.DRAG_BEGIN': false,
                    },
                },
                {
                    'id': 1,
                    'type': 'st.plateRenderer',
                    'inputs': {
                        'aspect': 1.77777777778,
                        'transparent': true,
                        'opacity': 1,
                        'polygonOffset': true,
                        'polygonOffsetFactor': -2,
                        'borderRadius': 100,
                        'invertScale': true,
                    },
                    'events': {
                        'INTERACTION.CLICK': true,
                        'INTERACTION.HOVER': false,
                        'INTERACTION.DRAG': false,
                        'INTERACTION.DRAG_END': false,
                        'INTERACTION.DRAG_BEGIN': false,
                    },
                },
                {
                    'id': 2,
                    'type': 'mp.canvasRenderer',
                    'inputs': {
                        'textureRes': {
                            'w': 1024,
                            'h': 1024,
                        },
                    },
                },
                {
                    'id': 3,
                    'type': 'mp.canvasBorder',
                    'inputs': {
                        'position': {
                            'x': 0,
                            'y': 0,
                        },
                        'size': {
                            'w': 1024,
                            'h': 1024,
                        },
                        'radius': 100,
                        'lineWidth': 20,
                        'color': 'white',
                    },
                },
                {
                    'id': 4,
                    'type': 'st.buttonTextRenderer',
                    'inputs': {
                        'transparent': true,
                        'opacity': 1,
                        'polygonOffset': true,
                        'polygonOffsetFactor': -4,
                        'borderRadius': 50,
                        'color': '#f5efef',
                        'hoverColor': '#ff0d60',
                        'clickColor': '#3b74ff',
                        'borderShadowColor': '#4bea27',
                        'borderStyle': '#000000',
                        'borderLineWidth': 10,
                        'bgColor': 'rgba(111,200,239,0.93)',
                        'bgHoverColor': 'rgba(140,0,255,0.93)',
                        'bgClickColor': 'rgba(46,109,134,0.93)',
                        'fontSize': 25,
                        'textLabel': 'Geronomo',
                        'size': { 'w': 0.7, 'h': 0.1 },
                        'localPosition': { 'x': 0.0, 'y': 0.1, 'z': 0.01 },
                    },
                },
                {
                    'id': 5,
                    'type': 'st.buttonTextRenderer',
                    'inputs': {
                        'transparent': true,
                        'opacity': 1,
                        'polygonOffset': true,
                        'polygonOffsetFactor': -4,
                        'borderRadius': 50,
                        'color': '#f5efef',
                        'hoverColor': '#ff0d60',
                        'clickColor': '#3b74ff',
                        'borderShadowColor': '#4bea27',
                        'borderStyle': '#000000',
                        'borderLineWidth': 10,
                        'bgColor': 'rgba(111,200,239,0.93)',
                        'bgHoverColor': 'rgba(140,0,255,0.93)',
                        'bgClickColor': 'rgba(46,109,134,0.93)',
                        'fontSize': 25,
                        'textLabel': 'Pochahontos',
                        'size': { 'w': 0.7, 'h': 0.1 },
                        'localPosition': { 'x': 0.0, 'y': -0.05, 'z': 0.01 },
                    },
                },
                {
                    'id': 6,
                    'type': 'st.buttonTextRenderer',
                    'inputs': {
                        'transparent': true,
                        'opacity': 1,
                        'polygonOffset': true,
                        'polygonOffsetFactor': -4,
                        'borderRadius': 50,
                        'color': '#f5efef',
                        'hoverColor': '#ff0d60',
                        'clickColor': '#3b74ff',
                        'borderShadowColor': '#4bea27',
                        'borderStyle': '#000000',
                        'borderLineWidth': 10,
                        'bgColor': 'rgba(111,200,239,0.93)',
                        'bgHoverColor': 'rgba(140,0,255,0.93)',
                        'bgClickColor': 'rgba(46,109,134,0.93)',
                        'fontSize': 25,
                        'textLabel': 'Comanche',
                        'size': { 'w': 0.7, 'h': 0.1 },
                        'localPosition': { 'x': 0.0, 'y': -0.20, 'z': 0.01 },
                    },
                },
                {
                    'id': 7,
                    'type': 'st.buttonTextRenderer',
                    'inputs': {
                        'transparent': true,
                        'opacity': 1,
                        'polygonOffset': true,
                        'polygonOffsetFactor': -4,
                        'borderRadius': 50,
                        'color': '#f5efef',
                        'hoverColor': '#ff0d60',
                        'clickColor': '#3b74ff',
                        'borderShadowColor': '#4bea27',
                        'borderStyle': '#000000',
                        'borderLineWidth': 10,
                        'bgColor': 'rgba(111,200,239,0.93)',
                        'bgHoverColor': 'rgba(140,0,255,0.93)',
                        'bgClickColor': 'rgba(46,109,134,0.93)',
                        'fontSize': 25,
                        'textLabel': 'Kaesar',
                        'size': { 'w': 0.7, 'h': 0.1 },
                        'localPosition': { 'x': 0.0, 'y': -0.35, 'z': 0.01 },
                    },
                },
                {
                    'id': 8,
                    'type': 'st.plainTextRendererType',
                    'inputs': {
                        'transparent': true,
                        'opacity': 1,
                        'polygonOffset': true,
                        'polygonOffsetFactor': -4,
                        'borderRadius': 5,
                        'color': '#f5efef',
                        'borderShadowColor': '#00000000',
                        'borderStyle': '#00000000',
                        'borderLineWidth': 0,
                        'bgColor': 'rgba(190,190,190,0.1)',
                        'fontSize': 40,
                        'textLabel': 'Pick the odd one out!',
                        'size': { 'w': 0.9, 'h': 0.15 },
                        'localPosition': { 'x': 0.0, 'y': 0.3, 'z': 0.01 },
                    },
                },
            ],
            'bindings': [
                [1, 'texture', 2, 'texture'],
                [2, 'painter', 3, 'painter'],
            ],

        }],
    },
};

export const WireModel = {
    'version': '1.0',
    'icon': 'https://static.thenounproject.com/png/438176-200.png',
    iconMui: WireIcon,
    'payload': {
        'objects': [{
            'name': 'Wire',
            'position': { 'x': 0, 'y': 0, 'z': 0 },
            'rotation': { 'x': 0, 'y': 0, 'z': 0 },
            'scale': { 'x': 1, 'y': 1, 'z': 1 },
            'userData': {
                'type': 'wire',
                'hasPropertiesPanel': true,
                'hasColorProperty': true,
                'initialPlacementStyle': 'wire',
                'alwaysShow': false,
                'events': ['CLICK', 'HOVER', 'DRAG'],
                'startTerminalPosition': { 'x': -0.2, 'y': 0, 'z': 0.1 },
                'midTerminalPosition': { 'x': 0.15, 'y': 0, 'z': 0.15 },
                'endTerminalPosition': { 'x': 0.2, 'y': 0, 'z': 0.1 },
                'wireWidth': 1
            },
            'components': [
                {
                    'id': 0,
                    'type': 'mp.wire',
                    'inputs': {
                        'point1': { 'x': -0.2, 'y': 0, 'z': 0.1 },
                        'point2': { 'x': 0.15, 'y': 0, 'z': 0.15 },
                        'point3': { 'x': 0.2, 'y': 0, 'z': 0.1 },
                    },
                    'outputs': {
                        'index': 0
                    },
                    'events': {
                        'INTERACTION.CLICK': true,
                        // 'INTERACTION.HOVER': true,
                        // 'INTERACTION.DRAG': true,
                        // 'INTERACTION.DRAG_END': true,
                        // 'INTERACTION.DRAG_BEGIN': true,
                    },
                },
                {
                    'id': 1,
                    'type': 'mp.wireTerminal',
                    'inputs': {
                        'localScale': { 'x': 0.0025, 'y': 0.0025, 'z': 0.0025 },
                        'localRotation': { 'x': 0, 'y': 0, 'z': 0 },
                        'localPosition': { 'x': -0.2, 'y': 0, 'z': 0.1 },
                        'terminal': 'start',
                        'wireIndex': 0,
                        'showInConditions': true,
                        'name': 'Positive Terminal',
                        'color': 'red',
                        'shape': "sphere"
                    },
                    'outputs': {
                        'index': 1
                    },
                    'events': {
                        'INTERACTION.CLICK': true,
                        'INTERACTION.HOVER': true,
                        'INTERACTION.DRAG': true,
                        'INTERACTION.DRAG_END': true,
                        'INTERACTION.DRAG_BEGIN': true,
                    },
                },
                {
                    'id': 2,
                    'type': 'mp.wireTerminal',
                    'inputs': {
                        'localScale': { 'x': 0.0025, 'y': 0.0025, 'z': 0.0025 },
                        'localRotation': { 'x': 0, 'y': 0, 'z': 0 },
                        'localPosition': { 'x': 0.2, 'y': 0, 'z': 0.1 },
                        'terminal': 'end',
                        'wireIndex': 1,
                        'showInConditions': true,
                        'name': 'Negative Terminal',
                        'color': 'black',
                        'shape': "sphere"
                    },
                    'outputs': {
                        'index': 2
                    },
                    'events': {
                        'INTERACTION.CLICK': true,
                        'INTERACTION.HOVER': true,
                        'INTERACTION.DRAG': true,
                        'INTERACTION.DRAG_END': true,
                        'INTERACTION.DRAG_BEGIN': true,
                    },
                },
                {
                    'id': 3,
                    'type': 'mp.wireTerminal',
                    'inputs': {
                        'localScale': { 'x': 0.0025, 'y': 0.0025, 'z': 0.0025 },
                        'localRotation': { 'x': 0, 'y': 0, 'z': 0 },
                        'localPosition': { 'x': -0.2, 'y': 0, 'z': 0.1 },
                        'terminal': 'mid',
                        'wireIndex': 0,
                        'showInConditions': false,
                        'name': 'Mid Terminal',
                        'color': 'blue',
                        'shape': "sphere"
                    },
                    'outputs': {
                        'index': 3
                    },
                    'events': {
                        'INTERACTION.CLICK': true,
                        'INTERACTION.HOVER': true,
                        'INTERACTION.DRAG': true,
                        'INTERACTION.DRAG_END': true,
                        'INTERACTION.DRAG_BEGIN': true,
                    },
                },

            ],
            'bindings': [ //target, then source
                [1, 'width', 0, 'wireWidth'],
                [2, 'width', 0, 'wireWidth'],
                [1, 'lookAtPosition', 0, 'wireCenter'],
                [2, 'lookAtPosition', 0, 'wireCenter'],

                [0, 'positiveConnectedTo', 1, 'connectedTo'],
                [0, 'negativeConnectedTo', 2, 'connectedTo'],
            ],
            'events': [ //target, then source
                // [0, 'reposition', 1, 'INTERACTION.CLICK'],
                // [0, 'reposition', 2, 'INTERACTION.CLICK'],

                [0, 'repaint_start', 1, 'INTERACTION.DRAG_BEGIN'],
                [0, 'repaint_start', 1, 'INTERACTION.DRAG'],
                [0, 'repaint_start', 1, 'INTERACTION.DRAG_END'],
                [0, 'repaint_start', 1, 'repaint'],
                [0, 'repaint_end', 2, 'INTERACTION.DRAG_BEGIN'],
                [0, 'repaint_end', 2, 'INTERACTION.DRAG'],
                [0, 'repaint_end', 2, 'repaint'],
                // [0, 'repaint_mid', 3, 'INTERACTION.DRAG_BEGIN'],
                // [0, 'repaint_mid', 3, 'INTERACTION.DRAG'],
                // [0, 'repaint_mid', 3, 'INTERACTION.DRAG_END'],
                [0, 'repaint_end', 3, 'repaint'],
            ],
        }],
    },
};

export const MultimeterModel = {
    'version': '1.0',
    'icon': 'https://static.thenounproject.com/png/438176-200.png',
    iconMui: MultimeterIcon,
    'payload': {
        'objects': [{
            'name': 'Multimeter',
            'position': { 'x': 0, 'y': 0, 'z': 0 },
            'rotation': { 'x': 0, 'y': 0, 'z': 0 },
            'scale': { 'x': 1, 'y': 1, 'z': 1 },
            'userData': {
                'type': 'multimeter',
                'hasPropertiesPanel': true,
                'initialPlacementStyle': 'plane',
                'hasColorProperty': true,
                'alwaysShow': false,
                'events': ['CLICK', 'HOVER', 'DRAG'],

                // <<<<<<< HEAD
                // 'startTerminal1Position': { 'x': -0.02, 'y': -0.23, 'z': 0.1 },
                // 'endTerminal1Position': { 'x': 0.01, 'y': -0.13, 'z': 0 },
                // 'startTerminal2Position': { 'x': 0.05, 'y': -0.13, 'z': 0 },
                // 'endTerminal2Position': { 'x': 0.02, 'y': -0.23, 'z': 0.1 },

                'startTerminal1Position': { 'x': -0.02, 'y': -0.23, 'z': 0.1 },
                // 'midTerminal1Position': { 'x': -0.02, 'y': -0.16, 'z': 0.1 },
                'endTerminal1Position': { 'x': 0.016, 'y': -0.087, 'z': 0 },
                'startTerminal2Position': { 'x': 0.056, 'y': -0.087, 'z': 0 },
                // 'midTerminal2Position': { 'x': 0.02, 'y': -0.16, 'z': 0.1 },
                'endTerminal2Position': { 'x': 0.02, 'y': -0.23, 'z': 0.1 },
                'wireWidth': 1,
                'textureSource': '',
                // =======
                // 'startTerminal1Position': { 'x': -0.02, 'y': -0.2, 'z': 0.2 },
                // 'endTerminal1Position': { 'x': -0.035, 'y': -0.07, 'z': 0 },
                // 'startTerminal2Position': { 'x': 0.035, 'y': -0.07, 'z': 0 },
                // 'endTerminal2Position': { 'x': 0.02, 'y': -0.2, 'z': 0.2 },

                // >>>>>>> s36/arp/bms2
                'componentLocalPositions': [
                    {
                        'componentIndex': 0,
                        'localPosition': { 'x': 0, 'y': 0, 'z': 0 },
                        'localRotation': { 'x': 0, 'y': 0, 'z': 0 },
                        'localScale': { 'x': 1, 'y': 1, 'z': 1 },
                    }
                ]

            },
            'components': [
                {
                    'id': 0,
                    'type': 'mp.wire',
                    'inputs': {
                        'color': 'black',
                        'lineWidth': 3,
                        'point1': { 'x': -0.4, 'y': 0, 'z': 0.1 },
                        'point2': { 'x': -0.02, 'y': -0.16, 'z': 0.1 },
                        'point3': { 'x': -0.2, 'y': 0, 'z': 0.4 },

                        // 'point1': { 'x': 0.2, 'y': 0, 'z': 0.1 },
                        // 'point2': { 'x': 0.02, 'y': -0.16, 'z': 0.1 },
                        // 'point3': { 'x': 0.4, 'y': 0, 'z': 0.1 },
                        // },
                    },
                    'outputs': {
                        'index': 0
                    },
                    'events': {
                        'INTERACTION.CLICK': true,
                        // 'INTERACTION.HOVER': true,
                        // 'INTERACTION.DRAG': true,
                        // 'INTERACTION.DRAG_END': true,
                        // 'INTERACTION.DRAG_BEGIN': true,
                    },
                },
                {
                    'id': 1,
                    'type': 'mp.wireTerminal',
                    'inputs': {
                        'localScale': { 'x': 0.0025, 'y': 0.0025, 'z': 0.0025 },
                        'localRotation': { 'x': 0, 'y': 0, 'z': 0 },
                        'localPosition': { 'x': -0.01, 'y': 1, 'z': 0.1 },
                        'terminal': 'start',
                        'wireIndex': 0,
                        'showInConditions': true,
                        'name': 'Negative Terminal',
                        'color': 'black',
                        'shape': "sphere"
                    },
                    'outputs': {
                        'index': 1
                    },
                    'events': {
                        'INTERACTION.CLICK': true,
                        // 'INTERACTION.HOVER': true,
                        'INTERACTION.DRAG': true,
                        'INTERACTION.DRAG_END': true,
                        'INTERACTION.DRAG_BEGIN': true,
                    },
                },
                {
                    'id': 2,
                    'type': 'mp.wireTerminal',
                    'inputs': {
                        'localScale': { 'x': 0.0025, 'y': 0.0025, 'z': 0.0025 },
                        'localRotation': { 'x': 0, 'y': 0, 'z': 0 },
                        'localPosition': { 'x': -0.2, 'y': 0.1, 'z': 0.1 },
                        'terminal': 'end',
                        'showInConditions': false,
                        // 'name': 'Negative Terminal',
                        'color': 'black',
                        'wireIndex': 0,
                        'shape': "sphere"
                    },
                    'outputs': {
                        'index': 2
                    },
                    'events': {

                    },
                },
                // 'id': 4,
                // 'type': 'mp.planeRenderer',
                // 'inputs': {
                //     'invertScale': false,
                //     'transparent': true,
                //     'opacity': 1,
                //     'polygonOffset': true,
                //     'polygonOffsetFactor': -100,
                //     'color': 'white',
                // },
                {
                    'id': 3,
                    'type': 'mp.planeRenderer',
                    'inputs': {
                        'localPosition': { 'x': 0.001, 'y': 0.12, 'z': 0.0021 },
                        'localRotation': { 'x': 0, 'y': 0, 'z': 0 },
                        'localScale': { 'x': 0.12, 'y': 0.095, 'z': 0.5 },
                        'invertScale': false,
                        'transparent': true,
                        'opacity': 1,
                        'polygonOffset': true,
                        'polygonOffsetFactor': -100,
                        'color': 'white',
                        'allowLocalPostitionChange': true
                    },
                    'outputs': {
                        'index': 3
                    },
                    'events': {
                        'INTERACTION.CLICK': true,
                        // 'INTERACTION.HOVER': true,
                        // 'INTERACTION.DRAG': true,
                        // 'INTERACTION.DRAG_BEGIN': true,
                        // 'INTERACTION.DRAG_END': true,
                    },
                },
                {
                    // 'id': 5,
                    // 'type': 'mp.canvasRenderer',
                    // 'inputs': {
                    //     'textureRes': {
                    //         'w': 512,
                    //         'h': 512,
                    //     },
                    // },
                    'id': 4,
                    'type': 'mp.canvasRenderer',
                    'inputs': {
                        'textureRes': {
                            'w': 512,
                            'h': 512,
                        },
                    },
                    'outputs': {
                        'index': 4
                    },
                    'events': {
                        'INTERACTION.CLICK': true,
                        // 'INTERACTION.HOVER': true,
                        // 'INTERACTION.DRAG': true,
                        // 'INTERACTION.DRAG_BEGIN': true,
                        // 'INTERACTION.DRAG_END': true,
                    },
                },
                {
                    'id': 5,
                    'type': 'mp.multimeter',
                    'outputs': {
                        'index': 5
                    },
                    'events': {
                        'INTERACTION.CLICK': true,
                        // 'INTERACTION.HOVER': true,
                        // 'INTERACTION.DRAG': true,
                        // 'INTERACTION.DRAG_BEGIN': true,
                        // 'INTERACTION.DRAG_END': true,
                    },
                },
                {
                    'id': 6,
                    'type': 'mp.wire',
                    'outputs': {
                        'index': 6
                    },
                    'inputs': {
                        'color': 'red',
                        'lineWidth': 3,
                        'point1': { 'x': 0.2, 'y': 0, 'z': 0.1 },
                        'point2': { 'x': 0.02, 'y': -0.16, 'z': 0.1 },
                        'point3': { 'x': 0.4, 'y': 0, 'z': 0.1 },
                    },
                    'events': {
                        'INTERACTION.CLICK': true,
                        // 'INTERACTION.HOVER': true,
                        // 'INTERACTION.DRAG': true,
                        // 'INTERACTION.DRAG_END': true,
                        // 'INTERACTION.DRAG_BEGIN': true,
                    },
                },
                {
                    'id': 7,
                    'type': 'mp.wireTerminal',
                    'inputs': {
                        'localScale': { 'x': 0.0025, 'y': 0.0025, 'z': 0.0025 },
                        'localRotation': { 'x': 0, 'y': 0, 'z': 0 },
                        'localPosition': { 'x': 0.3, 'y': 0, 'z': 0.1 },
                        'terminal': 'start',
                        'showInConditions': false,
                        // 'name': 'Positive Terminal',
                        'color': 'red',
                        'wireIndex': 1,
                        'shape': "sphere"
                    },
                    'outputs': {
                        'index': 7
                    },
                    'events': {
                        'INTERACTION.CLICK': true,
                        // 'INTERACTION.HOVER': true,
                        // 'INTERACTION.DRAG': true,
                        // 'INTERACTION.DRAG_END': true,
                        // 'INTERACTION.DRAG_BEGIN': true,
                    },
                },
                {
                    'id': 8,
                    'type': 'mp.wireTerminal',
                    'inputs': {
                        'localScale': { 'x': 0.0025, 'y': 0.0025, 'z': 0.0025 },
                        'localRotation': { 'x': 0, 'y': 0, 'z': 0 },
                        'localPosition': { 'x': 0.5, 'y': 0, 'z': 0.1 },
                        'terminal': 'end',
                        'wireIndex': 1,
                        'showInConditions': true,
                        'name': 'Positive Terminal',
                        'color': 'red',
                        'shape': "sphere"
                    },
                    'outputs': {
                        'index': 8
                    },
                    'events': {

                        'INTERACTION.CLICK': true,
                        // 'INTERACTION.HOVER': true,
                        'INTERACTION.DRAG': true,
                        'INTERACTION.DRAG_END': true,
                        'INTERACTION.DRAG_BEGIN': true,
                    },
                },
                {
                    'id': 9,
                    'type': 'mp.canvasText',
                    'inputs': {
                        'size': {
                            'w': 1024,
                            'h': 1024,
                        },
                        'text': '0.0',
                        'fontStyle': 'normal bold',
                        'fontSize': 130,
                        'fontName': 'sans-serif',
                        'position': { 'x': 30, 'y': 0, 'z': 0 },
                        'textWidth': 840,
                    },
                },
                {
                    'id': 10,
                    'type': 'mp.planeRenderer',
                    'inputs': {
                        'localPosition': { 'x': 0.001, 'y': 0.12, 'z': 0.0019 },
                        'localScale': { 'x': 0.12, 'y': 0.055, 'z': 0.5 },
                        'invertScale': false,
                        'transparent': false,
                        'opacity': 1,
                        'polygonOffset': true,
                        'polygonOffsetFactor': -100,
                        'color': 'black',
                    },
                    'events': {
                        // 'INTERACTION.CLICK': true,
                        // 'INTERACTION.HOVER': true,
                        // 'INTERACTION.DRAG': true,
                        // 'INTERACTION.DRAG_BEGIN': true,
                        // 'INTERACTION.DRAG_END': true,
                    },
                },
                {
                    'id': 11,
                    'type': 'mp.gltfLoader',
                    'inputs': {
                        'url': '/assets/3d/multimeter/multimeter_edit.glb',
                        'localScale': { 'x': 0.023, 'y': 0.023, 'z': 0.023 },
                        'localRotation': { 'x': 90, 'y': 0, 'z': 0 },
                        'localPosition': { 'x': 0, 'y': 0, 'z': 0 },
                    },
                    'events': {
                        'INTERACTION.CLICK': true,
                        'INTERACTION.HOVER': true,
                        'INTERACTION.DRAG': true,
                        'INTERACTION.DRAG_END': true,
                        'INTERACTION.DRAG_BEGIN': true,
                    },
                },
            ],
            'bindings': [
                // [0, 'wireCenter', 1, 'wireCenter']
                // [0, 'position', 1, 'position'],
                // [0, 'position', 2, 'position'],
                [1, 'width', 0, 'wireWidth'],
                [2, 'width', 0, 'wireWidth'],
                [7, 'width', 6, 'wireWidth'],
                [8, 'width', 6, 'wireWidth'],
                [3, 'texture', 4, 'texture'],
                // [4, 'painter', 5, 'painter'],
                [5, 'texture', 4, 'texture'],
                [3, 'visible', 5, 'visible'],

                [0, 'negativeConnectedTo', 1, 'connectedTo'], //wireTerminal->output->connectedTo bound to Wire input->negativeConnectedTo
                // [0, 'negativeConnectedTo', 2, 'connectedTo'], shouldnt and doesnt move

                [6, 'positiveConnectedTo', 8, 'connectedTo'], //wireTerminal->output->connectedTo bound to Wire input->positiveConnectedTo
                // [6, 'negativeConnectedTo', 7, 'connectedTo'], shouldnt and doesnt move

                [4, 'painter', 9, 'painter'],
                [5, 'loadingState', 11, 'loadingState'],

                [5, 'positiveConnectedTo', 0, 'negativeConnectedTo'], //wire->output->positiveConnectedTo bound to multimeter input->positiveConnectedTo
                [5, 'negativeConnectedTo', 6, 'positiveConnectedTo'],//wire->output->negativeConnectedTo bound to multimeter input->negativeConnectedTo
                [9, 'text', 5, 'reading'],
            ],
            'events': [
                // [0, 'reposition', 1, 'INTERACTION.CLICK'],
                // [0, 'reposition', 2, 'INTERACTION.CLICK'],
                [0, 'repaint_start', 1, 'INTERACTION.DRAG_BEGIN'],
                [0, 'repaint_start', 1, 'INTERACTION.DRAG'],
                [0, 'repaint_start', 1, 'INTERACTION.DRAG_END'],
                [0, 'repaint_end', 2, 'INTERACTION.DRAG_BEGIN'],
                [0, 'repaint_end', 2, 'INTERACTION.DRAG'],
                [0, 'repaint_end', 2, 'INTERACTION.DRAG_END'],


                [6, 'repaint_start', 7, 'INTERACTION.DRAG_BEGIN'],
                [6, 'repaint_start', 7, 'INTERACTION.DRAG'],
                [6, 'repaint_start', 7, 'INTERACTION.DRAG_END'],
                [6, 'repaint_end', 8, 'INTERACTION.DRAG_BEGIN'],
                [6, 'repaint_end', 8, 'INTERACTION.DRAG'],
                [6, 'repaint_end', 8, 'INTERACTION.DRAG_END'],

                // [2, 'repaint', 3, 'repaint'],
                // [3, 'repaint', 4, 'repaint'],
                [4, 'repaint', 9, 'textUpdate'],
                // [4, 'texture', 5, 'texture'],

            ],
        }],
    },
};


internalModelMap.set(ArrowFlowMarker.payload.objects[0].name, Object.freeze(ArrowFlowMarker));
internalModelMap.set(WireTerminal.payload.objects[0].name, Object.freeze(WireTerminal));

modelMap.set(TextBoxModel.payload.objects[0].name, Object.freeze(TextBoxModel));
modelMap.set(WebcamModel.payload.objects[0].name, Object.freeze(WebcamModel));
modelMap.set(BorderHighlightModel.payload.objects[0].name, Object.freeze(BorderHighlightModel));
modelMap.set(ArrowModel.payload.objects[0].name, Object.freeze(ArrowModel));
modelMap.set(BoxHighlightModel.payload.objects[0].name, Object.freeze(BoxHighlightModel));
modelMap.set(LeverToggleModel.payload.objects[0].name, Object.freeze(LeverToggleModel));
modelMap.set(IoTModel.payload.objects[0].name, Object.freeze(IoTModel));
// modelMap.set(OffButtonModel.payload.objects[0].name, Object.freeze(OffButtonModel));
// modelMap.set(OnButtonModel.payload.objects[0].name, Object.freeze(OnButtonModel));
// modelMap.set(OnOffButtonModel.payload.objects[0].name, Object.freeze(OnOffButtonModel));
modelMap.set(ToggleMultiModelModel.payload.objects[0].name, Object.freeze(ToggleMultiModelModel));
// modelMap.set(ThreeDMenuModel.payload.objects[0].name, Object.freeze(ThreeDMenuModel));
modelMap.set(ImageRendererModel.payload.objects[0].name, Object.freeze(ImageRendererModel));
modelMap.set(FireParticleSystem.payload.objects[0].name, Object.freeze(FireParticleSystem));
modelMap.set(WaterParticleSystem.payload.objects[0].name, Object.freeze(WaterParticleSystem));
modelMap.set(DialModel.payload.objects[0].name, Object.freeze(DialModel));
// modelMap.set(LightModel.payload.objects[0].name, Object.freeze(LightModel));

modelMap.set(SpotLightModel.payload.objects[0].name, Object.freeze(SpotLightModel));

modelMap.set(AnalogGaugeModel.payload.objects[0].name, Object.freeze(AnalogGaugeModel));
modelMap.set(ArrowFlow.payload.objects[0].name, Object.freeze(ArrowFlow));
modelMap.set(Dropzone.payload.objects[0].name, Object.freeze(Dropzone));
// modelMap.set(videoView.payload.objects[0].name, Object.freeze(videoView));
modelMap.set(QuizModel.payload.objects[0].name, Object.freeze(QuizModel));
modelMap.set(WireModel.payload.objects[0].name, Object.freeze(WireModel));
modelMap.set(MultimeterModel.payload.objects[0].name, Object.freeze(MultimeterModel));

Object.freeze(modelMap);
export default modelMap;


